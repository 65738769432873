import React, { useEffect, useState } from "react"

import { faSearch, faTrashAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { Platform } from "@taiyoro/parse-stream-url"
import { useTranslation } from "react-i18next"

import LoadingBox from "../../components/LoadingBox"
import { StylePaper } from "../../components/StyleMaterialUI"
import Game from "../../models/Taiyoro/Meta/Game"
import {
  deletePlatformGameMapping,
  fetchPlatformGameMappings,
  PlatformGameMapping,
  PLATFORM_CHOICES,
  savePlatformGameMapping,
} from "../../services/Taiyoro/dataCollection"
import { fetchGames } from "../../services/Taiyoro/games"
import { localisedLabel } from "../../utils/i18n"

interface MappingSelectorProps {
  games: Array<Game>
  platformGameMapping: PlatformGameMapping
  updateMapping: (updatedMapping: PlatformGameMapping) => void
}

const MappingSelector = ({ games, platformGameMapping, updateMapping }: MappingSelectorProps) => {
  const initiallySelectedGame = games.find((game) => game.id === platformGameMapping.gameId)

  const [value, setValue] = React.useState(initiallySelectedGame || null)
  const [inputValue, setInputValue] = React.useState(
    initiallySelectedGame ? localisedLabel(initiallySelectedGame) : ""
  )

  return (
    <Autocomplete
      options={games}
      getOptionLabel={(option) => localisedLabel(option)}
      value={value}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      disableClearable
      clearOnBlur
      onChange={(_event, newValue) => {
        if (typeof newValue === "string") {
          return
        }
        setValue(newValue)
        updateMapping({
          ...platformGameMapping,
          gameId: newValue.id,
        })
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  )
}

const PLATFORM_GAME_MAPPINGS_PLATFORMS = PLATFORM_CHOICES.filter((platformChoice) => {
  return platformChoice.platform !== Platform.Afreeca && platformChoice.platform !== Platform.YouTube
})

const PlatformGameMappings = () => {
  const { t } = useTranslation(["data-collection", "common"])

  const [selectedPlatform, setSelectedPlaform] = useState(PLATFORM_GAME_MAPPINGS_PLATFORMS[0])
  const [platform, setPlatform] = useState(null)
  const [games, setGames] = useState<Array<Game> | null>(null)
  const [loading, setLoading] = useState(false)
  const [platformGameMappings, setPlatformGameMappings] = useState<Array<PlatformGameMapping> | null>(null)
  const [deleteMappingState, setDeleteMappingState] = useState<PlatformGameMapping | null>(null)

  const getPlatformGameMappingsForPlatform = (platform: Platform) => {
    setLoading(true)
    fetchPlatformGameMappings(platform).then((fetchedResponse) => {
      setPlatformGameMappings(fetchedResponse)
      setPlatform(platform)
      setLoading(false)
    })
  }

  const onDelete = async () => {
    const success = await deletePlatformGameMapping(
      selectedPlatform.platform,
      deleteMappingState.platformGameId
    )
    if (success) {
      const updatedPlatformGameMappings = [...platformGameMappings].filter((platformGameMapping) => {
        return !(
          platformGameMapping.platformGameId === deleteMappingState.platformGameId &&
          platformGameMapping.platformName === deleteMappingState.platformName
        )
      })
      setPlatformGameMappings(updatedPlatformGameMappings)
      setDeleteMappingState(null)
    }
  }

  const load = async () => {
    fetchGames().then((games) => {
      setGames(games)
    })
  }

  useEffect(() => {
    load()
  }, [])

  const handleUpdate = async (updatedPlatformGameMapping: PlatformGameMapping) => {
    const success = await savePlatformGameMapping(
      platform,
      updatedPlatformGameMapping.platformGameId,
      updatedPlatformGameMapping.gameId
    )
    if (success) {
      const index = platformGameMappings.findIndex((platformGameMapping) => {
        return (
          platformGameMapping.platformGameId === updatedPlatformGameMapping.platformGameId &&
          platformGameMapping.platformName === updatedPlatformGameMapping.platformName
        )
      })
      const updatedPlatformGameMappings = [...platformGameMappings]
      updatedPlatformGameMappings[index] = updatedPlatformGameMapping
      setPlatformGameMappings(updatedPlatformGameMappings)
    }
  }

  const getPlatformGameUrl = (platformGameMapping: PlatformGameMapping) => {
    if (platform === Platform.Twitch) {
      return encodeURI(`https://www.twitch.tv/directory/game/${platformGameMapping.platformGameName}`)
    }
    if (platform === Platform.Mildom) {
      return encodeURI(`https://www.mildom.com/channel/${platformGameMapping.platformGameId}`)
    }
    if (platform === Platform.OpenRec) {
      return encodeURI(`https://www.openrec.tv/game/${platformGameMapping.platformGameId}`)
    }
  }

  return (
    <>
      <StylePaper>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            {selectedPlatform && (
              <Select
                required
                value={selectedPlatform.label}
                onChange={(e) => {
                  setSelectedPlaform(
                    PLATFORM_CHOICES.find((platformChoice) => platformChoice.label === e.target.value)
                  )
                }}
              >
                {PLATFORM_GAME_MAPPINGS_PLATFORMS.map((platformChoice) => (
                  <MenuItem
                    key={platformChoice.label}
                    value={platformChoice.label}
                  >
                    {platformChoice.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
        </Grid>
        <Box mt="12px">
          <Button
            onClick={() => getPlatformGameMappingsForPlatform(selectedPlatform.platform)}
            variant="contained"
            color="primary"
            startIcon={
              <FontAwesomeIcon
                icon={faSearch}
                size="1x"
              />
            }
          >
            {t("explore.fetch")}
          </Button>
        </Box>

        {loading && <LoadingBox />}
        {!loading && games && platformGameMappings && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("platformGameMappings.table.gameId")}</TableCell>
                <TableCell>{t("platformGameMappings.table.gameName")}</TableCell>
                <TableCell>{t("platformGameMappings.table.mapping")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {platformGameMappings.map((platformGameMapping, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link
                      href={getPlatformGameUrl(platformGameMapping)}
                      target="_blank"
                      underline="hover"
                    >
                      {platformGameMapping.platformGameId}
                    </Link>
                  </TableCell>
                  <TableCell>{platformGameMapping.platformGameName}</TableCell>
                  <TableCell>
                    <MappingSelector
                      platformGameMapping={platformGameMapping}
                      games={games}
                      updateMapping={handleUpdate}
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title={t("common:actions.delete")}>
                      <IconButton
                        color="error"
                        onClick={() => setDeleteMappingState(platformGameMapping)}
                        sx={{ width: "32px", height: "32px" }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </StylePaper>
      {deleteMappingState && (
        <Dialog
          open
          onClose={() => setDeleteMappingState(null)}
        >
          <DialogContent>
            <Typography>{t("platformGameMappings.deleteConfirmation")}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onDelete}
              autoFocus
              variant="outlined"
              color="error"
            >
              {t("common:actions.delete")}
            </Button>
            <Button
              onClick={() => setDeleteMappingState(null)}
              color="primary"
              autoFocus
              variant="contained"
            >
              {t("common:actions.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default PlatformGameMappings

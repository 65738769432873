import React from "react"

import { useTranslation } from "react-i18next"

import MetasPage from "../../../components/Taiyoro/MetaPage"
import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import { fetchTags, createTag, deleteTag, linkToMeta, deleteLinkToMeta } from "../../../services/Taiyoro/tags"

const TagMetaPage = () => {
  const { t } = useTranslation("taiyoro")

  return (
    <MetasPage
      title={t("meta.pageTitles.tags")}
      fetchFunc={fetchTags}
      deleteFunc={deleteTag}
      addNewFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createTag(name, nameJa, sortJa, id)}
      updateFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createTag(name, nameJa, sortJa, id)}
      metaType={MetaType.TAG}
      updateParentTagsFunc={(added, removed) =>
        Promise.all([
          ...added.map((groupData) => linkToMeta(groupData.parentGroupId, groupData.refId, MetaType.TAG)),
          ...removed.map((groupData) =>
            deleteLinkToMeta(groupData.parentGroupId, groupData.refId, MetaType.TAG)
          ),
        ])
      }
    />
  )
}

export default TagMetaPage

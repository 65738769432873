import { useEffect, useState } from "react"

import LoadingBox from "../../components/LoadingBox"
import ScoutingTool from "../../components/Scouting/ScoutingTool"
import Game from "../../models/Taiyoro/Meta/Game"
import { fetchGames } from "../../services/Taiyoro/games"

const Scouting = () => {
  const [loadingState, setLoadingState] = useState(true)
  const [games, setGames] = useState<Array<Game>>([])

  const load = () => {
    fetchGames().then((games) => {
      setGames(games)
      setLoadingState(false)
    })
  }

  useEffect(() => {
    load()
  }, [])

  return loadingState || games.length === 0 ? <LoadingBox /> : <ScoutingTool games={games} />
}

export default Scouting

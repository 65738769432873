import React, { useEffect, useState } from "react"

import { faArrowDown, faArrowUp, faCode, faSearch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"
import { useLocation, useSearchParam } from "react-use"

import LoadingBox from "../../components/LoadingBox"
import { StylePaper } from "../../components/StyleMaterialUI"
import { SortArrow } from "../../components/Taiyoro/Analytics/Table/styles"
import {
  DataCollectionResponse,
  DataCollectionSort,
  fetchDataCollection,
  MOMENT_FORMAT,
  PLATFORM_CHOICES,
} from "../../services/Taiyoro/dataCollection"

const DataCollectionList = () => {
  const { t } = useTranslation(["data-collection", "common"])

  const searchParamPlatform = useSearchParam("platform")
  const searchParamFrom = useSearchParam("from")
  const searchParamTo = useSearchParam("to")

  const [selectedPlatform, setSelectedPlaform] = useState(
    searchParamPlatform ? PLATFORM_CHOICES.find((p) => p.label === searchParamPlatform) : PLATFORM_CHOICES[0]
  )
  const [from, setFrom] = useState<moment.Moment | null>(
    searchParamFrom ? moment(searchParamFrom, MOMENT_FORMAT).tz("Asia/Tokyo") : null
  )
  const [to, setTo] = useState<moment.Moment | null>(
    searchParamTo ? moment(searchParamTo, MOMENT_FORMAT).tz("Asia/Tokyo") : null
  )
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<DataCollectionResponse | null>(null)
  const [channelUrl] = useState<string | null>(useSearchParam("channelUrl") || null)
  const [videoUrl] = useState<string | null>(useSearchParam("videoUrl") || null)
  const [timelineToDisplay, setTimelineToDisplay] = useState<string | null>(null)
  const location = useLocation()

  useEffect(() => {
    ;(channelUrl || videoUrl) && search(true)
  }, [])

  const search = (
    clearResults: boolean,
    sort: DataCollectionSort = DataCollectionSort.START_DATETIME_DESC,
    offset: number = 0
  ) => {
    setLoading(true)
    fetchDataCollection(selectedPlatform.platform, sort, offset, null, from, to, channelUrl, videoUrl).then(
      (fetchedResponse) => {
        !response && setResponse(fetchedResponse)
        response &&
          setResponse({
            sort: fetchedResponse.sort,
            results: [...(clearResults ? [] : response.results), ...fetchedResponse.results],
          })
        setLoading(false)
      }
    )
  }

  const handleSortChange = () => {
    setResponse(null)
    let oppositeSearch =
      response.sort === DataCollectionSort.START_DATETIME_ASC
        ? DataCollectionSort.START_DATETIME_DESC
        : DataCollectionSort.START_DATETIME_ASC
    search(true, oppositeSearch)
  }

  const getCurrentParamsAsUrlParams = () => {
    let params = ""
    params += `&platform=${selectedPlatform.label}`
    if (from) {
      params += `&from=${from.format(MOMENT_FORMAT)}'`
    }
    if (to) {
      params += `&to=${from.format(MOMENT_FORMAT)}'`
    }
    return params
  }

  return (
    <StylePaper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          {selectedPlatform && (
            <Select
              required
              value={selectedPlatform.label}
              onChange={(e) => {
                setSelectedPlaform(
                  PLATFORM_CHOICES.find((platformChoice) => platformChoice.label === e.target.value)
                )
              }}
            >
              {PLATFORM_CHOICES.map((platformChoice) => (
                <MenuItem
                  key={platformChoice.label}
                  value={platformChoice.label}
                >
                  {platformChoice.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
        <Grid item>
          <TextField
            label={t("explore.from")}
            type="datetime-local"
            value={from ? from.format(MOMENT_FORMAT) : ""}
            onChange={(e) => {
              if (!e.target.value) {
                setFrom(null)
                return
              }
              setFrom(moment(e.target.value, MOMENT_FORMAT).tz("Asia/Tokyo"))
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginRight: "12px" }}
          />
          <TextField
            label={t("explore.to")}
            type="datetime-local"
            value={to ? to.format(MOMENT_FORMAT) : ""}
            onChange={(e) => {
              if (!e.target.value) {
                setTo(null)
                return
              }
              setTo(moment(e.target.value, MOMENT_FORMAT).tz("Asia/Tokyo"))
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Box mt="12px">
        <Button
          onClick={() => search(true)}
          variant="contained"
          color="primary"
          startIcon={
            <FontAwesomeIcon
              icon={faSearch}
              size="1x"
            />
          }
        >
          {t("explore.fetch")}
        </Button>
      </Box>

      {loading && <LoadingBox />}
      {!loading && response && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("explore.table.userName")}</TableCell>
                <TableCell>{t("explore.table.gameName")}</TableCell>
                <TableCell>{t("explore.table.streamTitle")}</TableCell>
                <TableCell>{t("explore.table.channelUrl")}</TableCell>
                <TableCell>{t("explore.table.videoUrl")}</TableCell>
                <TableCell onClick={() => handleSortChange()}>
                  {t("explore.table.startDatetime")}
                  <SortArrow>
                    <FontAwesomeIcon
                      color="#f30362"
                      icon={response.sort === DataCollectionSort.START_DATETIME_ASC ? faArrowUp : faArrowDown}
                    />
                  </SortArrow>
                </TableCell>
                <TableCell>{t("explore.table.endDatetime")}</TableCell>
                <TableCell>{t("explore.table.viewerTimeline")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response.results.map((result) => (
                <TableRow
                  key={result.id}
                  data-id={result.id}
                >
                  <TableCell>{result.userName}</TableCell>
                  <TableCell>{result.gameName}</TableCell>
                  <TableCell>{result.streamTitle}</TableCell>
                  <TableCell>
                    <Typography>
                      <Link
                        href={`${location.origin}${location.pathname}?channelUrl=${
                          result.channelUrl
                        }${getCurrentParamsAsUrlParams()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {result.channelUrl}
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      <Link
                        href={`${location.origin}${location.pathname}?videoUrl=${
                          result.videoUrl
                        }${getCurrentParamsAsUrlParams()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {result.videoUrl}
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell>{result.startDatetime}</TableCell>
                  <TableCell>{result.endDatetime}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        setTimelineToDisplay(JSON.stringify(JSON.parse(result.viewerTimeline), null, 2))
                      }
                    >
                      <FontAwesomeIcon icon={faCode} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box mt={1}>
            <Typography>
              {response.results.length}
              {t("explore.currentDisplay")}
            </Typography>
            <Button
              onClick={() => search(false, response.sort, response.results.length)}
              variant="contained"
              color="primary"
              startIcon={
                <FontAwesomeIcon
                  icon={faSearch}
                  size="1x"
                />
              }
            >
              {t("explore.loadMore")}
            </Button>
          </Box>
        </>
      )}
      <Dialog
        maxWidth="xl"
        open={timelineToDisplay !== null}
        onClose={() => setTimelineToDisplay(null)}
      >
        <DialogContent>{timelineToDisplay && <pre>{timelineToDisplay}</pre>}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => setTimelineToDisplay(null)}
            color="primary"
            variant="contained"
          >
            {t("common:actions.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </StylePaper>
  )
}

export default DataCollectionList

import TeamOrganization from "../../models/Taiyoro/Meta/TeamOrganization"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchTeamOrganizations = async (): Promise<TeamOrganization[] | null> => {
  let response = await GraphQL(
    `query {
        admin {
            teamOrganization {
            get {
              id
              name
              nameJa
              sortJa
              urlSlug,
              primaryImage,
              secondaryImage,
              homepageUrl
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.teamOrganization &&
      response.data.admin.teamOrganization.get &&
      response.data.admin.teamOrganization.get) ||
    null
  )
}

export const createTeamOrganization = async (
  name: string,
  nameJa?: string,
  sortJa?: string,
  primaryImage?: string,
  secondaryImage?: string,
  urlSlug?: string,
  homepageUrl?: string,
  id?: string
) => {
  let response = await GraphQL(
    `mutation {
      admin {
        teamOrganization {
          save (teamOrganization: {
            ${id ? `id: "${id}"` : ""}
            name: ${name ? `"${name}"` : null}
            nameJa: ${nameJa ? `"${nameJa}"` : null}
            sortJa: ${sortJa ? `"${sortJa}"` : null}
            urlSlug: ${urlSlug ? `"${urlSlug}"` : null}
            primaryImage: ${primaryImage ? `"${primaryImage}"` : null}
            secondaryImage: ${secondaryImage ? `"${secondaryImage}"` : null}
            homepageUrl: ${homepageUrl ? `"${homepageUrl}"` : null}
          }) {
            id
            name
            nameJa
            sortJa
            urlSlug
            primaryImage
            secondaryImage
            homepageUrl
            updatedAt
          }
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.teamOrganization &&
      response.data.admin.teamOrganization.save) ||
    null
  )
}

export const deleteTeamOrganization = async (id) => {
  let response = await GraphQL(
    `mutation {
        admin {
          teamOrganization {
            delete (
                teamOrganizationId: "${id}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.teamOrganization &&
      response.data.admin.teamOrganization.delete) ||
    null
  )
}

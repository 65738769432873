import React, { useEffect, useState } from "react"

import {
  faCaretDown,
  faCaretLeft,
  faExclamationTriangle,
  faTimesCircle,
  faCheckCircle,
  faBan,
  faBracketsSquare,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton, Stack, Tooltip } from "@mui/material"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Link as ReactRouterDomLink } from "react-router-dom"
import { HIGH_ACCESS_ROLES } from "utils/roles"
import { taiyoroRatingPercentileToScore } from "utils/taiyoro"
import useRolesCanAccess from "utils/useRolesCanAccess"

import Game from "../../../../../models/Taiyoro/Meta/Game"
import Language from "../../../../../models/Taiyoro/Meta/Language"
import Platform from "../../../../../models/Taiyoro/Meta/Platform"
import Venue from "../../../../../models/Taiyoro/Meta/Venue"
import EventDate from "../../../../../models/Taiyoro/eventDate"
import isValidUrl from "../../../../../utils/taiyoro/validators/streamUrl"
import ChipLink from "../../../../StyleMaterialUI/ChipLink"
import Duration from "./Duration"
import StreamList from "./StreamList"
import { GreenCheckbox, NameText } from "./styles"

interface Props {
  date: EventDate
  games: Game[]
  venues: Venue[]
  platforms: Platform[]
  languages: Language[]
  eventId: string
  expanded: boolean
  onToggle: (id: string) => void
}

const Date = ({ date, games, venues, platforms, languages, expanded, onToggle, eventId }: Props) => {
  const [invalidStreamUrlIdsState, setInvalidStreamUrlIdsState] = useState([])
  const [successfulCollectionState, setSuccessfulCollectionState] = useState(null)
  const [dataCollectionErrorState, setDataCollectionErrorState] = useState(null)
  const { t } = useTranslation("taiyoro")

  const isHighAccessUser = useRolesCanAccess(HIGH_ACCESS_ROLES)

  useEffect(() => {
    setInvalidStreamUrlIdsState(
      date.platforms.filter((stream) => !isValidUrl(stream.url)).map((stream) => stream.id)
    )
    setSuccessfulCollectionState(
      date.platforms.some(
        (stream) =>
          isValidUrl(stream.url) &&
          !stream.dataCollectionErrored &&
          (stream.views1Week ||
            stream.viewsLiveMinutesWatched ||
            stream.viewsLiveMinutesWatchedManual ||
            stream.views1WeekManual)
      )
    )
    setDataCollectionErrorState(
      date.platforms.some(
        (stream) =>
          isValidUrl(stream.url) &&
          stream.dataCollectionErrored &&
          !(
            stream.views1Week ||
            stream.viewsLiveMinutesWatched ||
            stream.viewsLiveMinutesWatchedManual ||
            stream.views1WeekManual
          )
      )
    )
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <TableRow
        onClick={() => onToggle(date.id)}
        style={{
          cursor: date.platforms.length > 0 ? "pointer" : "initial",
        }}
      >
        <TableCell style={{ width: "15%", minWidth: "150px" }}>
          {date.cancelled ? (
            <Tooltip title={t("edit.dates.cancelled")}>
              <span style={{ marginRight: "4px" }}>
                <FontAwesomeIcon
                  icon={faBan}
                  color="grey"
                />
              </span>
            </Tooltip>
          ) : (
            ""
          )}
          {successfulCollectionState ? (
            <Tooltip title={t("list.summary.dateList.dataCollectionSuccess")}>
              <span style={{ marginRight: "4px" }}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#1AAA55"
                />
              </span>
            </Tooltip>
          ) : (
            ""
          )}
          {invalidStreamUrlIdsState.length > 0 ? (
            <Tooltip title={t("list.summary.dateList.invalidOrUnsupportedUrls")}>
              <span style={{ marginRight: "4px" }}>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  color="#ffcc01"
                />
              </span>
            </Tooltip>
          ) : (
            ""
          )}
          {dataCollectionErrorState && (
            <Tooltip title={t("list.summary.dateList.dataCollectionFailure")}>
              <span style={{ marginRight: "4px" }}>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  color="red"
                />
              </span>
            </Tooltip>
          )}
          <NameText
            component="span"
            data-id={date.id}
          >
            {date.name}
          </NameText>
        </TableCell>
        <TableCell>
          <Duration date={date} />
        </TableCell>
        <TableCell>
          <ChipLink
            to="/taiyoro/meta/games"
            size="small"
            color="primary"
            label={games.length > 0 && games.find((game) => game.id === date.game).name}
            tooltip
            style={{ maxWidth: "140px" }}
          />
        </TableCell>
        <TableCell>
          <GreenCheckbox checked={date.offline === 1} />
        </TableCell>
        <TableCell>
          <NameText component="span">
            {date.venue && venues.length > 0 && venues.find((venue) => venue.id === date.venue).name}
          </NameText>
        </TableCell>
        <TableCell>
          <NameText component="span">{date.attendees}</NameText>
        </TableCell>
        <TableCell>
          <NameText component="span">{taiyoroRatingPercentileToScore(date.taiyoroRating)}</NameText>
        </TableCell>
        <TableCell style={{ width: "42px" }}>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
          >
            {isHighAccessUser && date.startTime.isBefore(moment()) && (
              <ReactRouterDomLink to={`/taiyoro/${eventId}/${date.id}/brackets`}>
                <IconButton onClick={(e) => e.stopPropagation()}>
                  <FontAwesomeIcon icon={faBracketsSquare} />
                </IconButton>
              </ReactRouterDomLink>
            )}
            <FontAwesomeIcon
              icon={expanded ? faCaretDown : faCaretLeft}
              size="1x"
            />
          </Stack>
        </TableCell>
      </TableRow>
      {expanded && date.platforms.length > 0 && (
        <StreamList
          key={`streams-${date.id}`}
          eventPlatforms={date.platforms}
          eventDate={date}
          eventId={eventId}
          platforms={platforms}
          languages={languages}
          broadcastDuration={moment.duration(date.endTime.diff(date.startTime)).asMinutes()}
        />
      )}
    </React.Fragment>
  )
}

export default Date

import React, { useState } from "react"

import {
  faExclamationTriangle,
  faLock,
  faRobot,
  faTrashAlt,
  faUnlock,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip, Button, Box, IconButton, Stack } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import getPlatformFromUrl, { Platform as PlatformType } from "@taiyoro/parse-stream-url"
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog"
import { useTranslation } from "react-i18next"
import { useUpdateEffect } from "react-use"

import Platform from "../../../../../../../models/Taiyoro/Meta/Platform"
import EventStreamModel from "../../../../../../../models/Taiyoro/Stream"
import { PublishedState } from "../../../../../../../models/Taiyoro/event"
import { UserRole } from "../../../../../../../models/UserManagement"
import { editDatePlatform } from "../../../../../../../services/Taiyoro/event"
import { fetchLanguages } from "../../../../../../../services/Taiyoro/languages"
import { HIGH_ACCESS_ROLES } from "../../../../../../../utils/roles"
import isValidUrl from "../../../../../../../utils/taiyoro/validators/streamUrl"
import useRolesCanAccess from "../../../../../../../utils/useRolesCanAccess"
import RealTimeUpdateField from "../../../../../RealTimeUpdateField"
import UnlockDialog from "../UnlockDialog"

interface Props {
  eventPublishedState: PublishedState
  model: EventStreamModel
  availablePlatforms: Platform[]
  onUpdate: (changes: any) => void
  onDelete: (id: string) => void
}

const EventVOD = ({ eventPublishedState, model, availablePlatforms, onUpdate, onDelete }: Props) => {
  const [availableLanguagesState] = useState(fetchLanguages)

  const [idState] = useState(model.id || null)
  const [platformState, setPlatformState] = useState(model.platform || null)
  const [urlState, setUrlState] = useState(model.url || null)
  const [languageState, setLanguageState] = useState(model.language)
  const [isSubStreamState, setIsSubStreamState] = useState(model.isSubStream)
  const [isValidUrlState, setIsValidUrlState] = useState(model.url && isValidUrl(model.url))
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const { t } = useTranslation(["taiyoro", "common"])

  const [views1WeekState, setViews1WeekState] = useState(model.views1WeekManual || model.views1Week)
  const [viewsWeek2State, setViewsWeek2State] = useState(model.viewsWeek2Manual || model.viewsWeek2)
  const [viewsWeek3State, setViewsWeek3State] = useState(model.viewsWeek3Manual || model.viewsWeek3)
  const [viewsWeek4State, setViewsWeek4State] = useState(model.viewsWeek4Manual || model.viewsWeek4)

  const isHighAccessUser = useRolesCanAccess(HIGH_ACCESS_ROLES)

  const isEditableToEditor =
    useRolesCanAccess([UserRole.EDITOR]) && eventPublishedState === PublishedState.Draft

  const canDelete = isHighAccessUser || isEditableToEditor

  useUpdateEffect(() => {
    //Update the values on the parent component so it can calculate the totals of these values
    onUpdate({
      views1WeekManual: views1WeekState,
      viewsWeek2Manual: viewsWeek2State,
      viewsWeek3Manual: viewsWeek3State,
      viewsWeek4Manual: viewsWeek4State,
      platform: platformState,
      url: urlState,
      language: languageState,
      isSubStream: isSubStreamState,
    })
    // eslint-disable-next-line
  }, [
    platformState,
    urlState,
    languageState,
    views1WeekState,
    viewsWeek2State,
    viewsWeek3State,
    viewsWeek4State,
    isSubStreamState,
  ])

  const toggleLock = () => {
    if (isLockedState) {
      setIsUnlockDialogOpenState(true)
      return
    }
    if (!isLockedState) {
      setIsLockedState(true)
    }
  }

  const onUpdateUrl = (url: string) => {
    setUrlState(url)

    setIsValidUrlState(url && isValidUrl(url))

    try {
      const p = getPlatformFromUrl(url)

      let pId
      let platform
      switch (p) {
        case PlatformType.YouTube:
          platform = availablePlatforms.find((o) => o.name.toLowerCase().includes("youtube"))
          if (platform) {
            pId = platform.id
          }
          break
        case PlatformType.Twitch:
          platform = availablePlatforms.find((o) => o.name.toLowerCase().includes("twitch"))
          if (platform) {
            pId = platform.id
          }
          break
        case PlatformType.OpenRec:
          platform = availablePlatforms.find((o) => o.name.toLowerCase().includes("openrec"))
          if (platform) {
            pId = platform.id
          }
          break
        case PlatformType.Mildom:
          platform = availablePlatforms.find((o) => o.name.toLowerCase().includes("mildom"))
          if (platform) {
            pId = platform.id
          }
          break
        case PlatformType.Afreeca:
          platform = availablePlatforms.find((o) => o.name.toLowerCase().includes("afreeca"))
          if (platform) {
            pId = platform.id
          }
          break
      }
      pId && setPlatformState(pId)
      return pId
    } catch (e) {
      console.error(e)
    }
  }

  const [isLockedState, setIsLockedState] = useState(true)
  const [isUnlockDialogOpenState, setIsUnlockDialogOpenState] = useState(false)

  return (
    <React.Fragment>
      <Stack
        sx={{ minWidth: "200px" }}
        direction="row"
        gap={1}
        alignItems="center"
      >
        <Tooltip
          placement="bottom"
          title={urlState || ""}
        >
          <Box>
            <RealTimeUpdateField
              type="text"
              variant="standard"
              label={t("edit.dates.streams.vodUrl")}
              validationRule={async (encodedUrl) => {
                return new Promise((resolve, reject) => {
                  const encodedUrlSafe = encodedUrl === null ? "https://" : encodedUrl
                  resolve(encodedUrlSafe)
                })
              }}
              updateFunc={(encodedUrl) => {
                const decodedUrl = decodeURI(encodedUrl)
                setUrlState(decodedUrl)
                const newPlatformId = onUpdateUrl(decodedUrl)
                let payload
                if (newPlatformId) {
                  payload = {
                    url: decodedUrl,
                    platformId: newPlatformId,
                  }
                } else {
                  payload = {
                    url: decodedUrl,
                  }
                }
                return editDatePlatform(idState, payload)
              }}
              initialValue={urlState || ""}
            />
          </Box>
        </Tooltip>
        {!isValidUrlState && (
          <Tooltip
            placement="bottom"
            title={t("list.summary.dateList.invalidOrUnsupportedUrl")}
          >
            <Box>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                color="#ffcc01"
              />
            </Box>
          </Tooltip>
        )}
      </Stack>
      <Box width="125px">
        <FormControl style={{ width: "100%" }}>
          <Tooltip
            placement="bottom"
            title={availableLanguagesState.find((l) => l.id === languageState)?.name || ""}
          >
            <Box>
              <RealTimeUpdateField
                type="select"
                variant="standard"
                disableClearable
                initialValue={availableLanguagesState.find((l) => l.id === languageState)}
                options={availableLanguagesState}
                label={t("edit.dates.streams.language")}
                updateFunc={(value) => {
                  setLanguageState(value.id)
                  return editDatePlatform(idState, { language: value.id })
                }}
              />
            </Box>
          </Tooltip>
        </FormControl>
      </Box>
      <Box width="125px">
        <RealTimeUpdateField
          type="number-format"
          variant="standard"
          label={t("edit.dates.vods.w1")}
          disabled={isLockedState}
          updateFunc={(value) => {
            setViews1WeekState(value ? parseInt(value) : model.views1Week)
            return editDatePlatform(idState, { views1WeekManual: value ? parseInt(value) : null })
          }}
          initialValue={views1WeekState}
        />
      </Box>
      <Box width="125px">
        <RealTimeUpdateField
          type="number-format"
          variant="standard"
          label={t("edit.dates.vods.w2")}
          disabled={isLockedState}
          updateFunc={(value) => {
            setViewsWeek2State(value ? parseInt(value) : model.viewsWeek2)
            return editDatePlatform(idState, { viewsWeek2Manual: value ? parseInt(value) : null })
          }}
          initialValue={viewsWeek2State}
        />
      </Box>
      <Box width="125px">
        <RealTimeUpdateField
          type="number-format"
          variant="standard"
          label={t("edit.dates.vods.w3")}
          disabled={isLockedState}
          updateFunc={(value) => {
            setViewsWeek3State(value ? parseInt(value) : model.viewsWeek3)
            return editDatePlatform(idState, { viewsWeek3Manual: value ? parseInt(value) : null })
          }}
          initialValue={viewsWeek3State}
        />
      </Box>
      <Box width="125px">
        <RealTimeUpdateField
          type="number-format"
          variant="standard"
          label={t("edit.dates.vods.w4")}
          disabled={isLockedState}
          updateFunc={(value) => {
            setViewsWeek4State(value ? parseInt(value) : model.viewsWeek4)
            return editDatePlatform(idState, { viewsWeek4Manual: value ? parseInt(value) : null })
          }}
          initialValue={viewsWeek4State}
        />
      </Box>
      <Box>
        <Tooltip
          placement="bottom"
          title={t("edit.dates.streams.lock.tooltip")}
        >
          <Button onClick={toggleLock}>
            {!isLockedState && <FontAwesomeIcon icon={faUnlock}></FontAwesomeIcon>}
            {isLockedState && <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>}
          </Button>
        </Tooltip>
      </Box>
      <Box>
        <RealTimeUpdateField
          type="checkbox"
          label={t("edit.dates.streams.isSubStream")}
          updateFunc={(value) => {
            const intValue = parseInt(value)
            setIsSubStreamState(intValue)
            return editDatePlatform(idState, { isSubStream: intValue })
          }}
          initialValue={isSubStreamState}
        />
      </Box>
      <Box>
        {model.automaticallyGenerated === 1 && (
          <Tooltip
            placement="bottom"
            title={t("edit.dates.streams.automaticallyGenerated")}
          >
            <Box>
              <FontAwesomeIcon icon={faRobot} />
            </Box>
          </Tooltip>
        )}
      </Box>
      <Box style={{ textAlign: "right" }}>
        {canDelete && (
          <Tooltip title={t("common:actions.delete")}>
            <IconButton
              color="error"
              onClick={() => setShowDeleteConfirmation(true)}
              sx={{ width: "32px", height: "32px" }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <UnlockDialog
        isUnlockDialogOpenState={isUnlockDialogOpenState}
        onUnlock={() => {
          setIsLockedState(false)
          setIsUnlockDialogOpenState(false)
        }}
        onCancelUnlock={() => setIsUnlockDialogOpenState(false)}
      />
      <ConfirmationDialog
        body={t("common:actions.checkAgain")}
        open={showDeleteConfirmation}
        onConfirm={() => onDelete(model.id)}
        onCancel={() => setShowDeleteConfirmation(false)}
        confirmText={t("common:actions.delete")}
        cancelText={t("common:actions.cancel")}
      />
    </React.Fragment>
  )
}

export default EventVOD

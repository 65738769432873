import React, { useState, useEffect, useContext } from "react"

import {
  faArrowDown,
  faArrowUp,
  faEdit,
  faPlusSquare,
  faTrashAlt,
  faWindowClose,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Autocomplete,
  Checkbox,
  Chip,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import { TaiyoroDataContext } from "contexts/tag-hierarchy"
import { Tag, TagNode } from "models/Taiyoro/Meta/Tag"
import { LocalMomentFromUTCDateTime } from "models/Taiyoro/eventDate"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import Scroll from "react-scroll"
import { useLocation } from "react-use"
import { fetchGames } from "services/Taiyoro/games"
import { localisedLabel } from "utils/i18n"

import { GameAlternativeName } from "../../../models/Taiyoro/Meta/Game"
import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import { UserRole } from "../../../models/UserManagement"
import { fetchGameGroups } from "../../../services/Taiyoro/gameGroups"
import { signMediaS3 } from "../../../services/Taiyoro/media"
import { fetchTeamOrganizations } from "../../../services/Taiyoro/teamOrganizations"
import { fetchTeams } from "../../../services/Taiyoro/teams"
import { ADMIN_ROLES, HIGH_ACCESS_ROLES } from "../../../utils/roles"
import sortByEnglishNameOrJapaneseSort from "../../../utils/sortByEnglishNameOrJapaneseSort"
import useRolesCanAccess from "../../../utils/useRolesCanAccess"
import ErrorDialog from "../../Dialogs/ErrorDialog"
import DropdownSearchable from "../../Form/DropdownSearchable"
import { StyleButton } from "../../StyleMaterialUI"
import TableList from "../../Table/TableList"
import { SortableTableCell, SortArrow } from "../Analytics/Table/styles"
import DeleteMeta from "../DeleteMeta"
import MediaUpload from "../MediaUpload"

interface MetaItem {
  id: string
  name: string
  nameJa: string
  sortJa: string
  updatedAt: string
  isGameDeveloper?: boolean
  primaryImage?: string
  secondaryImage?: string
  hashtag?: string
  latitude?: string
  longitude?: string
  teamId?: string
  teamOrganizationId?: string
  gameId?: string
  urlSlug?: string
  homepageUrl?: string
  quantifiable?: number
  isGenre?: number
}

interface SortParams {
  field: "en" | "ja" | "updatedAt"
  sort: "asc" | "desc"
}

interface GroupLink {
  refId: string
  parentGroupId: string
}

interface Props {
  title: string
  fetchFunc: () => Promise<any>
  addNewFunc: (
    name: string,
    nameJa: string | null,
    sortJa: string | null,
    isGameDeveloper?: boolean | null,
    primaryImage?: string | null,
    secondaryImage?: string | null,
    latitude?: string | null,
    longitude?: string | null,
    teamId?: string | null,
    teamOrganizationId?: string | null,
    gameId?: string | null,
    hashtag?: string | null,
    urlSlug?: string | null,
    quantifiable?: number | null,
    homepageUrl?: string | null,
    isGenre?: number | null,
    id?: string | null
  ) => Promise<false | MetaItem>
  updateFunc: (
    name: string,
    nameJa: string,
    sortJa: string,
    isGameDeveloper?: boolean | null,
    primaryImage?: string | null,
    secondaryImage?: string | null,
    latitude?: string | null,
    longitude?: string | null,
    teamId?: string | null,
    teamOrganizationId?: string | null,
    gameId?: string | null,
    hashtag?: string | null,
    urlSlug?: string | null,
    quantifiable?: number | null,
    homepageUrl?: string | null,
    isGenre?: number | null,
    id?: string
  ) => Promise<false | MetaItem>
  deleteFunc: (id: string) => Promise<boolean>
  metaType: MetaType
  gameDeveloperFields?: boolean
  primaryImageFieldsAndRatio?: number
  secondaryImageFieldsAndRatio?: number
  latLongFields?: boolean
  teamFields?: boolean
  teamOrganizationFields?: boolean
  gameFields?: boolean
  slugFields?: boolean
  quantifiableFields?: boolean
  hashtagFields?: boolean
  homepageUrlFields?: boolean
  isGenreFields?: boolean
  updateGroupsFunc?: (added: GroupLink[], removed: GroupLink[]) => Promise<any>
  updateGameAlternativeNamesFunc?: (
    added: GameAlternativeName[],
    removed: GameAlternativeName[]
  ) => Promise<any>
  allowNonUniqueNames?: boolean
  updateParentTagsFunc?: (added: GroupLink[], removed: GroupLink[]) => Promise<any>
  idFields?: boolean
}

const MetaPage = ({
  title,
  fetchFunc,
  addNewFunc,
  updateFunc,
  deleteFunc,
  metaType,
  gameDeveloperFields,
  primaryImageFieldsAndRatio,
  secondaryImageFieldsAndRatio,
  latLongFields,
  teamFields,
  teamOrganizationFields,
  gameFields,
  hashtagFields,
  quantifiableFields,
  slugFields,
  homepageUrlFields,
  isGenreFields,
  updateGroupsFunc,
  updateGameAlternativeNamesFunc,
  allowNonUniqueNames,
  updateParentTagsFunc,
  idFields,
}: Props) => {
  const [metaListState, setMetaListState] = useState([])
  const [errorState, setErrorState] = useState(null)
  const [isLoadingState, setIsLoadingState] = useState(true)
  const [isSavingState, setIsSavingState] = useState(false)
  const [availableGameGroupsState, setAvailableGameGroupsState] = useState([])
  const [availableTeamsState, setAvailableTeamsState] = useState([])
  const [availableTeamOrganizationsState, setAvailableTeamOrganizationsState] = useState([])
  const [availableGamesState, setAvailableGamesState] = useState([])
  const [sortParamsState, setSortParamsState] = useState<SortParams>({
    field: "ja",
    sort: "asc",
  })

  const [newMetaIdState, setNewMetaIdState] = useState("")
  const [newMetaNameState, setNewMetaNameState] = useState("")
  const [newMetaNameJaState, setNewMetaNameJaState] = useState("")
  const [newMetaSortJaState, setNewMetaSortJaState] = useState("")
  const [newMetaIsGameDeveloperState, setNewMetaIsGameDeveloperState] = useState(false)
  const [newMetaPrimaryImageState, setNewMetaPrimaryImageState] = useState("")
  const [newMetaSecondaryImageState, setNewMetaSecondaryImageState] = useState("")
  const [newMetaLatitudeState, setNewMetaLatitudeState] = useState("")
  const [newMetaLongitudeState, setNewMetaLongitudeState] = useState("")
  const [newMetaTeamState, setNewMetaTeamState] = useState(null)
  const [newMetaTeamOrganizationState, setNewMetaTeamOrganizationState] = useState(null)
  const [newMetaHashtagState, setNewMetaHashtagState] = useState("")
  const [newMetaGameIdState, setNewMetaGameIdState] = useState(null)
  const [newMetaSlugState, setNewMetaSlugState] = useState("")
  const [newMetaQuantifiableState, setNewMetaQuantifiableState] = useState(0)
  const [newMetaHomepageUrlState, setNewMetaHomepageUrlState] = useState("")
  const [newMetaIsGenreState, setNewMetaIsGenreState] = useState(0)

  const [editIdState, setEditIdState] = useState(null)
  const [editTextState, setEditTextState] = useState("")
  const [editNameJaState, setEditNameJaState] = useState("")
  const [editSortJaState, setEditSortJaState] = useState("")
  const [editIsGameDeveloperState, setEditIsGameDeveloperState] = useState(null)
  const [editPrimaryImageState, setEditPrimaryImageState] = useState("")
  const [editSecondaryImageState, setEditSecondaryImageState] = useState("")
  const [editGameGroupsState, setEditGameGroupsState] = useState([])
  const [editGameGroupsStateOriginal, setEditGameGroupsStateOriginal] = useState([])
  const [editLatitudeState, setEditLatitudeState] = useState("")
  const [editLongitudeState, setEditLongitudeState] = useState("")
  const [editTeamState, setEditTeamState] = useState(null)
  const [editTeamOrganizationState, setEditTeamOrganizationState] = useState(null)
  const [editGameAlternativeNamesState, setEditGameAlternativeNamesState] = useState([])
  const [editGameAlternativeNamesOriginalState, setEditGameAlternativeNamesOriginalState] = useState([])
  const [editGameAlternativeNameInputState, setEditGameAlternativeNameInputState] = useState("")
  const [editGameIdState, setEditGameIdState] = useState(null)
  const [editHashtagState, setEditHashtagState] = useState("")
  const [editSlugState, setEditSlugState] = useState("")
  const [editQuantifiableState, setEditQuantifiableState] = useState(0)
  const [editHomepageUrlState, setEditHomepageUrlState] = useState("")
  const [editIsGenreState, setEditIsGenreState] = useState(0)
  const [editParentTagsState, setEditParentTagsState] = useState([])
  const [editParentTagsStateOriginal, setEditParentTagsStateOriginal] = useState([])

  const [deleteMetaObjectState, setDeleteMetaObjectState] = useState(null)
  const { t } = useTranslation(["taiyoro", "common"])
  const location = useLocation()
  const [focusIdState, setFocusIdState] = useState<null | string>(null)
  const canDeleteMeta = useRolesCanAccess(HIGH_ACCESS_ROLES)
  const canCreateAndEditMeta = useRolesCanAccess([
    ...ADMIN_ROLES,
    UserRole.PRODUCER,
    UserRole.PUBLISHER,
    UserRole.ANALYST,
    UserRole.EDITOR,
  ])
  const { tagHierarchy, refreshTags } = useContext(TaiyoroDataContext)

  const fieldSort = (a: MetaItem, b: MetaItem) => {
    if (sortParamsState.field === "ja") {
      const optionalA = a["sortJa"] || ""
      const optionalB = b["sortJa"] || ""
      return sortParamsState.sort === "asc"
        ? optionalA.localeCompare(optionalB, "ja")
        : optionalB.localeCompare(optionalA, "ja")
    }
    if (sortParamsState.field === "en") {
      const optionalA = a["name"] || ""
      const optionalB = b["name"] || ""
      return sortParamsState.sort === "asc"
        ? optionalA.localeCompare(optionalB)
        : optionalB.localeCompare(optionalA)
    }
    return sortParamsState.sort === "asc"
      ? a.updatedAt.localeCompare(b.updatedAt)
      : b.updatedAt.localeCompare(a.updatedAt)
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const focusId = params.get("id")
    focusId && focusId !== focusIdState && setFocusIdState(params.get("id"))
  }, [location, focusIdState])

  const getParentTagsForId = (tagHierarchy: TagNode[], id: string): Array<Tag> => {
    let parentTags: Array<Tag> = []

    for (const tagNode of tagHierarchy || []) {
      const { tag, children } = tagNode

      for (const child of children) {
        if (child.tag.id === id) {
          parentTags.push(tag)
          break
        }
      }

      if (children.length > 0) {
        const matchingParents = getParentTagsForId(children, id)
        parentTags = parentTags.concat(matchingParents)
      }
    }

    return parentTags
  }

  const saveNewMeta = async () => {
    try {
      const result = await addNewFunc(
        newMetaNameState,
        newMetaNameJaState,
        newMetaSortJaState,
        newMetaIsGameDeveloperState,
        newMetaPrimaryImageState,
        newMetaSecondaryImageState,
        newMetaLatitudeState,
        newMetaLongitudeState,
        newMetaTeamState,
        newMetaTeamOrganizationState,
        newMetaHashtagState,
        newMetaGameIdState,
        newMetaSlugState,
        newMetaQuantifiableState,
        newMetaHomepageUrlState,
        newMetaIsGenreState,
        newMetaIdState !== "" ? newMetaIdState : null
      )
      if (result === false) {
        setErrorState(t("meta.errors.saving"))
        return
      }
      const newMetaList = [...metaListState, result]
      setMetaListState(newMetaList.sort(sortByEnglishNameOrJapaneseSort))
      setNewMetaNameState("")
      setNewMetaNameJaState("")
      setNewMetaSortJaState("")
      setNewMetaIsGameDeveloperState(false)
      setNewMetaPrimaryImageState("")
      setNewMetaSecondaryImageState("")
      setNewMetaLatitudeState("")
      setNewMetaLongitudeState("")
      setNewMetaTeamState(null)
      setNewMetaTeamOrganizationState(null)
      setNewMetaHashtagState("")
      setNewMetaSlugState("")
      setNewMetaQuantifiableState(0)
      setNewMetaHomepageUrlState("")
      setNewMetaIsGenreState(0)
      setNewMetaIdState("")
    } catch (err) {
      setErrorState(err)
    } finally {
      setIsSavingState(false)
      if (updateParentTagsFunc) {
        refreshTags()
      }
    }
  }

  const updateMeta = async (id) => {
    try {
      if (updateGroupsFunc) {
        const addedGroups = editGameGroupsState
          .filter((gs) => !(editGameGroupsStateOriginal || []).some((g) => gs.id === g.id))
          .map((group) => ({ refId: id, parentGroupId: group.id }))
        const removedGroups = (editGameGroupsStateOriginal || [])
          .filter((g) => !editGameGroupsState.some((gs) => gs.id === g.id))
          .map((group) => ({ refId: id, parentGroupId: group.id }))
        await updateGroupsFunc(addedGroups, removedGroups)
      }

      if (updateGameAlternativeNamesFunc) {
        const addedNames = editGameAlternativeNamesState
          .filter((entity) => !editGameAlternativeNamesOriginalState.some((e) => e === entity))
          .map((name) => ({ gameId: editIdState as any, name: name }))
        const removedNames = editGameAlternativeNamesOriginalState
          .filter((e) => !editGameAlternativeNamesState.some((entity) => entity === e))
          .map((name) => ({ gameId: editIdState as any, name: name }))
        await updateGameAlternativeNamesFunc(addedNames, removedNames)
      }

      if (updateParentTagsFunc) {
        const addedTags = editParentTagsState
          .filter((entity) => !editParentTagsStateOriginal.some((e) => e === entity))
          .map((parentTag) => ({ refId: editIdState as any, parentGroupId: parentTag.id }))
        const removedTags = editParentTagsStateOriginal
          .filter((e) => !editParentTagsState.some((entity) => entity === e))
          .map((parentTag) => ({ refId: editIdState as any, parentGroupId: parentTag.id }))
        await updateParentTagsFunc(addedTags, removedTags)
      }

      const result = await updateFunc(
        editTextState,
        editNameJaState,
        editSortJaState,
        editIsGameDeveloperState,
        editPrimaryImageState,
        editSecondaryImageState,
        editLatitudeState,
        editLongitudeState,
        editTeamState,
        editTeamOrganizationState,
        editHashtagState,
        editGameIdState,
        editSlugState,
        editQuantifiableState,
        editHomepageUrlState,
        editIsGenreState,
        id
      )
      if (result === false) {
        setErrorState(t("meta.errors.saving"))
        return
      }
      const newMetaList = metaListState.map((meta) => {
        if (meta.id !== editIdState) return meta
        meta.name = result.name
        meta.nameJa = result.nameJa
        meta.sortJa = result.sortJa
        meta.isGameDeveloper = result.isGameDeveloper
        meta.primaryImage = result.primaryImage
        meta.secondaryImage = result.secondaryImage
        meta.latitude = result.latitude
        meta.longitude = result.longitude
        meta.teamId = result.teamId
        meta.teamOrganizationId = result.teamOrganizationId
        meta.hashtag = result.hashtag
        meta.urlSlug = result.urlSlug
        meta.gameId = result.gameId
        meta.quantifiable = result.quantifiable
        meta.homepageUrl = result.homepageUrl
        meta.isGenre = result.isGenre
        meta.updatedAt = result.updatedAt
        if (updateGroupsFunc) {
          meta.parentGroups = editGameGroupsState
        }
        if (updateGameAlternativeNamesFunc) {
          meta.alternativeNames = editGameAlternativeNamesState
        }
        return meta
      })
      newMetaList.sort(sortByEnglishNameOrJapaneseSort)
      setMetaListState(newMetaList)
      setEditIdState(null)
      setEditTextState("")
      setEditNameJaState("")
      setEditSortJaState("")
      setEditIsGameDeveloperState(false)
      setEditPrimaryImageState("")
      setEditSecondaryImageState("")
      setEditGameGroupsState([])
      setEditGameGroupsStateOriginal([])
      setEditLatitudeState("")
      setEditLongitudeState("")
      setEditTeamState(null)
      setEditTeamOrganizationState(null)
      setEditGameAlternativeNamesState([])
      setEditGameAlternativeNameInputState("")
      setEditGameAlternativeNamesOriginalState([])
      setEditHashtagState("")
      setEditSlugState("")
      setEditGameIdState(null)
      setEditQuantifiableState(0)
      setEditHomepageUrlState("")
      setEditIsGenreState(0)
      setEditParentTagsState([])
      setEditParentTagsStateOriginal([])
    } catch (err) {
      setErrorState(err)
    } finally {
      setIsSavingState(false)
      if (updateParentTagsFunc) {
        refreshTags()
      }
    }
  }

  useEffect(() => {
    const getMeta = async () => {
      setIsLoadingState(true)
      try {
        if (updateGroupsFunc) {
          const result = await fetchGameGroups()
          setAvailableGameGroupsState(result)
        }
        if (teamOrganizationFields) {
          const result = await fetchTeamOrganizations()
          setAvailableTeamOrganizationsState(result)
        }

        if (gameFields) {
          const result = await fetchGames()
          setAvailableGamesState(result)
        }

        if (teamFields) {
          const result = await fetchTeams()
          setAvailableTeamsState(result)
        }

        const metas = await fetchFunc()
        if (metas !== null) {
          metas.sort(sortByEnglishNameOrJapaneseSort)
          metas.forEach((meta) => (meta.isGameDeveloper = meta.isGameDeveloper ? true : false))
          setMetaListState(metas)
        } else {
          setErrorState(t("meta.errors.loading"))
        }
      } catch (err) {
        setErrorState(err)
      }
      setIsLoadingState(false)
    }
    getMeta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFunc, updateGroupsFunc])

  useEffect(() => {
    if (focusIdState) {
      setTimeout(() => {
        const scroll = Scroll.scroller
        scroll.scrollTo(focusIdState, {
          offset: -100,
          isDynamic: true,
          smooth: true,
        })
      }, 0)
    }
  }, [metaListState, focusIdState])

  const handleOnDeleted = (meta: any) => {
    setMetaListState(metaListState.filter((m) => m.id !== meta.id))
    if (updateParentTagsFunc) {
      refreshTags()
    }
  }

  const metaTypeToMediaType = (metaType: MetaType) => {
    switch (metaType) {
      case MetaType.CASTER:
        return "caster"
      case MetaType.GAME:
        return "game"
      case MetaType.GAME_GROUP:
        return "game_group"
      case MetaType.ORGANIZER:
        return "organizer"
      case MetaType.PLACEMENT:
        return "placement"
      case MetaType.PLATFORM:
        return "platform"
      case MetaType.PRODUCER:
        return "producer"
      case MetaType.SIGNIFICANT_PLAYER:
        return "significant_player"
      case MetaType.SPONSOR:
        return "sponsor"
      case MetaType.TAG:
        return "tag"
      case MetaType.TEAM:
        return "team"
      case MetaType.VENUE:
        return "venue"
      case MetaType.TEAM_ORGANIZATION:
        return "team_organization"
      default:
        return ""
    }
  }

  const handleSortChange = (field: "updatedAt" | "en" | "ja") => {
    if (sortParamsState.field === field) {
      setSortParamsState({ ...sortParamsState, sort: sortParamsState.sort === "asc" ? "desc" : "asc" })
      return
    }
    setSortParamsState({ field: field, sort: "asc" })
  }

  return (
    <>
      <DeleteMeta
        meta={deleteMetaObjectState}
        type={metaType}
        replacementList={metaListState}
        onModalClosed={() => setDeleteMetaObjectState(null)}
        deleteFunc={deleteFunc}
        onDeleted={handleOnDeleted}
      />
      <TableList size={"medium"}>
        <TableList.Header>
          <TableList.HeaderRow>
            {idFields && <TableList.HeaderCell>{t("meta.headers.id")}</TableList.HeaderCell>}
            <SortableTableCell onClick={() => handleSortChange("en")}>
              {t("meta.headers.name")}
              <SortArrow>
                {sortParamsState.field === "en" && (
                  <FontAwesomeIcon
                    color="#f30362"
                    icon={sortParamsState.sort === "asc" ? faArrowUp : faArrowDown}
                  />
                )}
              </SortArrow>
            </SortableTableCell>
            <TableList.HeaderCell>{t("meta.headers.nameJa")}</TableList.HeaderCell>
            <SortableTableCell onClick={() => handleSortChange("ja")}>
              {t("meta.headers.sortJa")}
              <SortArrow>
                {sortParamsState.field === "ja" && (
                  <FontAwesomeIcon
                    color="#f30362"
                    icon={sortParamsState.sort === "asc" ? faArrowUp : faArrowDown}
                  />
                )}
              </SortArrow>
            </SortableTableCell>
            {slugFields && <TableList.HeaderCell>{t("meta.headers.slug")}</TableList.HeaderCell>}
            {hashtagFields && <TableList.HeaderCell>{t("meta.headers.hashtag")}</TableList.HeaderCell>}
            {updateGameAlternativeNamesFunc && (
              <TableList.HeaderCell>{t("meta.headers.alternativeNames")}</TableList.HeaderCell>
            )}
            {gameDeveloperFields && (
              <TableList.HeaderCell>{t("meta.headers.isGameDeveloper")}</TableList.HeaderCell>
            )}
            {primaryImageFieldsAndRatio && (
              <TableList.HeaderCell>{t("meta.headers.primaryImage")}</TableList.HeaderCell>
            )}
            {secondaryImageFieldsAndRatio && (
              <TableList.HeaderCell>{t("meta.headers.secondaryImage")}</TableList.HeaderCell>
            )}
            {gameFields && <TableList.HeaderCell>{t("meta.headers.game")}</TableList.HeaderCell>}
            {latLongFields && (
              <>
                <TableList.HeaderCell>{t("meta.headers.latitude")}</TableList.HeaderCell>
                <TableList.HeaderCell>{t("meta.headers.longitude")}</TableList.HeaderCell>
              </>
            )}
            {teamFields && <TableList.HeaderCell>{t("meta.headers.team")}</TableList.HeaderCell>}
            {updateGroupsFunc && <TableList.HeaderCell>{t("meta.headers.groups")}</TableList.HeaderCell>}
            {teamOrganizationFields && (
              <TableList.HeaderCell>{t("meta.headers.teamOrganization")}</TableList.HeaderCell>
            )}
            {quantifiableFields && (
              <TableList.HeaderCell>{t("meta.headers.quantifiable")}</TableList.HeaderCell>
            )}
            {homepageUrlFields && (
              <TableList.HeaderCell>{t("meta.headers.homepageUrl")}</TableList.HeaderCell>
            )}
            {updateParentTagsFunc && (
              <TableList.HeaderCell>{t("meta.headers.parentTags")}</TableList.HeaderCell>
            )}
            {isGenreFields && <TableList.HeaderCell>{t("meta.headers.isGenre")}</TableList.HeaderCell>}
            <SortableTableCell onClick={() => handleSortChange("updatedAt")}>
              {t("meta.headers.updatedAt")}
              <SortArrow>
                {sortParamsState.field === "updatedAt" && (
                  <FontAwesomeIcon
                    color="#f30362"
                    icon={sortParamsState.sort === "asc" ? faArrowUp : faArrowDown}
                  />
                )}
              </SortArrow>
            </SortableTableCell>
            <TableList.HeaderCell></TableList.HeaderCell>
          </TableList.HeaderRow>
        </TableList.Header>
        <TableList.Body>
          {!isLoadingState &&
            metaListState &&
            [...metaListState].sort(fieldSort).map((meta) => (
              <TableList.BodyRow
                key={`meta-list-${meta.id}`}
                data-id={meta.id}
                sx={
                  meta.id === focusIdState ? { backgroundColor: (theme) => theme.palette.action.focus } : {}
                }
              >
                {idFields && (
                  <TableList.BodyCell>
                    <Scroll.Element name={meta.id}>
                      <div>{meta.id}</div>
                    </Scroll.Element>
                  </TableList.BodyCell>
                )}
                <TableList.BodyCell>
                  <Scroll.Element name={meta.id}>
                    {meta.id === editIdState ? (
                      <TextField
                        label={t("meta.headers.name")}
                        variant="outlined"
                        value={editTextState}
                        onChange={(event) => setEditTextState(event.target.value)}
                        style={{ width: "100%", margin: "10px 0" }}
                        disabled={isSavingState}
                      />
                    ) : (
                      <div>{meta.name}</div>
                    )}
                  </Scroll.Element>
                </TableList.BodyCell>
                <TableList.BodyCell>
                  {meta.id === editIdState ? (
                    <TextField
                      label={t("meta.headers.nameJa")}
                      variant="outlined"
                      value={editNameJaState || ""}
                      onChange={(event) => setEditNameJaState(event.target.value)}
                      style={{ width: "100%", margin: "10px 0" }}
                      disabled={isSavingState}
                    />
                  ) : (
                    <div>{meta.nameJa}</div>
                  )}
                </TableList.BodyCell>
                <TableList.BodyCell>
                  {meta.id === editIdState ? (
                    <TextField
                      label={t("meta.headers.sortJa")}
                      variant="outlined"
                      value={editSortJaState || ""}
                      onChange={(event) => setEditSortJaState(event.target.value)}
                      style={{ width: "100%", margin: "10px 0" }}
                      disabled={isSavingState}
                    />
                  ) : (
                    <div>{meta.sortJa}</div>
                  )}
                </TableList.BodyCell>
                {slugFields && (
                  <TableList.BodyCell>
                    {meta.id === editIdState ? (
                      <TextField
                        label={t("meta.headers.slug")}
                        variant="outlined"
                        value={editSlugState || ""}
                        onChange={(event) => setEditSlugState(event.target.value)}
                        style={{ width: "100%", margin: "10px 0" }}
                        disabled={isSavingState}
                      />
                    ) : (
                      <div>{meta.urlSlug}</div>
                    )}
                  </TableList.BodyCell>
                )}
                {hashtagFields && (
                  <TableList.BodyCell>
                    {meta.id === editIdState ? (
                      <TextField
                        label={t("meta.headers.hashtag")}
                        variant="outlined"
                        value={editHashtagState || ""}
                        onChange={(event) => setEditHashtagState(event.target.value.replaceAll("#", ""))}
                        style={{ width: "100%", margin: "10px 0" }}
                        disabled={isSavingState}
                      />
                    ) : (
                      <div>{meta.hashtag}</div>
                    )}
                  </TableList.BodyCell>
                )}
                {updateGameAlternativeNamesFunc && (
                  <TableList.BodyCell>
                    {meta.id === editIdState && (
                      <>
                        {(editGameAlternativeNamesState || []).map((name) => (
                          <Chip
                            key={name}
                            style={{ marginRight: "6px" }}
                            color="primary"
                            label={name}
                            onDelete={() =>
                              setEditGameAlternativeNamesState(
                                editGameAlternativeNamesState.filter((x) => x !== name)
                              )
                            }
                          />
                        ))}
                        <TextField
                          label={t("meta.headers.alternativeNames")}
                          value={editGameAlternativeNameInputState}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setEditGameAlternativeNamesState([
                                      ...editGameAlternativeNamesState,
                                      editGameAlternativeNameInputState,
                                    ])
                                    setEditGameAlternativeNameInputState("")
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlusSquare} />
                                </span>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => setEditGameAlternativeNameInputState(event.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setEditGameAlternativeNamesState([
                                ...editGameAlternativeNamesState,
                                editGameAlternativeNameInputState,
                              ])
                              setEditGameAlternativeNameInputState("")
                              e.preventDefault()
                            }
                          }}
                        />
                      </>
                    )}
                    {meta.id !== editIdState &&
                      (meta.alternativeNames || []).map((name) => (
                        <Chip
                          key={name}
                          style={{ marginRight: "6px" }}
                          color="primary"
                          label={name}
                        />
                      ))}
                  </TableList.BodyCell>
                )}
                {gameDeveloperFields && (
                  <TableList.BodyCell>
                    <Switch
                      color="primary"
                      name={meta.id}
                      disabled={isSavingState || meta.id !== editIdState}
                      checked={meta.id === editIdState ? editIsGameDeveloperState : meta.isGameDeveloper}
                      onChange={() => setEditIsGameDeveloperState(!editIsGameDeveloperState)}
                    />
                  </TableList.BodyCell>
                )}
                {primaryImageFieldsAndRatio && (
                  <TableList.BodyCell>
                    <MediaUpload
                      key="edit-primary"
                      url={meta.id === editIdState ? editPrimaryImageState : meta.primaryImage}
                      mediaType={metaTypeToMediaType(metaType)}
                      onChange={(url) => setEditPrimaryImageState(url)}
                      editable={meta.id === editIdState}
                      aspect={primaryImageFieldsAndRatio}
                      buttonText={t("meta.controls.chooseFile")}
                    />
                  </TableList.BodyCell>
                )}
                {secondaryImageFieldsAndRatio && (
                  <TableList.BodyCell>
                    <MediaUpload
                      key="edit-secondary"
                      url={meta.id === editIdState ? editSecondaryImageState : meta.secondaryImage}
                      mediaType={metaTypeToMediaType(metaType)}
                      onChange={(url) => setEditSecondaryImageState(url)}
                      editable={meta.id === editIdState}
                      aspect={secondaryImageFieldsAndRatio}
                      buttonText={t("meta.controls.chooseFile")}
                    />
                  </TableList.BodyCell>
                )}
                {latLongFields && (
                  <>
                    <TableList.BodyCell>
                      {meta.id === editIdState ? (
                        <TextField
                          label="GPS Latitude"
                          variant="outlined"
                          value={editLatitudeState}
                          onChange={(event) => setEditLatitudeState(event.target.value)}
                          style={{ margin: "10px 0" }}
                          disabled={isSavingState}
                        />
                      ) : (
                        meta.latitude
                      )}
                    </TableList.BodyCell>
                    <TableList.BodyCell>
                      {meta.id === editIdState ? (
                        <TextField
                          label="GPS Longitude"
                          variant="outlined"
                          value={editLongitudeState}
                          onChange={(event) => setEditLongitudeState(event.target.value)}
                          style={{ margin: "10px 0" }}
                          disabled={isSavingState}
                        />
                      ) : (
                        meta.longitude
                      )}
                    </TableList.BodyCell>
                  </>
                )}
                {quantifiableFields && (
                  <TableList.BodyCell>
                    {meta.id === editIdState ? (
                      <Checkbox
                        checked={editQuantifiableState === 1}
                        onChange={(_event, checked) => setEditQuantifiableState(checked ? 1 : 0)}
                      />
                    ) : (
                      <Checkbox
                        checked={meta.quantifiable === 1}
                        disabled={editQuantifiableState === 1}
                      />
                    )}
                  </TableList.BodyCell>
                )}
                {homepageUrlFields && (
                  <TableList.BodyCell>
                    {meta.id === editIdState ? (
                      <TextField
                        label={t("meta.headers.homepageUrl")}
                        variant="outlined"
                        value={editHomepageUrlState}
                        onChange={(event) => setEditHomepageUrlState(event.target.value)}
                        style={{ margin: "10px 0" }}
                        disabled={isSavingState}
                      />
                    ) : (
                      <>
                        {meta.homepageUrl && (
                          <Link
                            href={meta.homepageUrl}
                            target="_blank"
                          >
                            {new URL(meta.homepageUrl).hostname}
                          </Link>
                        )}
                      </>
                    )}
                  </TableList.BodyCell>
                )}
                {updateParentTagsFunc && (
                  <TableList.BodyCell style={{ minWidth: "250px" }}>
                    {meta.id === editIdState ? (
                      <Autocomplete
                        multiple
                        options={metaListState}
                        getOptionLabel={localisedLabel}
                        value={editParentTagsState}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              key={option.id}
                              variant="outlined"
                              label={localisedLabel(option)}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(_, newValue) => {
                          setEditParentTagsState(newValue)
                        }}
                      />
                    ) : (
                      <>
                        {
                          <Stack
                            gap={1}
                            direction="row"
                          >
                            {getParentTagsForId(tagHierarchy, meta.id).map((parentTag) => (
                              <Chip
                                label={localisedLabel(parentTag)}
                                key={parentTag.id}
                              />
                            ))}
                          </Stack>
                        }
                      </>
                    )}
                  </TableList.BodyCell>
                )}
                {teamFields && (
                  <TableList.BodyCell>
                    {meta.id === editIdState ? (
                      <DropdownSearchable
                        label="Team"
                        value={availableTeamsState.find((team) => team.id === editTeamState)}
                        options={availableTeamsState}
                        onChange={(e) => setEditTeamState(e && e.id)}
                        disabled={isSavingState}
                      />
                    ) : (
                      availableTeamsState.find((team) => team.id === meta.teamId)?.name
                    )}
                  </TableList.BodyCell>
                )}
                {gameFields && (
                  <TableList.BodyCell>
                    {meta.id === editIdState ? (
                      <DropdownSearchable
                        label="Game"
                        value={availableGamesState.find((game) => game.id === editGameIdState)}
                        options={availableGamesState}
                        onChange={(e) => setEditGameIdState(e && e.id)}
                        disabled={isSavingState}
                      />
                    ) : (
                      localisedLabel(availableGamesState.find((game) => game.id === meta.gameId))
                    )}
                  </TableList.BodyCell>
                )}
                {teamOrganizationFields && (
                  <TableList.BodyCell>
                    {meta.id === editIdState ? (
                      <DropdownSearchable
                        label="Team organization"
                        value={availableTeamOrganizationsState.find(
                          (organization) => organization.id === editTeamOrganizationState
                        )}
                        options={availableTeamOrganizationsState}
                        onChange={(e) => setEditTeamOrganizationState(e && e.id)}
                        disabled={isSavingState}
                      />
                    ) : (
                      availableTeamOrganizationsState.find(
                        (teamOrganization) => teamOrganization.id === meta.teamOrganizationId
                      )?.name
                    )}
                  </TableList.BodyCell>
                )}
                {updateGroupsFunc && (
                  <TableList.BodyCell>
                    {meta.id === editIdState ? (
                      <DropdownSearchable
                        label={t("meta.headers.groups")}
                        clearOnBlur
                        options={availableGameGroupsState}
                        multiple
                        value={editGameGroupsState}
                        onChange={setEditGameGroupsState}
                      />
                    ) : (
                      <div>
                        {(meta.parentGroups || []).map((g) => (
                          <Chip
                            key={g.id}
                            style={{ marginRight: "6px" }}
                            color="primary"
                            label={availableGameGroupsState.find((agg) => g.id === agg.id).name}
                          />
                        ))}
                      </div>
                    )}
                  </TableList.BodyCell>
                )}
                {isGenreFields && (
                  <TableList.BodyCell>
                    {meta.id === editIdState ? (
                      <Checkbox
                        checked={editIsGenreState === 1}
                        onChange={(_event, checked) => setEditIsGenreState(checked ? 1 : 0)}
                      />
                    ) : (
                      <Checkbox
                        checked={meta.isGenre === 1}
                        disabled
                      />
                    )}
                  </TableList.BodyCell>
                )}
                <TableList.BodyCell>
                  {LocalMomentFromUTCDateTime(meta.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                </TableList.BodyCell>
                <TableList.BodyCell>
                  <div style={{ textAlign: "right" }}>
                    {meta.id === editIdState ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                      >
                        <StyleButton
                          variant="contained"
                          onClick={() => {
                            setIsSavingState(true)
                            updateMeta(editIdState)
                          }}
                          disabled={isLoadingState === true || isSavingState === true || editTextState === ""}
                        >
                          {isSavingState ? <CircularProgress /> : t("common:actions.save")}
                        </StyleButton>
                        <Tooltip title={t("common:actions.cancel")}>
                          <IconButton
                            onClick={() => {
                              setEditTextState("")
                              setEditNameJaState("")
                              setEditSortJaState("")
                              setEditIsGameDeveloperState(null)
                              setEditPrimaryImageState("")
                              setEditSecondaryImageState("")
                              setEditLatitudeState("")
                              setEditLongitudeState("")
                              setEditTeamState(null)
                              setEditTeamOrganizationState(null)
                              setEditGameIdState(null)
                              setEditGameAlternativeNameInputState("")
                              setEditHashtagState("")
                              setEditSlugState("")
                              setEditQuantifiableState(0)
                              setEditHomepageUrlState("")
                              setEditIsGenreState(0)
                              setEditParentTagsState([])
                              setEditIdState(null)
                            }}
                            disabled={
                              isLoadingState === true || isSavingState === true || editTextState === ""
                            }
                            sx={{ width: "32px", height: "32px" }}
                          >
                            <FontAwesomeIcon icon={faWindowClose} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    ) : (
                      <React.Fragment>
                        {canCreateAndEditMeta && (
                          <Tooltip title={t("common:actions.edit")}>
                            <IconButton
                              sx={{ width: "32px", height: "32px" }}
                              onClick={() => {
                                setEditTextState(meta.name)
                                setEditNameJaState(meta.nameJa)
                                setEditSortJaState(meta.sortJa)
                                setEditIsGameDeveloperState(meta.isGameDeveloper)
                                setEditPrimaryImageState(meta.primaryImage)
                                setEditSecondaryImageState(meta.secondaryImage)
                                setEditLatitudeState(meta.latitude)
                                setEditLongitudeState(meta.longitude)
                                setEditTeamState(meta.teamId)
                                setEditIdState(meta.id)
                                setEditSlugState(meta.urlSlug)
                                setEditGameGroupsState(
                                  (meta.parentGroups || []).map((g) =>
                                    availableGameGroupsState.find((pg) => g.id === pg.id)
                                  )
                                )
                                setEditGameGroupsStateOriginal(
                                  (meta.parentGroups || []).map((g) =>
                                    availableGameGroupsState.find((pg) => g.id === pg.id)
                                  )
                                )
                                setEditTeamOrganizationState(meta.teamOrganizationId)
                                setEditGameAlternativeNameInputState("")
                                setEditGameAlternativeNamesState(
                                  (meta.alternativeNames && [...meta.alternativeNames]) || []
                                )
                                setEditGameAlternativeNamesOriginalState(
                                  (meta.alternativeNames && [...meta.alternativeNames]) || []
                                )
                                setEditGameIdState(meta.gameId)
                                setEditHashtagState(meta.hashtag)
                                setEditQuantifiableState(meta.quantifiable)
                                setEditHomepageUrlState(meta.homepageUrl)
                                setEditParentTagsState(getParentTagsForId(tagHierarchy, meta.id))
                                setEditParentTagsStateOriginal(getParentTagsForId(tagHierarchy, meta.id))
                                setEditIsGenreState(meta.isGenre)
                              }}
                              disabled={
                                isLoadingState === true || isSavingState === true || editTextState !== ""
                              }
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {canDeleteMeta && (
                          <Tooltip title={t("common:actions.delete")}>
                            <IconButton
                              color="error"
                              sx={{ width: "32px", height: "32px" }}
                              onClick={() => setDeleteMetaObjectState(meta)}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </TableList.BodyCell>
              </TableList.BodyRow>
            ))}
          {!isLoadingState && metaListState.length === 0 && (
            <TableList.BodyRow>
              <TableList.BodyCell>{t("meta.noItems")}</TableList.BodyCell>
            </TableList.BodyRow>
          )}
          {isLoadingState && (
            <TableList.BodyRow>
              <TableList.BodyCell>
                <CircularProgress />
              </TableList.BodyCell>
            </TableList.BodyRow>
          )}
          {canCreateAndEditMeta && (
            <TableList.BodyRow>
              {idFields && (
                <TableList.BodyCell>
                  <TextField
                    label={t("meta.headers.id")}
                    variant="outlined"
                    value={newMetaIdState}
                    onChange={(event) => setNewMetaIdState(event.target.value)}
                    style={{ margin: "10px 0" }}
                    disabled={isSavingState}
                  />
                </TableList.BodyCell>
              )}
              <TableList.BodyCell>
                <TextField
                  label={t("meta.headers.name")}
                  variant="outlined"
                  value={newMetaNameState}
                  onChange={(event) => setNewMetaNameState(event.target.value)}
                  style={{ margin: "10px 0" }}
                  disabled={isSavingState}
                />
              </TableList.BodyCell>
              <TableList.BodyCell>
                <TextField
                  label={t("meta.headers.nameJa")}
                  variant="outlined"
                  value={newMetaNameJaState}
                  onChange={(event) => setNewMetaNameJaState(event.target.value)}
                  style={{ margin: "10px 0" }}
                />
              </TableList.BodyCell>
              <TableList.BodyCell>
                <TextField
                  label={t("meta.headers.sortJa")}
                  variant="outlined"
                  value={newMetaSortJaState}
                  onChange={(event) => setNewMetaSortJaState(event.target.value)}
                  style={{ margin: "10px 0" }}
                />
              </TableList.BodyCell>
              {slugFields && (
                <TableList.BodyCell>
                  <TextField
                    label={t("meta.headers.slug")}
                    variant="outlined"
                    value={newMetaSlugState}
                    onChange={(event) => setNewMetaSlugState(event.target.value)}
                    style={{ margin: "10px 0" }}
                  />
                </TableList.BodyCell>
              )}
              {hashtagFields && (
                <TableList.BodyCell>
                  <TextField
                    label={t("meta.headers.hashtag")}
                    variant="outlined"
                    value={newMetaHashtagState}
                    onChange={(event) => setNewMetaHashtagState(event.target.value)}
                    style={{ margin: "10px 0" }}
                  />
                </TableList.BodyCell>
              )}
              {updateGameAlternativeNamesFunc && <TableList.BodyCell></TableList.BodyCell>}
              {gameDeveloperFields && (
                <TableList.BodyCell>
                  <Switch
                    color="primary"
                    name="newSwitch"
                    checked={newMetaIsGameDeveloperState}
                    onChange={() => setNewMetaIsGameDeveloperState(!newMetaIsGameDeveloperState)}
                  />
                </TableList.BodyCell>
              )}
              {primaryImageFieldsAndRatio && (
                <TableList.BodyCell>
                  <MediaUpload
                    key="add-primary"
                    url={newMetaPrimaryImageState}
                    mediaType={metaTypeToMediaType(metaType)}
                    onChange={(url) => setNewMetaPrimaryImageState(url)}
                    aspect={primaryImageFieldsAndRatio}
                    editable
                    buttonText={t("meta.controls.chooseFile")}
                  />
                </TableList.BodyCell>
              )}
              {secondaryImageFieldsAndRatio && (
                <TableList.BodyCell>
                  <MediaUpload
                    key="add-secondary"
                    url={newMetaSecondaryImageState}
                    mediaType={metaTypeToMediaType(metaType)}
                    onChange={(url) => setNewMetaSecondaryImageState(url)}
                    aspect={secondaryImageFieldsAndRatio}
                    editable
                    buttonText={t("meta.controls.chooseFile")}
                  />
                </TableList.BodyCell>
              )}
              {latLongFields && (
                <>
                  <TableList.BodyCell>
                    <TextField
                      label="GPS Latitude"
                      variant="outlined"
                      value={newMetaLatitudeState}
                      onChange={(event) => setNewMetaLatitudeState(event.target.value)}
                      style={{ margin: "10px 0" }}
                      disabled={isSavingState}
                    />
                  </TableList.BodyCell>
                  <TableList.BodyCell>
                    <TextField
                      label="GPS Longitude"
                      variant="outlined"
                      value={newMetaLongitudeState}
                      onChange={(event) => setNewMetaLongitudeState(event.target.value)}
                      style={{ margin: "10px 0" }}
                      disabled={isSavingState}
                    />
                  </TableList.BodyCell>
                </>
              )}
              {quantifiableFields && (
                <TableList.BodyCell>
                  <Checkbox
                    checked={newMetaQuantifiableState === 1}
                    onChange={(_event, checked) => setNewMetaQuantifiableState(checked ? 1 : 0)}
                  />
                </TableList.BodyCell>
              )}
              {homepageUrlFields && (
                <TableList.BodyCell>
                  <TextField
                    label={t("meta.headers.homepageUrl")}
                    variant="outlined"
                    value={newMetaHomepageUrlState}
                    onChange={(event) => setNewMetaHomepageUrlState(event.target.value)}
                    style={{ margin: "10px 0" }}
                    disabled={isSavingState}
                  />
                </TableList.BodyCell>
              )}
              {updateGroupsFunc && <TableList.BodyCell></TableList.BodyCell>}
              {updateParentTagsFunc && <TableList.BodyCell></TableList.BodyCell>}
              {isGenreFields && (
                <TableList.BodyCell>
                  <Checkbox
                    checked={newMetaIsGenreState === 1}
                    onChange={(_event, checked) => setNewMetaIsGenreState(checked ? 1 : 0)}
                  />
                </TableList.BodyCell>
              )}
              {teamFields && (
                <TableList.BodyCell>
                  <DropdownSearchable
                    label="Team"
                    value={availableTeamsState.find((team) => team.id === newMetaTeamState)}
                    options={availableTeamsState}
                    onChange={(e) => setNewMetaTeamState(e && e.id)}
                    disabled={isSavingState}
                  />
                </TableList.BodyCell>
              )}
              {gameFields && (
                <TableList.BodyCell>
                  <DropdownSearchable
                    label="Game"
                    value={availableGamesState.find((game) => game.id === newMetaGameIdState)}
                    options={availableGamesState}
                    onChange={(e) => setNewMetaGameIdState(e && e.id)}
                    disabled={isSavingState}
                  />
                </TableList.BodyCell>
              )}
              {teamOrganizationFields && (
                <TableList.BodyCell>
                  <DropdownSearchable
                    label="Team organization"
                    value={availableTeamOrganizationsState.find(
                      (teamOrganization) => teamOrganization.id === newMetaTeamOrganizationState
                    )}
                    options={availableTeamOrganizationsState}
                    onChange={(e) => setNewMetaTeamOrganizationState(e && e.id)}
                    disabled={isSavingState}
                  />
                </TableList.BodyCell>
              )}
              <TableList.BodyCell>
                <StyleButton
                  variant="contained"
                  onClick={() => {
                    setIsSavingState(true)
                    saveNewMeta()
                  }}
                  disabled={
                    newMetaNameState === "" ||
                    isLoadingState === true ||
                    isSavingState === true ||
                    (!allowNonUniqueNames &&
                      metaListState.filter((meta) => meta.name === newMetaNameState).length !== 0)
                  }
                >
                  {isSavingState ? <CircularProgress /> : t("common:actions.save")}
                </StyleButton>
              </TableList.BodyCell>
            </TableList.BodyRow>
          )}
        </TableList.Body>
      </TableList>

      <ErrorDialog
        open={errorState !== null}
        message={errorState || ""}
        onClose={() => setErrorState(null)}
      />
    </>
  )
}

MetaPage.propTypes = {
  title: PropTypes.string.isRequired,
  fetchFunc: PropTypes.func.isRequired,
  addNewFunc: PropTypes.func.isRequired,
  updateFunc: PropTypes.func.isRequired,
  updateGroupsFunc: PropTypes.func,
}

export default MetaPage

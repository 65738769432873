import React, { useState, useEffect, useContext } from "react"

import { Grid } from "@mui/material"
import RealTimeUpdateTagEventTags from "components/Taiyoro/CreateEvent/Information/RealTimeUpdateEventTags"
import { EventContext } from "contexts/event"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"
import { useDebounce } from "react-use"

import Caster from "../../../../models/Taiyoro/Meta/Caster"
import Currency from "../../../../models/Taiyoro/Meta/Currency"
import Organizer from "../../../../models/Taiyoro/Meta/Organizer"
import Producer from "../../../../models/Taiyoro/Meta/Producer"
import Event, { EventTier } from "../../../../models/Taiyoro/event"
import { Products } from "../../../../models/UserManagement"
import { createCaster } from "../../../../services/Taiyoro/casters"
import {
  deleteEventOrganizer,
  addEventOrganizer,
  deleteEventProducer,
  addEventProducer,
  editEvent,
  addEventCaster,
  deleteEventCaster,
} from "../../../../services/Taiyoro/event"
import { createOrganizer } from "../../../../services/Taiyoro/organizers"
import { createProducer } from "../../../../services/Taiyoro/producers"
import { getUserById } from "../../../../services/UserManagement/user"
import { openMetaEditNewTab } from "../../../../utils/uri"
import CountrySelect from "../../../CountrySelect"
import RealTimeUpdateField from "../../RealTimeUpdateField"
import EventImage from "./EventImage"
import { HelperText } from "./styles"

const TWITTER_URL = "https://twitter.com/"

interface Props {
  loadedModel: Event
  availableOrganizers: Organizer[]
  availableCurrencies: Currency[]
  availableProducers: Producer[]
  availableCasters: Caster[]
}

const Information = ({
  loadedModel,
  availableOrganizers,
  availableCurrencies,
  availableProducers,
  availableCasters,
}: Props) => {
  const { t } = useTranslation("taiyoro")

  const [availableCastersState, setAvailableCastersState] = useState(null)
  const [availableOrganizersState, setAvailableOrganizersState] = useState(null)
  const [availableCurrenciesState, setAvailableCurrenciesState] = useState([])
  const [availableProducersState, setAvailableProducersState] = useState(null)
  const [availableTierOptionsState] = useState(
    Object.values(EventTier)
      .filter((value) => typeof value !== "string")
      .map((tier) => {
        return {
          id: tier,
          name: t("tiers." + tier),
        }
      })
  )

  const [event, setEvent] = useContext(EventContext)
  const [urlSlugRef, setUrlSlugRef] = useState(loadedModel.urlSlug)
  const [loadedModelState, setLoadedModelState] = useState(null)

  const [ownerState, setOwnerState] = useState("")
  const [organizersState, setOrganizersState] = useState(null)
  const [producersState, setProducersState] = useState(null)
  const [castersState, setCastersState] = useState(null)

  const getUserNameFromId = async (userId) => {
    const user = await getUserById(Products.PLAYBRAIN, userId).catch(() => {
      return { username: "" }
    })
    return user.username || ""
  }

  useDebounce(
    () => {
      if (urlSlugRef === event.urlSlug) {
        return
      }

      setEvent((oldEvent) => {
        const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
        newEvent.urlSlug = urlSlugRef
        return newEvent
      })
    },
    300,
    [urlSlugRef, event]
  )

  useEffect(() => {
    if (loadedModel) {
      loadedModel.owner && getUserNameFromId(loadedModel.owner).then((name) => setOwnerState(name))
      setLoadedModelState(loadedModel)
    }
  }, [loadedModel])

  useEffect(() => {
    setAvailableCurrenciesState(availableCurrencies)
  }, [availableCurrencies])

  useEffect(() => {
    if (loadedModelState && availableCasters.length > 0) {
      setCastersState(
        loadedModelState.casters.map((caster) => availableCasters.find((ac) => ac.id === caster.id))
      )
      setAvailableCastersState(availableCasters)
    }
  }, [availableCasters, loadedModelState])

  useEffect(() => {
    if (loadedModelState && availableOrganizers.length > 0) {
      setOrganizersState(
        loadedModelState.organizers.map(
          (organizer) => availableOrganizers.filter((at) => at.id === organizer)[0]
        )
      )
      setAvailableOrganizersState(availableOrganizers)
    }
  }, [availableOrganizers, loadedModelState])

  useEffect(() => {
    if (loadedModelState && availableProducers.length > 0) {
      setProducersState(
        loadedModelState.producers.map((producer) => availableProducers.find((ap) => ap.id === producer.id))
      )
      setAvailableProducersState(availableProducers)
    }
  }, [availableProducers, loadedModelState])

  return (
    <>
      {loadedModelState && (
        <Grid
          container
          rowGap="12px"
          spacing="12px"
        >
          <Grid
            item
            xs={7}
          >
            <RealTimeUpdateField
              type="text"
              label={t("edit.name")}
              updateFunc={(value) => editEvent(loadedModelState.id, "name", value)}
              initialValue={loadedModelState.name}
            />
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">
              Created by {ownerState} at {moment(loadedModelState.createdAt).format("YYYY/MM/DD HH:mm")}
            </HelperText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            <RealTimeUpdateField
              type="text"
              label={t("meta.headers.slug")}
              updateFunc={(value) => {
                editEvent(loadedModelState.id, "urlSlug", value)
                setUrlSlugRef(value)
              }}
              initialValue={loadedModelState.urlSlug}
              validationRule={(value) => {
                return new Promise((resolve, reject) => {
                  resolve(value ? value.replaceAll(" ", "").slice(0, 64) : null)
                })
              }}
            />
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">{t("edit.help.urlSlug")}</HelperText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            <RealTimeUpdateField
              type="text"
              label={t("edit.eventUrl")}
              updateFunc={(value) => editEvent(loadedModelState.id, "eventUrl", value)}
              initialValue={loadedModelState.eventUrl}
            />
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">{t("edit.help.eventUrl")}</HelperText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            <RealTimeUpdateField
              type="select"
              options={availableTierOptionsState}
              label={t("edit.tier")}
              initialValue={availableTierOptionsState.find((tier) => tier.id === loadedModelState.tier)}
              updateFunc={(value) => editEvent(loadedModelState.id, "tier", value ? value.id : null)}
            />
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">{t("edit.help.tier")}</HelperText>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <RealTimeUpdateField
              type="text"
              label={t("edit.twitterHandle")}
              updateFunc={(value) => editEvent(loadedModelState.id, "twitterHandle", value)}
              initialValue={loadedModelState.twitterHandle}
              validationRule={(value) => {
                return new Promise((resolve, reject) => {
                  resolve(value ? value.replaceAll(TWITTER_URL, "") : null)
                })
              }}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <RealTimeUpdateField
              type="text"
              label={t("edit.hashtag")}
              updateFunc={(value) => editEvent(loadedModelState.id, "hashtag", value)}
              initialValue={loadedModelState.hashtag}
              validationRule={(value) => {
                return new Promise((resolve, reject) => {
                  resolve(value ? value.replaceAll("#", "") : null)
                })
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
          ></Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">{t("edit.help.twitterHandle")}</HelperText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            <EventImage
              label={t("edit.imageUrl")}
              eventId={loadedModelState.id}
              field="imageUrl"
              initialValue={loadedModelState.imageUrl}
            />
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">{t("edit.help.imageUrl")}</HelperText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            <CountrySelect
              value={loadedModel.origin}
              eventId={loadedModelState.id}
              label={t("edit.origin.label")}
            />
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">{t("edit.help.origin")}</HelperText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={3}
                style={{ minWidth: "100px" }}
              >
                <RealTimeUpdateField
                  type="select"
                  label={t("edit.currency")}
                  disableClearable
                  updateFunc={(value) => editEvent(loadedModelState.id, "currency", value)}
                  initialValue={loadedModelState.currency}
                  options={availableCurrenciesState}
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <RealTimeUpdateField
                  type="number-format"
                  label={t("edit.budget")}
                  updateFunc={(value) => editEvent(loadedModelState.id, "budget", value)}
                  initialValue={loadedModelState.budget === null ? "" : loadedModelState.budget.split(".")[0]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">{t("edit.help.budget")}</HelperText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            <RealTimeUpdateTagEventTags />
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">{t("edit.help.tags")}</HelperText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            {organizersState && availableOrganizersState && (
              <RealTimeUpdateField
                type="searchable"
                label={t("edit.organizers")}
                initialValue={organizersState}
                options={availableOrganizersState}
                addFunc={(organizer) => addEventOrganizer(loadedModelState.id, organizer.id)}
                deleteFunc={(organizer) => deleteEventOrganizer(loadedModelState.id, organizer.id)}
                createFunc={(value) => {
                  const f = async (value) => {
                    const result = await createOrganizer(value)
                    setAvailableOrganizersState([result, ...availableOrganizersState])
                    openMetaEditNewTab("organizers")
                    return result
                  }
                  return f(value)
                }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">{t("edit.help.organizers")}</HelperText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            {producersState && availableProducersState && (
              <RealTimeUpdateField
                type="searchable"
                label={t("meta.pageTitles.producers")}
                initialValue={producersState}
                options={availableProducersState}
                addFunc={(producer) => addEventProducer(loadedModelState.id, producer.id)}
                deleteFunc={(producer) => deleteEventProducer(loadedModelState.id, producer.id)}
                createFunc={(value) => {
                  const f = async (value) => {
                    // If in English mode, create an english titled producer, if in Japanese mode create with Japanese title.
                    const prefferedLanguage = localStorage.getItem("language") || "en"

                    let result = null
                    // TODO (Roy) Is there a way to specify which optional paramter you are sending?
                    if (prefferedLanguage === "en") {
                      result = await createProducer(value, null, null, null, null)
                    } else {
                      result = await createProducer(null, value, null, null, null)
                    }
                    setAvailableProducersState([result, ...availableProducersState])
                    openMetaEditNewTab("producers")
                    return result
                  }
                  return f(value)
                }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">{t("edit.help.producers")}</HelperText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            {castersState && availableCastersState && (
              <RealTimeUpdateField
                type="searchable"
                label={t("meta.pageTitles.casters")}
                initialValue={castersState}
                options={availableCastersState}
                addFunc={(caster) => addEventCaster(loadedModelState.id, caster.id)}
                deleteFunc={(caster) => deleteEventCaster(loadedModelState.id, caster.id)}
                createFunc={(value) => {
                  const f = async (value) => {
                    // If in English mode, create an english titled producer, if in Japanese mode create with Japanese title.
                    const prefferedLanguage = localStorage.getItem("language") || "en"

                    let result = null
                    // TODO (Roy) Is there a way to specify which optional paramter you are sending?
                    if (prefferedLanguage === "en") {
                      result = await createCaster(value, null, null, null, null)
                    } else {
                      result = await createCaster(null, value, null, null, null)
                    }
                    setAvailableCastersState([result, ...availableCastersState])
                    openMetaEditNewTab("casters")
                    return result
                  }
                  return f(value)
                }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={5}
          ></Grid>
          <Grid
            item
            xs={7}
          >
            <RealTimeUpdateField
              type="text"
              multiline
              label={t("edit.description")}
              updateFunc={(value) => editEvent(loadedModelState.id, "description", value)}
              initialValue={loadedModel.description}
            />
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText component="span">{t("edit.help.description")}</HelperText>
          </Grid>
          <Grid
            item
            xs={7}
          >
            <RealTimeUpdateField
              type="text"
              label={t("edit.notes")}
              updateFunc={(value) => editEvent(loadedModelState.id, "notes", value)}
              initialValue={loadedModel.notes}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Information

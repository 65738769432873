import React from "react"

import { TagHierarchy } from "models/Taiyoro/Meta/Tag"
import { EventListSearchParams } from "models/Taiyoro/event"
import moment from "moment-timezone"

type TaiyoroDataContextType = {
  tagHierarchy: TagHierarchy | null
  loadingTags: boolean
  refreshTags: () => void
  eventListSearchParams: EventListSearchParams
  setEventListSearchParams: React.Dispatch<React.SetStateAction<EventListSearchParams>>
}

export const TaiyoroDataContext = React.createContext<TaiyoroDataContextType>({
  tagHierarchy: null,
  loadingTags: false,
  refreshTags: () => {},
  eventListSearchParams: {
    mode: "calendar",
    month: moment(),
  },
  setEventListSearchParams: () => {},
})

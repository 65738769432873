import { useState, type Dispatch, type SetStateAction } from "react"
import React from "react"

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import MediaUpload from "components/Taiyoro/MediaUpload"
import type { ImageState } from "components/Taiyoro/Posts/PostImages"
import { useTranslation } from "react-i18next"
import { signMediaS3 } from "services/Taiyoro/media"

interface Props {
  title: string
  onSubmit: () => void
  onClose: () => void
  show: boolean
  imageState: ImageState
  setImageState: Dispatch<SetStateAction<ImageState>>
}

export const PostImageModal = ({ title, onClose, onSubmit, show, imageState, setImageState }: Props) => {
  const { t } = useTranslation("taiyoro")
  const [isOgImage, setIsOgImage] = useState(false)

  return (
    <Dialog
      open={!!show}
      onClose={onClose}
      maxWidth="lg"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ mb: 2 }}
          component="label"
          htmlFor="ogImageAspectRatio"
        >
          <Switch
            checked={isOgImage}
            id="ogImageAspectRatio"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsOgImage(e.target.checked)}
          />
          <Typography variant="body2">{t("posts.detailsPage.forceRatio")}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          sx={{ mt: 1 }}
        >
          <MediaUpload
            key="post-image"
            url={imageState.url}
            mediaType="post"
            onChange={(url: string) => setImageState((prev) => ({ ...prev, url }))}
            editable
            buttonText={t("posts.detailsPage.uploadImage")}
            aspect={isOgImage ? 40 / 21 : undefined}
          />
          <Box>
            <TextField
              label={t("posts.detailsPage.tableHeaders.altTextJa")}
              value={imageState.altTextJa}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setImageState((prev) => ({ ...prev, altTextJa: e.target.value }))
              }
            />
          </Box>
          <Box>
            <TextField
              label={t("posts.detailsPage.tableHeaders.altText")}
              value={imageState.altText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setImageState((prev) => ({ ...prev, altText: e.target.value }))
              }
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="text"
          color="info"
        >
          {t("posts.detailsPage.cancel")}
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          {t("posts.detailsPage.saveImage")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import React from "react"

import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Tooltip } from "@mui/material"
import EventGamePlacements from "components/Taiyoro/CreateEvent/EventGames/EventGameEntry/EventGamePlacements"
import Placement from "models/Taiyoro/Meta/Placement"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { editEventGame } from "services/Taiyoro/eventGame"

import SignificantPlayer from "../../../../../models/Taiyoro/Meta/SignificantPlayer"
import Team from "../../../../../models/Taiyoro/Meta/Team"
import { PublishedState } from "../../../../../models/Taiyoro/event"
import { EventGame } from "../../../../../models/Taiyoro/eventGame"
import { UserRole } from "../../../../../models/UserManagement"
import { ADMIN_ROLES, HIGH_ACCESS_ROLES } from "../../../../../utils/roles"
import useRolesCanAccess from "../../../../../utils/useRolesCanAccess"
import TableList from "../../../../Table/TableList"
import RealTimeUpdateField from "../../../RealTimeUpdateField"
import { Title, Root, HelperText } from "./styles"

interface Props {
  eventPublishedState: PublishedState
  model: EventGame
  eventId: string
  availableTeams: Team[]
  gameName: string
  availablePlayers: SignificantPlayer[]
  onDelete: (id: string) => void
  availableCurrencies: string[]
  availablePlacements: Placement[]
}

const EventGameEntry = ({
  eventPublishedState,
  model,
  eventId,
  gameName,
  availableTeams,
  availablePlayers,
  onDelete,
  availableCurrencies,
  availablePlacements,
}: Props) => {
  const { t } = useTranslation(["taiyoro", "common"])

  const isHighAccessUser = useRolesCanAccess(HIGH_ACCESS_ROLES)

  const isEditableToEditor =
    useRolesCanAccess([UserRole.EDITOR]) && eventPublishedState === PublishedState.Draft

  const canDelete = isHighAccessUser || isEditableToEditor

  if (!model) {
    return <TableList.BodyRow></TableList.BodyRow>
  }

  return (
    <Root>
      <Box sx={{ marginBottom: "12px" }}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container>
              <Grid
                item
                xs={10}
              >
                <Title>{gameName}</Title>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ textAlign: "right" }}
              >
                {canDelete && (
                  <Tooltip title={t("common:actions.delete")}>
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation()
                        onDelete(model.gameId)
                      }}
                      sx={{ width: "32px", height: "32px" }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={2}
                >
                  <RealTimeUpdateField
                    type="select"
                    options={availableCurrencies}
                    disableClearable
                    label={t("edit.games.currency")}
                    updateFunc={(value) => editEventGame(eventId, model.gameId, { currency: value })}
                    initialValue={model.currency}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                >
                  <RealTimeUpdateField
                    type="number-format"
                    label={t("edit.games.prizePool")}
                    updateFunc={(value) => editEventGame(eventId, model.gameId, { prizePool: value })}
                    initialValue={model.prizePool ? model.prizePool.split(".")[0] : ""}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                >
                  <HelperText component="span">{t("edit.games.help.prizePool")}</HelperText>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={7}
                >
                  <RealTimeUpdateField
                    type="text"
                    label={t("edit.games.resultsUrl")}
                    updateFunc={(value) => editEventGame(eventId, model.gameId, { resultsUrl: value })}
                    initialValue={model.resultsUrl}
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                >
                  <HelperText component="span">{t("edit.games.help.resultsUrl")}</HelperText>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <EventGamePlacements
                  eventGame={model}
                  type="player"
                  options={availablePlayers}
                  canDelete={canDelete}
                  availablePlacements={availablePlacements}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <EventGamePlacements
                  eventGame={model}
                  type="team"
                  options={availableTeams}
                  canDelete={canDelete}
                  availablePlacements={availablePlacements}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                spacing={3}
              >
                <Grid
                  item
                  xs={7}
                >
                  <RealTimeUpdateField
                    type="number-format"
                    label={t("edit.games.participantCount")}
                    updateFunc={(value) => {
                      const parsedValue = parseInt(value)
                      editEventGame(eventId, model.gameId, { participantCount: parsedValue })
                    }}
                    initialValue={model.participantCount}
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                >
                  <HelperText component="span">{t("edit.games.help.participantCount")}</HelperText>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Root>
  )
}

EventGameEntry.propTypes = {
  model: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  availableTeams: PropTypes.array,
  availablePlayers: PropTypes.array,
  availableCurrencies: PropTypes.array,
}

export default EventGameEntry

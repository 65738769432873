import React, { ReactNode } from "react"

import { Button } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

interface Props {
  open: boolean
  title?: string
  body?: string
  onConfirm?: () => void
  onCancel?: () => void
  children?: ReactNode
  confirmText?: string
  cancelText?: string
}

const ConfirmationDialog = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent>
        {props.body && <DialogContentText>{props.body}</DialogContentText>}
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>{props.cancelText}</Button>
        <Button
          onClick={(e) => {
            e.stopPropagation()
            props.onConfirm()
          }}
        >
          {props.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog

import { MetaType } from "../../models/Taiyoro/Meta/MetaType"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const checkUsage = async (metaId: string, metaType: MetaType) => {
  let response = await GraphQL(
    `query {
          admin {
            metaReplacement {
              usage ( 
                metaType: ${metaType}
                metaId: "${metaId}"
              )
              {
                events { id name }
                dates { id name eventId eventName }
                datePlatforms { id url eventId eventName dateId dateName}
                eventGames { gameId gameName eventId eventName}
                games { id name }
                gameGroups { id name }
                teams { id name }
                venues { id name }
                organizers { id name }
                sponsors { id name }
                significantPlayers { id name }
                producers { id name }
              }
            }
          }
        }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.metaReplacement &&
      response.data.admin.metaReplacement.usage) ||
    null
  )
}

export const replaceUsage = async (metaType: MetaType, oldMetaId: string, newMetaId: string) => {
  let response = await GraphQL(
    `mutation {
        admin {
          metaReplacement {
            replaceAll ( 
              metaType: ${metaType}
              oldMetaId: "${oldMetaId}"
              newMetaId: "${newMetaId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.metaReplacement &&
      response.data.admin.metaReplacement.replaceAll) ||
    null
  )
}

import { taiyoroRatingPercentileToScore } from "utils/taiyoro"

import { LocalMomentFromUTCDateTime } from "../../../models/Taiyoro/eventDate"
import { AnalyticEntity, AnalyticsResponse, ReportEntity } from "../../../services/Taiyoro/Analytics"

export const displayNumberGreaterThanZero = (field) => {
  try {
    return field && Number(field) > 0 && Number(field).toLocaleString()
  } catch {
    return ""
  }
}

export enum SortField {
  EVENT_NAME = "EVENT_NAME",
  EVENT_START = "EVENT_START",
  EVENT_END = "EVENT_END",
  PEAK_CONCURRENTS = "PEAK_CONCURRENTS",
  AVERAGE_CONCURRENTS = "AVERAGE_CONCURRENTS",
  MINUTES_WATCHED = "MINUTES_WATCHED",
  MINUTES_WATCHED_PERCENTAGE = "MINUTES_WATCHED_PERCENTAGE",
  //MINUTES_WATCHED_PERCENTAGE doesn't exist on the backend. It is emulated on the front-end to create a unique column
  //and during query time is swapped out for MINUTES_WATCHED which is the valid sort field.
  AVERAGE_DAILY_MINUTES_WATCHED = "AVERAGE_DAILY_MINUTES_WATCHED",
  NUM_DAYS = "NUM_DAYS",
  ERRORS = "ERRORS",
  DATE_NAME = "DATE_NAME",
  GAME_NAME = "GAME_NAME",
  GAME_NAME_JA = "GAME_NAME_JA",
  DATE_START = "DATE_START",
  DATE_END = "DATE_END",
  SPONSOR_NAME = "SPONSOR_NAME",
  SPONSOR_NAME_JA = "SPONSOR_NAME_JA",
  NUM_GAMES = "NUM_GAMES",
  NUM_EVENTS = "NUM_EVENTS",
  LIVE_BROADCAST_DURATION_MINUTES = "LIVE_BROADCAST_DURATION_MINUTES",
  NUM_SPONSORS = "NUM_SPONSORS",
  SATURATION_SCORE = "SATURATION_SCORE",
  PLATFORM_NAME = "PLATFORM_NAME",
  PLATFORM_NAME_JA = "PLATFORM_NAME_JA",
  NUM_STREAMS = "NUM_STREAMS",
  ORGANIZER_NAME = "ORGANIZER_NAME",
  ORGANIZER_NAME_JA = "ORGANIZER_NAME_JA",
  CASTER_NAME = "CASTER_NAME",
  CASTER_NAME_JA = "CASTER_NAME_JA",
  TAIYORO_RATING = "TAIYORO_RATING",
  TAIYORO_RATING_WEEK = "TAIYORO_RATING_WEEK",
}

export interface SortState {
  order: string
  field: SortField
}

export enum AnalyticField {
  viewsLiveMinutesWatched,
  viewsLiveMinutesWatchedPercentageOfTotal,
  viewsLiveConcurrentAverage,
  viewsLiveConcurrentPeak,
  averageDailyMinutesWatched,
  numDays,
  numDaysTracked,
  dataCollectionErrors,
  name,
}

export const mapResultsToData = (
  type: AnalyticEntity | ReportEntity,
  analyticsReponse: AnalyticsResponse
) => {
  switch (type) {
    case AnalyticEntity.EVENTS:
      return analyticsReponse.events.map((event) => {
        let object = {}
        object[SortField[SortField.EVENT_NAME]] = event.event.name
        object[SortField[SortField.GAME_NAME]] = event.games.map((game) => game.name).join(", ")
        object[SortField[SortField.GAME_NAME_JA]] = event.games.map((game) => game.nameJa).join(", ")
        object[SortField[SortField.EVENT_START]] = LocalMomentFromUTCDateTime(event.eventStartDatetime)
          .locale("en")
          .format("YYYY/MM/DD hh:mm A")
        object[SortField[SortField.EVENT_END]] = LocalMomentFromUTCDateTime(event.eventEndDatetime)
          .locale("en")
          .format("YYYY/MM/DD hh:mm A")
        object[SortField[SortField.PEAK_CONCURRENTS]] = displayNumberGreaterThanZero(
          event.viewsLiveConcurrentPeak
        )
        object[SortField[SortField.AVERAGE_CONCURRENTS]] = displayNumberGreaterThanZero(
          event.viewsLiveConcurrentAverage
        )
        object[SortField[SortField.MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          event.viewsLiveMinutesWatched
        )
        object[SortField[SortField.MINUTES_WATCHED_PERCENTAGE]] = displayNumberGreaterThanZero(
          event.viewsLiveMinutesWatchedPercentageOfTotal
        )
        object[SortField[SortField.AVERAGE_DAILY_MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          event.averageDailyMinutesWatched
        )
        object[SortField[SortField.TAIYORO_RATING]] = taiyoroRatingPercentileToScore(
          event.event.taiyoroRating
        )
        object[SortField[SortField.NUM_DAYS]] = event.numDays
        object[SortField[SortField.ERRORS]] = event.dataCollectionErrors
        return object
      })
    case AnalyticEntity.DATES:
      return analyticsReponse.dates.map((date) => {
        let object = {}
        object[SortField[SortField.DATE_NAME]] = date.date.name
        object[SortField[SortField.EVENT_NAME]] = date.event.name
        object[SortField[SortField.GAME_NAME]] = date.game.name
        object[SortField[SortField.GAME_NAME_JA]] = date.game.nameJa
        object[SortField[SortField.DATE_START]] = LocalMomentFromUTCDateTime(date.date.startDatetime)
          .locale("en")
          .format("YYYY/MM/DD hh:mm A")
        object[SortField[SortField.DATE_END]] = LocalMomentFromUTCDateTime(date.date.endDatetime)
          .locale("en")
          .format("YYYY/MM/DD hh:mm A")
        object[SortField[SortField.PEAK_CONCURRENTS]] = displayNumberGreaterThanZero(
          date.viewsLiveConcurrentPeak
        )
        object[SortField[SortField.AVERAGE_CONCURRENTS]] = displayNumberGreaterThanZero(
          date.viewsLiveConcurrentAverage
        )
        object[SortField[SortField.MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          date.viewsLiveMinutesWatched
        )
        object[SortField[SortField.MINUTES_WATCHED_PERCENTAGE]] = displayNumberGreaterThanZero(
          date.viewsLiveMinutesWatchedPercentageOfTotal
        )
        object[SortField[SortField.TAIYORO_RATING]] = taiyoroRatingPercentileToScore(date.date.taiyoroRating)
        object[SortField[SortField.TAIYORO_RATING_WEEK]] = taiyoroRatingPercentileToScore(
          date.taiyoroDateWeekRating
        )

        object[SortField[SortField.ERRORS]] = date.dataCollectionErrors
        return object
      })
    case AnalyticEntity.SPONSORS:
      return analyticsReponse.sponsors.map((sponsor) => {
        let object = {}
        object[SortField[SortField.SPONSOR_NAME]] = sponsor.sponsor.name
        object[SortField[SortField.SPONSOR_NAME_JA]] = sponsor.sponsor.nameJa
        object[SortField[SortField.PEAK_CONCURRENTS]] = displayNumberGreaterThanZero(
          sponsor.viewsLiveConcurrentPeak
        )
        object[SortField[SortField.AVERAGE_CONCURRENTS]] = displayNumberGreaterThanZero(
          sponsor.viewsLiveConcurrentAverage
        )
        object[SortField[SortField.MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          sponsor.viewsLiveMinutesWatched
        )
        object[SortField[SortField.MINUTES_WATCHED_PERCENTAGE]] = displayNumberGreaterThanZero(
          sponsor.viewsLiveMinutesWatchedPercentageOfTotal
        )
        object[SortField[SortField.NUM_GAMES]] = sponsor.numGames
        object[SortField[SortField.NUM_EVENTS]] = sponsor.numEvents
        object[SortField[SortField.NUM_DAYS]] = sponsor.numDays
        return object
      })
    case AnalyticEntity.GAMES:
      return analyticsReponse.games.map((game) => {
        let object = {}
        object[SortField[SortField.GAME_NAME]] = game.game.name
        object[SortField[SortField.GAME_NAME_JA]] = game.game.nameJa
        object[SortField[SortField.PEAK_CONCURRENTS]] = displayNumberGreaterThanZero(
          game.viewsLiveConcurrentPeak
        )
        object[SortField[SortField.AVERAGE_CONCURRENTS]] = displayNumberGreaterThanZero(
          game.viewsLiveConcurrentAverage
        )
        object[SortField[SortField.MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          game.viewsLiveMinutesWatched
        )
        object[SortField[SortField.MINUTES_WATCHED_PERCENTAGE]] = displayNumberGreaterThanZero(
          game.viewsLiveMinutesWatchedPercentageOfTotal
        )
        object[SortField[SortField.AVERAGE_DAILY_MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          game.averageDailyMinutesWatched
        )
        object[SortField[SortField.LIVE_BROADCAST_DURATION_MINUTES]] = displayNumberGreaterThanZero(
          game.liveBroadcastDurationMinutes
        )
        object[SortField[SortField.NUM_EVENTS]] = game.numEvents
        object[SortField[SortField.NUM_DAYS]] = game.numDays
        object[SortField[SortField.NUM_SPONSORS]] = game.numSponsors
        object[SortField[SortField.SATURATION_SCORE]] = game.saturationScore
        return object
      })
    case AnalyticEntity.PLATFORMS:
      return analyticsReponse.platforms.map((platform) => {
        let object = {}
        object[SortField[SortField.PLATFORM_NAME]] = platform.platform.name
        object[SortField[SortField.PLATFORM_NAME_JA]] = platform.platform.nameJa
        object[SortField[SortField.PEAK_CONCURRENTS]] = displayNumberGreaterThanZero(
          platform.viewsLiveConcurrentPeak
        )
        object[SortField[SortField.AVERAGE_CONCURRENTS]] = displayNumberGreaterThanZero(
          platform.viewsLiveConcurrentAverage
        )
        object[SortField[SortField.MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          platform.viewsLiveMinutesWatched
        )
        object[SortField[SortField.MINUTES_WATCHED_PERCENTAGE]] = displayNumberGreaterThanZero(
          platform.viewsLiveMinutesWatchedPercentageOfTotal
        )
        object[SortField[SortField.AVERAGE_DAILY_MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          platform.averageDailyMinutesWatched
        )
        object[SortField[SortField.LIVE_BROADCAST_DURATION_MINUTES]] = displayNumberGreaterThanZero(
          platform.liveBroadcastDurationMinutes
        )
        object[SortField[SortField.NUM_EVENTS]] = platform.numEvents
        object[SortField[SortField.NUM_DAYS]] = platform.numDays
        object[SortField[SortField.NUM_STREAMS]] = platform.numStreams
        return object
      })
    case AnalyticEntity.ORGANIZERS:
      return analyticsReponse.organizers.map((organizer) => {
        let object = {}
        object[SortField[SortField.ORGANIZER_NAME]] = organizer.organizer.name
        object[SortField[SortField.ORGANIZER_NAME_JA]] = organizer.organizer.nameJa
        object[SortField[SortField.PEAK_CONCURRENTS]] = displayNumberGreaterThanZero(
          organizer.viewsLiveConcurrentPeak
        )
        object[SortField[SortField.AVERAGE_CONCURRENTS]] = displayNumberGreaterThanZero(
          organizer.viewsLiveConcurrentAverage
        )
        object[SortField[SortField.MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          organizer.viewsLiveMinutesWatched
        )
        object[SortField[SortField.MINUTES_WATCHED_PERCENTAGE]] = displayNumberGreaterThanZero(
          organizer.viewsLiveMinutesWatchedPercentageOfTotal
        )
        object[SortField[SortField.AVERAGE_DAILY_MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          organizer.averageDailyMinutesWatched
        )
        object[SortField[SortField.LIVE_BROADCAST_DURATION_MINUTES]] = displayNumberGreaterThanZero(
          organizer.liveBroadcastDurationMinutes
        )
        object[SortField[SortField.NUM_EVENTS]] = organizer.numEvents
        object[SortField[SortField.NUM_DAYS]] = organizer.numDays
        return object
      })
    case AnalyticEntity.CASTERS:
      return analyticsReponse.casters.map((caster) => {
        let object = {}
        object[SortField[SortField.CASTER_NAME]] = caster.caster.name
        object[SortField[SortField.CASTER_NAME_JA]] = caster.caster.nameJa
        object[SortField[SortField.PEAK_CONCURRENTS]] = displayNumberGreaterThanZero(
          caster.viewsLiveConcurrentPeak
        )
        object[SortField[SortField.AVERAGE_CONCURRENTS]] = displayNumberGreaterThanZero(
          caster.viewsLiveConcurrentAverage
        )
        object[SortField[SortField.MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          caster.viewsLiveMinutesWatched
        )
        object[SortField[SortField.MINUTES_WATCHED_PERCENTAGE]] = displayNumberGreaterThanZero(
          caster.viewsLiveMinutesWatchedPercentageOfTotal
        )
        object[SortField[SortField.AVERAGE_DAILY_MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          caster.averageDailyMinutesWatched
        )
        object[SortField[SortField.LIVE_BROADCAST_DURATION_MINUTES]] = displayNumberGreaterThanZero(
          caster.liveBroadcastDurationMinutes
        )
        object[SortField[SortField.NUM_EVENTS]] = caster.numEvents
        object[SortField[SortField.NUM_DAYS]] = caster.numDays
        object[SortField[SortField.NUM_GAMES]] = caster.numGames
        return object
      })
    case ReportEntity.TOURNAMENT_VIEWERSHIP_RANK:
      return analyticsReponse.events.map((event) => {
        let object = {}
        object[SortField[SortField.EVENT_NAME]] = event.event.name
        object[SortField[SortField.MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          event.viewsLiveMinutesWatched
        )
        object[SortField[SortField.NUM_DAYS]] = event.numDays
        object[SortField[SortField.AVERAGE_CONCURRENTS]] = displayNumberGreaterThanZero(
          event.viewsLiveConcurrentAverage
        )
        return object
      })
    case ReportEntity.GAME_TITLE_VIEWERSHIP_RANK:
      return analyticsReponse.games.map((game) => {
        let object = {}
        object[SortField[SortField.GAME_NAME]] = game.game.name
        object[SortField[SortField.GAME_NAME_JA]] = game.game.nameJa
        object[SortField[SortField.MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          game.viewsLiveMinutesWatched
        )
        object[SortField[SortField.NUM_DAYS]] = game.numDays
        return object
      })
    case ReportEntity.TOURNAMENT_PCV_RANK:
      return analyticsReponse.dates.map((date) => {
        let object = {}
        object[SortField[SortField.EVENT_NAME]] = date.event.name
        object[SortField[SortField.DATE_NAME]] = date.date.name
        object[SortField[SortField.DATE_START]] = LocalMomentFromUTCDateTime(date.date.startDatetime)
          .locale("en")
          .format("YYYY/MM/DD hh:mm A")
        object[SortField[SortField.PEAK_CONCURRENTS]] = displayNumberGreaterThanZero(
          date.viewsLiveConcurrentPeak
        )
        return object
      })
    case ReportEntity.GAME_TITLE_PCV_RANK:
      return analyticsReponse.games.map((game) => {
        let object = {}
        object[SortField[SortField.GAME_NAME]] = game.game.name
        object[SortField[SortField.GAME_NAME_JA]] = game.game.nameJa
        object[SortField[SortField.PEAK_CONCURRENTS]] = displayNumberGreaterThanZero(
          game.viewsLiveConcurrentPeak
        )
        object[SortField[SortField.EVENT_NAME]] = game.peakEventName
        object[SortField[SortField.DATE_NAME]] = game.peakDateName
        return object
      })
    case ReportEntity.SPONSOR_VIEWERSHIP_RANK:
      return analyticsReponse.sponsors.map((sponsor) => {
        let object = {}
        object[SortField[SortField.SPONSOR_NAME]] = sponsor.sponsor.name
        object[SortField[SortField.SPONSOR_NAME_JA]] = sponsor.sponsor.nameJa
        object[SortField[SortField.NUM_DAYS]] = sponsor.numDays
        object[SortField[SortField.MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          sponsor.viewsLiveMinutesWatched
        )
        return object
      })
    case ReportEntity.GAME_TITLE_SCORE_RANK:
      return analyticsReponse.games.map((game) => {
        let object = {}
        object[SortField[SortField.GAME_NAME]] = game.game.name
        object[SortField[SortField.GAME_NAME_JA]] = game.game.nameJa
        object[SortField[SortField.MINUTES_WATCHED]] = displayNumberGreaterThanZero(
          game.viewsLiveMinutesWatched
        )
        object[SortField[SortField.SATURATION_SCORE]] = game.saturationScore
        return object
      })
  }
}

export const getColumnsFromType = (type: AnalyticEntity | ReportEntity) => {
  switch (type) {
    case AnalyticEntity.EVENTS:
      return [
        SortField.EVENT_NAME,
        SortField.GAME_NAME,
        SortField.GAME_NAME_JA,
        SortField.EVENT_START,
        SortField.EVENT_END,
        SortField.PEAK_CONCURRENTS,
        SortField.AVERAGE_CONCURRENTS,
        SortField.MINUTES_WATCHED,
        SortField.MINUTES_WATCHED_PERCENTAGE,
        SortField.AVERAGE_DAILY_MINUTES_WATCHED,
        SortField.TAIYORO_RATING,
        SortField.NUM_DAYS,
        SortField.ERRORS,
      ]
    case AnalyticEntity.DATES:
      return [
        SortField.DATE_NAME,
        SortField.EVENT_NAME,
        SortField.GAME_NAME,
        SortField.GAME_NAME_JA,
        SortField.DATE_START,
        SortField.DATE_END,
        SortField.PEAK_CONCURRENTS,
        SortField.AVERAGE_CONCURRENTS,
        SortField.MINUTES_WATCHED,
        SortField.MINUTES_WATCHED_PERCENTAGE,
        SortField.TAIYORO_RATING,
        SortField.TAIYORO_RATING_WEEK,
        SortField.ERRORS,
      ]
    case AnalyticEntity.SPONSORS:
      return [
        SortField.SPONSOR_NAME,
        SortField.SPONSOR_NAME_JA,
        SortField.PEAK_CONCURRENTS,
        SortField.AVERAGE_CONCURRENTS,
        SortField.MINUTES_WATCHED,
        SortField.MINUTES_WATCHED_PERCENTAGE,
        SortField.NUM_GAMES,
        SortField.NUM_EVENTS,
        SortField.NUM_DAYS,
      ]
    case AnalyticEntity.GAMES:
      return [
        SortField.GAME_NAME,
        SortField.GAME_NAME_JA,
        SortField.PEAK_CONCURRENTS,
        SortField.AVERAGE_CONCURRENTS,
        SortField.MINUTES_WATCHED,
        SortField.MINUTES_WATCHED_PERCENTAGE,
        SortField.AVERAGE_DAILY_MINUTES_WATCHED,
        SortField.LIVE_BROADCAST_DURATION_MINUTES,
        SortField.NUM_EVENTS,
        SortField.NUM_DAYS,
        SortField.NUM_SPONSORS,
        SortField.SATURATION_SCORE,
      ]
    case AnalyticEntity.PLATFORMS:
      return [
        SortField.PLATFORM_NAME,
        SortField.PLATFORM_NAME_JA,
        SortField.PEAK_CONCURRENTS,
        SortField.AVERAGE_CONCURRENTS,
        SortField.MINUTES_WATCHED,
        SortField.MINUTES_WATCHED_PERCENTAGE,
        SortField.AVERAGE_DAILY_MINUTES_WATCHED,
        SortField.LIVE_BROADCAST_DURATION_MINUTES,
        SortField.NUM_EVENTS,
        SortField.NUM_DAYS,
        SortField.NUM_STREAMS,
      ]
    case AnalyticEntity.ORGANIZERS:
      return [
        SortField.ORGANIZER_NAME,
        SortField.ORGANIZER_NAME_JA,
        SortField.PEAK_CONCURRENTS,
        SortField.AVERAGE_CONCURRENTS,
        SortField.MINUTES_WATCHED,
        SortField.MINUTES_WATCHED_PERCENTAGE,
        SortField.AVERAGE_DAILY_MINUTES_WATCHED,
        SortField.LIVE_BROADCAST_DURATION_MINUTES,
        SortField.NUM_EVENTS,
        SortField.NUM_DAYS,
      ]
    case AnalyticEntity.CASTERS:
      return [
        SortField.CASTER_NAME,
        SortField.CASTER_NAME_JA,
        SortField.PEAK_CONCURRENTS,
        SortField.AVERAGE_CONCURRENTS,
        SortField.MINUTES_WATCHED,
        SortField.MINUTES_WATCHED_PERCENTAGE,
        SortField.AVERAGE_DAILY_MINUTES_WATCHED,
        SortField.LIVE_BROADCAST_DURATION_MINUTES,
        SortField.NUM_EVENTS,
        SortField.NUM_DAYS,
        SortField.NUM_GAMES,
      ]
    case ReportEntity.TOURNAMENT_VIEWERSHIP_RANK:
      return [
        SortField.EVENT_NAME,
        SortField.MINUTES_WATCHED,
        SortField.NUM_DAYS,
        SortField.AVERAGE_CONCURRENTS,
      ]
    case ReportEntity.GAME_TITLE_VIEWERSHIP_RANK:
      return [SortField.GAME_NAME, SortField.GAME_NAME_JA, SortField.MINUTES_WATCHED, SortField.NUM_DAYS]
    case ReportEntity.TOURNAMENT_PCV_RANK:
      return [SortField.EVENT_NAME, SortField.DATE_NAME, SortField.DATE_START, SortField.PEAK_CONCURRENTS]
    case ReportEntity.GAME_TITLE_PCV_RANK:
      return [
        SortField.GAME_NAME,
        SortField.GAME_NAME_JA,
        SortField.PEAK_CONCURRENTS,
        SortField.EVENT_NAME,
        SortField.DATE_NAME,
      ]
    case ReportEntity.SPONSOR_VIEWERSHIP_RANK:
      return [
        SortField.SPONSOR_NAME,
        SortField.SPONSOR_NAME_JA,
        SortField.NUM_DAYS,
        SortField.MINUTES_WATCHED,
      ]
    case ReportEntity.GAME_TITLE_SCORE_RANK:
      return [
        SortField.GAME_NAME,
        SortField.GAME_NAME_JA,
        SortField.MINUTES_WATCHED,
        SortField.SATURATION_SCORE,
      ]
  }
}

export const getDefaultSortForAnalyticType = (type: AnalyticEntity | ReportEntity) => {
  switch (type) {
    case AnalyticEntity.EVENTS:
    case AnalyticEntity.DATES:
    case AnalyticEntity.SPONSORS:
    case AnalyticEntity.GAMES:
    case AnalyticEntity.PLATFORMS:
    case AnalyticEntity.ORGANIZERS:
    case AnalyticEntity.CASTERS:
    case ReportEntity.GAME_TITLE_VIEWERSHIP_RANK:
    case ReportEntity.TOURNAMENT_VIEWERSHIP_RANK:
    case ReportEntity.SPONSOR_VIEWERSHIP_RANK:
      return SortField.MINUTES_WATCHED
    case ReportEntity.GAME_TITLE_PCV_RANK:
    case ReportEntity.TOURNAMENT_PCV_RANK:
      return SortField.PEAK_CONCURRENTS
    case ReportEntity.GAME_TITLE_SCORE_RANK:
      return SortField.SATURATION_SCORE
  }
}

export const getEntityFromReport = (type: AnalyticEntity | ReportEntity) => {
  switch (type as ReportEntity) {
    case ReportEntity.GAME_TITLE_PCV_RANK:
    case ReportEntity.GAME_TITLE_VIEWERSHIP_RANK:
    case ReportEntity.GAME_TITLE_SCORE_RANK:
      return AnalyticEntity.GAMES
    case ReportEntity.TOURNAMENT_VIEWERSHIP_RANK:
      return AnalyticEntity.EVENTS
    case ReportEntity.SPONSOR_VIEWERSHIP_RANK:
      return AnalyticEntity.SPONSORS
    case ReportEntity.TOURNAMENT_PCV_RANK:
      return AnalyticEntity.DATES
    default:
      return type as AnalyticEntity
  }
}

export const mapSortFieldToValidBackendSortField = (sortField: SortField) => {
  switch (sortField) {
    case SortField.MINUTES_WATCHED_PERCENTAGE:
      return SortField.MINUTES_WATCHED
    default:
      return sortField
  }
}

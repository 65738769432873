import GameGroup from "../../models/Taiyoro/Meta/GameGroup"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchGameGroups = async (): Promise<GameGroup[] | null> => {
  let response = await GraphQL(
    `query {
        admin {
          gameGroup {
            get {
              id
              name
              nameJa
              sortJa
              primaryImage
              secondaryImage
              childGames {
                  id
              }
              childGroups {
                  id
              }
              parentGroups {
                  id
              }
              isGenre
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.gameGroup &&
      response.data.admin.gameGroup.get &&
      response.data.admin.gameGroup.get) ||
    null
  )
}

export const createGameGroup = async (
  name: string,
  nameJa?: string,
  sortJa?: string,
  primaryImage?: string,
  secondaryImage?: string,
  isGenre?: number,
  id?: string
) => {
  let response = await GraphQL(
    `mutation {
      admin {
        gameGroup {
          save (gameGroup: {
            ${id ? `id: "${id}"` : ""}
            name: ${name ? `"${name}"` : null}
            nameJa: ${nameJa ? `"${nameJa}"` : null}
            sortJa: ${sortJa ? `"${sortJa}"` : null}
            primaryImage: ${primaryImage ? `"${primaryImage}"` : null}
            secondaryImage: ${secondaryImage ? `"${secondaryImage}"` : null}
            isGenre: ${isGenre ? 1 : 0}
          }) {
            id
            name
            nameJa
            sortJa
            primaryImage
            secondaryImage
            childGames {
                id
            }
            childGroups {
                id
            }
            parentGroups {
                id
            }
            isGenre
            updatedAt
          }
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.gameGroup &&
      response.data.admin.gameGroup.save) ||
    null
  )
}

export const link = async (type, refId, parentGroupId) => {
  let response = await GraphQL(
    `mutation {
        admin {
          gameGroup {
            link (
                type: ${type}
                refId: "${refId}"
                parentGroupId: "${parentGroupId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.gameGroup &&
      response.data.admin.gameGroup.link) ||
    null
  )
}

export const deleteLink = async (type, refId, parentGroupId) => {
  let response = await GraphQL(
    `mutation {
        admin {
          gameGroup {
            deleteLink (
                type: ${type}
                refId: "${refId}"
                parentGroupId: "${parentGroupId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.gameGroup &&
      response.data.admin.gameGroup.deleteLink) ||
    null
  )
}

export const deleteGameGroup = async (id) => {
  let response = await GraphQL(
    `mutation {
        admin {
          gameGroup {
            delete (
                gameGroupId: "${id}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.gameGroup &&
      response.data.admin.gameGroup.delete) ||
    null
  )
}

import React from "react"

import { useTranslation } from "react-i18next"

import MetasPage from "../../../components/Taiyoro/MetaPage"
import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import { fetchTeams, createTeam, deleteTeam } from "../../../services/Taiyoro/teams"

const MetaTeamPage = () => {
  const { t } = useTranslation("taiyoro")

  return (
    <MetasPage
      title={t("meta.pageTitles.teams")}
      fetchFunc={fetchTeams}
      deleteFunc={deleteTeam}
      addNewFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) =>
        createTeam(
          name,
          nameJa,
          sortJa,
          teamOrganizationId,
          primaryImage,
          secondaryImage,
          urlSlug,
          gameId,
          id
        )
      }
      updateFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) =>
        createTeam(
          name,
          nameJa,
          sortJa,
          teamOrganizationId,
          primaryImage,
          secondaryImage,
          urlSlug,
          gameId,
          id
        )
      }
      metaType={MetaType.TEAM}
      primaryImageFieldsAndRatio={16 / 9}
      secondaryImageFieldsAndRatio={1}
      teamOrganizationFields
      slugFields
      gameFields
      allowNonUniqueNames
    />
  )
}

export default MetaTeamPage

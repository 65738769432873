import Team from "../../models/Taiyoro/Meta/Team"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchTeams = async (): Promise<Team[] | null> => {
  let response = await GraphQL(
    `query {
        admin {
          team {
            get {
              id
              name
              nameJa
              sortJa
              teamOrganizationId
              primaryImage
              secondaryImage
              gameId
              urlSlug
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.team &&
      response.data.admin.team.get &&
      response.data.admin.team.get) ||
    null
  )
}

export const fetchTeam = async (id: string): Promise<Team | null> => {
  let response = await GraphQL(
    `query {
        admin {
          team {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              teamOrganizationId
              primaryImage
              secondaryImage
              urlSlug
              gameId
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.team &&
      response.data.admin.team.get &&
      response.data.admin.team.get[0]) ||
    null
  )
}

export const createTeam = async (
  name: string,
  nameJa?: string,
  sortJa?: string,
  teamOrganizationId?: string,
  primaryImage?: string,
  secondaryImage?: string,
  urlSlug?: string,
  gameId?: string,
  id?: string
) => {
  let response = await GraphQL(
    `mutation {
      admin {
        team {
          save (team: {
            ${id ? `id: "${id}"` : ""}
            name: ${name ? `"${name}"` : null}
            nameJa: ${nameJa ? `"${nameJa}"` : null}
            sortJa: ${sortJa ? `"${sortJa}"` : null}
            teamOrganizationId: ${teamOrganizationId ? `"${teamOrganizationId}"` : null}
            primaryImage: ${primaryImage ? `"${primaryImage}"` : null}
            secondaryImage: ${secondaryImage ? `"${secondaryImage}"` : null}
            urlSlug: ${urlSlug ? `"${urlSlug}"` : null}
            gameId: ${gameId ? `"${gameId}"` : null}
          }) {
            id
            name
            nameJa
            sortJa
            teamOrganizationId
            primaryImage
            secondaryImage
            urlSlug
            gameId
            updatedAt
          }
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.team &&
      response.data.admin.team.save &&
      response.data.admin.team.save) ||
    null
  )
}

export const deleteTeam = async (id) => {
  let response = await GraphQL(
    `mutation {
      admin {
        team {
          delete (
              teamId: "${id}"
          )
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data && response.data.admin && response.data.admin.team && response.data.admin.team.delete) ||
    null
  )
}

import React from "react"

import MetasPage from "components/Taiyoro/MetaPage"
import { MetaType } from "models/Taiyoro/Meta/MetaType"
import { useTranslation } from "react-i18next"
import { createPlacement, deletePlacement, fetchPlacements } from "services/Taiyoro/placements"

const PlacementMetaPage = () => {
  const { t } = useTranslation("taiyoro")

  return (
    <MetasPage
      title={t("meta.pageTitles.placement")}
      fetchFunc={fetchPlacements}
      deleteFunc={deletePlacement}
      addNewFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createPlacement(name, nameJa, sortJa, quantifiable, id)}
      updateFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createPlacement(name, nameJa, sortJa, quantifiable, id)}
      metaType={MetaType.PLACEMENT}
      quantifiableFields
      idFields
    />
  )
}

export default PlacementMetaPage

import { PlayDataEventReportData } from "models/PlayData"
import GraphQL from "utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchPlayDataEventReportData = async (eventId: string): Promise<PlayDataEventReportData> =>
  GraphQL(
    `query {
      admin {
        playData {
          getEventReport(eventId: "${eventId}") {
            id
            name
            description
            startDatetime
            endDatetime
            lastStartDatetime
            imageUrl
            charts {
              concurrents {
                dateName
                pcu
                acu
              }
              watchtime {
                dateName
                watchtime
              }
              watchtimeHeatmap {
                time
                watchtime
              }
              channelWatchtime {
                name
                url
                watchtime
              }
              channelDateWatchtime {
                data
                channels {
                  id
                  name
                  url
                  isSubStream
                }
              }
              competingEventsOverview {
                game {
                  id
                  name
                }
                events {
                  id
                  name
                  startDatetime
                  endDatetime
                  lastStartDatetime
                  imageUrl
                  description
                  summary {
                    pcu
                    acu
                    hoursWatched
                    dateCount
                    finishedDateCount
                  }
                }
              }
              competingEventsViewership {
                dateName
                startDatetime
                endDatetime
                data
                events {
                  id
                  name
                  imageUrl
                }
              }
              dateOverview {
                name
                startDatetime
                endDatetime
                summary {
                  pcu
                  acu
                  hoursWatched
                  hoursBroadcast
                }
                data
                channels {
                  id
                  name
                  url
                  pcu
                  acu
                  hoursWatched
                  isSubStream
                }
              }
            }
            summary {
              pcu {
                value
                percentile
              }
              acu {
                value
                percentile
              }
              hoursWatched {
                value
                percentile
              }
              dateCount {
                value
                percentile
              }
              finishedDateCount
            }
            organizers {
              id
              name
              nameJa
            }
            casters {
              id
              name
              nameJa
            }
            teams {
              id
              name
              nameJa
              secondaryImage
            }
            players {
              id
              name
              nameJa
              primaryImage
            }
            sponsors {
              id
              name
              nameJa
            }
            producers {
              id
              name
              nameJa
            }
          }
        }
      }
    }
      `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((res) => res.data.admin.playData.getEventReport)

export const downloadPlayDataReport = async (url: string, eventName: string) => {
  return fetch(
    `${
      process.env.REACT_APP_PUPPETEER_API || "http://localhost:3888/api/query"
    }?resource=playdata&eventName=${encodeURIComponent(eventName)}&url=${encodeURIComponent(url)}`
  )
    .then((res) => res.json())
    .then((res) => res.url)
}

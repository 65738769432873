import React, { useEffect, useState } from "react"

import {
  faExclamationTriangle,
  faPaste,
  faCheckCircle,
  faTimesCircle,
  faBan,
  faTrashAlt,
  faEdit,
  faChartLine,
  faFileChartPie,
} from "@fortawesome/pro-solid-svg-icons"
import { faExternalLinkAlt } from "@fortawesome/pro-solid-svg-icons/faExternalLinkAlt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Badge, Card, Grid, IconButton, Stack, Box, Link, Tooltip, Typography, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import { TwitchClips } from "components/Taiyoro/EventList/EventInfo/TwitchClips"
import { EventGamePlacement, PlacementType } from "models/Taiyoro/Meta/Placement"
import { useTranslation } from "react-i18next"
import ReactMarkdown from "react-markdown"
import { Link as ReactRouterDomLink } from "react-router-dom"
import { localisedLabel } from "utils/i18n"

import { countriesIncludingInternational } from "../../../../constants/countries"
import Caster from "../../../../models/Taiyoro/Meta/Caster"
import Game from "../../../../models/Taiyoro/Meta/Game"
import Language from "../../../../models/Taiyoro/Meta/Language"
import Organizer from "../../../../models/Taiyoro/Meta/Organizer"
import Platform from "../../../../models/Taiyoro/Meta/Platform"
import Producer from "../../../../models/Taiyoro/Meta/Producer"
import Sponsor from "../../../../models/Taiyoro/Meta/Sponsor"
import { Tag } from "../../../../models/Taiyoro/Meta/Tag"
import Venue from "../../../../models/Taiyoro/Meta/Venue"
import Event from "../../../../models/Taiyoro/event"
import { UserRole } from "../../../../models/UserManagement"
import { ADMIN_ROLES, HIGH_ACCESS_ROLES } from "../../../../utils/roles"
import isValidUrl from "../../../../utils/taiyoro/validators/streamUrl"
import useRolesCanAccess from "../../../../utils/useRolesCanAccess"
import ChipLink from "../../../StyleMaterialUI/ChipLink"
import DatesList from "./DatesList"
import Property from "./Property"
import { RecalculateTaiyoroScore } from "./RecalculateTaiyoroScore"

interface Props {
  model: Event
  tagList: Tag[]
  organizerList: Organizer[]
  producerList: Producer[]
  sponsorList: Sponsor[]
  casterList: Caster[]
  venueList: Venue[]
  platformList: Platform[]
  languageList: Language[]
  games: Game[]
  onDelete: (event: Event) => void
}

const sortByQuantifier = (a: EventGamePlacement, b: EventGamePlacement) => {
  if (!a.placement && b.placement) {
    return 1
  }
  if (!b.placement && a.placement) {
    return -1
  }
  if (a.quantifier < b.quantifier) {
    return -1
  }
  return 0
}

const StyledCard = styled(Card)(({ theme }) => ({
  border: "none",
  borderLeft: `solid 3px ${theme.palette.grey["500"]}`,
  borderRadius: "0px",
}))

const EventInfo = ({
  model,
  tagList,
  organizerList,
  producerList,
  sponsorList,
  casterList,
  venueList,
  platformList,
  languageList,
  games,
  onDelete,
}: Props) => {
  const [successfulCountState, setSuccessfulCountState] = useState<number>(0)
  const [platformsErrorCountState, setPlatformsErrorCountState] = useState<number>(0)
  const [invalidPlatformsUrlCountState, setInvalidPlatformsUrlCountState] = useState<number>(0)
  const [cancelledCountState, setCancelledCountState] = useState<number>(0)

  const [tagsState, setTagsState] = useState<Tag[]>([])

  const { t } = useTranslation(["taiyoro", "common"])

  const canDelete = useRolesCanAccess(HIGH_ACCESS_ROLES)

  const canEdit = useRolesCanAccess([...HIGH_ACCESS_ROLES, UserRole.EDITOR])

  const canViewPlayData = useRolesCanAccess([...ADMIN_ROLES, UserRole.PRODUCER])

  const getBadgeContent = (placement: PlacementType) => {
    if (!placement) {
      return 0
    }
    const placementType = localisedLabel(placement)
    if (placement.quantifiable === 1) {
      return `${placementType}・${placement.quantifier}`
    }
    return localisedLabel(placement)
  }

  useEffect(() => {
    let totalErrored = 0
    let totalInvalid = 0
    let totalSuccess = 0
    let totalCancelled = 0

    model.dates.forEach((date) => {
      if (date.cancelled === 1) {
        totalCancelled++
      }
      date.platforms.forEach((platform) => {
        if (!isValidUrl(platform.url)) {
          totalInvalid++
          return
        }
        if (platform.dataCollectionErrored) {
          totalErrored++
          return
        }
        //Succesfull if there's no dataCollectionError and a vod has views1Week or a stream has viewLiveMinutesWatched
        else if (
          platform.views1Week ||
          platform.views1WeekManual ||
          platform.viewsLiveMinutesWatched ||
          platform.viewsLiveMinutesWatchedManual
        ) {
          totalSuccess++
          return
        }
      })
    })

    setSuccessfulCountState(totalSuccess)
    setPlatformsErrorCountState(totalErrored)
    setInvalidPlatformsUrlCountState(totalInvalid)
    setCancelledCountState(totalCancelled)

    setTagsState(model.tags.map((id: string) => tagList.find((t) => t.id === id)))
  }, [model, tagList])

  return (
    <Box>
      <Stack
        gap={3}
        direction="row"
      >
        <Box>
          <Grid
            container
            spacing={0}
          >
            <Grid
              item
              xs={6}
            >
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  xs={4}
                >
                  <Property title={t("list.summary.eventUrl")}>
                    {model.eventUrl ? (
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <Tooltip title={model.eventUrl}>
                          <Link
                            href={model.eventUrl}
                            style={{ wordBreak: "break-all" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </Link>
                        </Tooltip>
                      </div>
                    ) : (
                      <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>
                    )}
                  </Property>
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Property title={t("list.summary.tier")}>
                    {model.tier !== null ? (
                      <>{t("tiers." + model.tier)}</>
                    ) : (
                      <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>
                    )}
                  </Property>
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Property title={t("list.summary.twitterHandle")}>
                    {model.twitterHandle ? (
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <Tooltip title={model.twitterHandle}>
                          <Link
                            href={`https://twitter.com/${model.twitterHandle}`}
                            style={{ wordBreak: "break-all" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            @{model.twitterHandle} <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </Link>
                        </Tooltip>
                      </div>
                    ) : (
                      <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>
                    )}
                  </Property>
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Property title={t("list.summary.hashtag")}>
                    {model.hashtag ? (
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <Tooltip title={model.hashtag}>
                          <Link
                            href={`https://twitter.com/hashtag/${model.hashtag}`}
                            style={{ wordBreak: "break-all" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            #{model.hashtag} <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </Link>
                        </Tooltip>
                      </div>
                    ) : (
                      <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>
                    )}
                  </Property>
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Property title={t("list.summary.origin")}>
                    {countriesIncludingInternational.find((c) => c.code === model.origin).label}
                  </Property>
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Property title={t("list.summary.imageUrl")}>
                    {model.imageUrl ? (
                      <img
                        src={model.imageUrl}
                        alt="event-banner"
                        height={75}
                      />
                    ) : (
                      <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>
                    )}
                  </Property>
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Property title={t("list.summary.organizers")}>
                    {(organizerList.length > 0 &&
                      organizerList.map((organizer, index) => (
                        <ChipLink
                          to="/taiyoro/meta/organizers"
                          color="primary"
                          size="small"
                          key={index}
                          label={organizer}
                          tooltip
                          style={{ maxWidth: "140px" }}
                        />
                      ))) || <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>}
                  </Property>
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Property title={t("list.summary.producers")}>
                    {(producerList.length > 0 &&
                      producerList.map((producer, index) => (
                        <ChipLink
                          to="/taiyoro/meta/producers"
                          color="primary"
                          size="small"
                          key={index}
                          label={producer}
                          tooltip
                          style={{ maxWidth: "140px" }}
                        />
                      ))) || <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>}
                  </Property>
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Property title={t("list.summary.sponsors")}>
                    {(sponsorList.length > 0 &&
                      sponsorList.map((sponsor, index) => (
                        <ChipLink
                          to="/taiyoro/meta/sponsors"
                          color="primary"
                          size="small"
                          key={index}
                          label={sponsor}
                          tooltip
                          style={{ maxWidth: "140px" }}
                        />
                      ))) || <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>}
                  </Property>
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <Property title={t("list.summary.casters")}>
                    {(casterList.length > 0 &&
                      casterList.map((caster, index) => (
                        <ChipLink
                          to="/taiyoro/meta/casters"
                          color="primary"
                          size="small"
                          key={index}
                          label={caster}
                          tooltip
                          style={{ maxWidth: "140px" }}
                        />
                      ))) || <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>}
                  </Property>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={6}
                >
                  <Property title={t("list.summary.notes")}>
                    {model.notes || (
                      <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>
                    )}
                  </Property>
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <Property title={t("list.summary.description")}>
                    {model.description ? (
                      <ReactMarkdown children={model.description} />
                    ) : (
                      <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>
                    )}
                  </Property>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Property title={t("list.summary.tags")}>
                    {tagsState.map((tag, index) => (
                      <ChipLink
                        to="/taiyoro/meta/tags"
                        key={index}
                        size="small"
                        color="primary"
                        label={tag.name}
                        style={{ maxWidth: "140px" }}
                        tooltip
                      />
                    ))}
                    {tagsState.length === 0 && (
                      <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>
                    )}
                  </Property>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Button variant="outlined" href={`/taiyoro/pickem/${model.id}`} 
            sx={{ display: "block", textWrap: "nowrap", marginBottom: "12px" }}>
            <Stack direction="row" gap={1} alignItems="center">
              {t("pickem.settings")}
              <img
                src="/images/pickem-logo.svg"
                height="20px"
                alt=""
              />
            </Stack>
          </Button>
          <Box style={{ textAlign: "right", minWidth: "130px" }}>
          {canViewPlayData && (
            <ReactRouterDomLink to={`/playdata?eventId=${model.id}`}>
              <IconButton sx={{ width: "32px", height: "32px" }}>
                <FontAwesomeIcon icon={faFileChartPie} />
              </IconButton>
            </ReactRouterDomLink>
          )}
          <ReactRouterDomLink to={`/taiyoro/metrics/${model.id}/timeline`}>
            <IconButton sx={{ width: "32px", height: "32px" }}>
              <FontAwesomeIcon icon={faChartLine} />
            </IconButton>
          </ReactRouterDomLink>

          {canEdit && (
            <Tooltip title={t("common:actions.edit")}>
              <ReactRouterDomLink to={`/taiyoro/event/${model.id}/edit`}>
                <IconButton sx={{ width: "32px", height: "32px" }}>
                  <FontAwesomeIcon icon={faEdit} />
                </IconButton>
              </ReactRouterDomLink>
            </Tooltip>
          )}
          <RecalculateTaiyoroScore eventId={model.id!} />
          {canDelete && (
            <Tooltip title={t("common:actions.delete")}>
              <IconButton
                color="error"
                onClick={() => onDelete(model)}
                sx={{ width: "32px", height: "32px" }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        </Box>
      </Stack>
      <Property title={t("list.summary.games")}>
        <Stack gap={1}>
          {model.games.map((game) => (
            <StyledCard
              variant="outlined"
              key={game.gameId}
              sx={{ paddingLeft: 2 }}
            >
              <Stack
                width="100%"
                direction="row"
                gap={2}
                alignItems="center"
              >
                <Typography>{localisedLabel(games.find((g) => g.id === game.gameId))}</Typography>
                {game.prizePool && (
                  <Typography variant="body2">
                    {`${game.currency} ${Number(game.prizePool).toLocaleString("en-US", {
                      currency: game.currency,
                      style: "currency",
                    })}`}
                  </Typography>
                )}
                {game.resultsUrl && (
                  <Link
                    href={game.resultsUrl}
                    target="_blank"
                    variant="body2"
                  >
                    {t("list.summary.gameList.headers.resultsURL")}
                  </Link>
                )}
              </Stack>

              {game.participants.teams.length > 0 && (
                <Typography
                  color="text.secondary"
                  mb={2}
                >
                  {t("meta.pageTitles.teams")}
                </Typography>
              )}
              <Stack
                direction="row"
                gap={2}
                flexWrap="wrap"
              >
                {[...game.participants.teams].sort(sortByQuantifier).map((team) => (
                  <Link
                    key={team.team.id}
                    target="_blank"
                    href={`/taiyoro/meta/teams?id=${team.team.id}`}
                  >
                    <Stack gap={1}>
                      <Badge
                        badgeContent={getBadgeContent(team.placement)}
                        color="primary"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        <Avatar
                          src={
                            team.team.secondaryImage ||
                            (team.team.teamOrganization && team.team.teamOrganization.secondaryImage)
                          }
                          sx={{
                            width: "75px",
                            height: "75px",
                          }}
                          variant="square"
                        />
                      </Badge>
                      <Typography
                        textAlign="center"
                        maxWidth="75px"
                      >
                        {localisedLabel(team.team)}
                      </Typography>
                    </Stack>
                  </Link>
                ))}
              </Stack>
              {game.participants.players.length > 0 && (
                <Typography
                  color="text.secondary"
                  mb={2}
                >
                  {t("meta.pageTitles.players")}
                </Typography>
              )}
              <Stack
                direction="row"
                gap={2}
                flexWrap="wrap"
              >
                {[...game.participants.players].sort(sortByQuantifier).map((player) => (
                  <Link
                    key={player.player.id}
                    to={`/taiyoro/meta/players?id=${player.player.id}`}
                    component={ReactRouterDomLink}
                  >
                    <Stack gap={1}>
                      <Badge
                        badgeContent={getBadgeContent(player.placement)}
                        color="primary"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        <Avatar
                          src={player.player.primaryImage}
                          sx={{
                            width: "75px",
                            height: "75px",
                          }}
                          variant="square"
                        />
                      </Badge>
                      <Typography
                        textAlign="center"
                        maxWidth="75px"
                      >
                        {localisedLabel(player.player)}
                      </Typography>
                    </Stack>
                  </Link>
                ))}
              </Stack>
            </StyledCard>
          ))}
        </Stack>
      </Property>
      <TwitchClips eventId={model.id} />
      <Grid container>
        {!model.published && (
          <Grid
            item
            xs={12}
          >
            <FontAwesomeIcon
              icon={faPaste}
              color="#FE7C00"
            />
            <span style={{ marginLeft: "4px" }}>{t("list.summary.notPublishedText")}</span>
          </Grid>
        )}
        {cancelledCountState > 0 && (
          <Grid
            item
            xs={12}
          >
            <FontAwesomeIcon
              icon={faBan}
              color="grey"
            />
            <span style={{ marginLeft: "4px" }}>{`${cancelledCountState} ${t(
              "list.summary.cancelledDate"
            )}`}</span>
          </Grid>
        )}
        {successfulCountState > 0 && (
          <Grid
            item
            xs={12}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="#1AAA55"
            />
            <span style={{ marginLeft: "4px" }}>{`${successfulCountState} ${t(
              "list.summary.successfulDataCount"
            )}`}</span>
          </Grid>
        )}
        {invalidPlatformsUrlCountState > 0 && (
          <Grid
            item
            xs={12}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              color="#ffcc01"
            />
            <span style={{ marginLeft: "4px" }}>{`${invalidPlatformsUrlCountState} ${t(
              "list.summary.unsupportedUrlCount"
            )}`}</span>
          </Grid>
        )}
        {platformsErrorCountState > 0 && (
          <Grid
            item
            xs={12}
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              color="red"
            />
            <span style={{ marginLeft: "4px" }}>{`${platformsErrorCountState} ${t(
              "list.summary.dataCollectionFailure"
            )}`}</span>
          </Grid>
        )}
      </Grid>
      <Box>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <Property title={t("list.summary.dates")}>
              <DatesList
                dates={model.dates}
                games={games}
                venues={venueList}
                platforms={platformList}
                languages={languageList}
                eventId={model.id}
              />
            </Property>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default EventInfo

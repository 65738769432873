import Sponsor from "../../models/Taiyoro/Meta/Sponsor"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchSponsors = async (): Promise<Sponsor[] | null> => {
  let response = await GraphQL(
    `query {
        admin {
          sponsor {
            get {
              id
              name
              nameJa
              sortJa
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.sponsor &&
      response.data.admin.sponsor.get &&
      response.data.admin.sponsor.get) ||
    null
  )
}

export const fetchSponsor = async (id: string): Promise<Sponsor | null> => {
  let response = await GraphQL(
    `query {
        admin {
          sponsor {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.sponsor &&
      response.data.admin.sponsor.get &&
      response.data.admin.sponsor.get[0]) ||
    null
  )
}

export const createSponsor = async (name: string, nameJa?: string, sortJa?: string, id?: string) => {
  let response = await GraphQL(
    `mutation {
      admin {
        sponsor {
          save (sponsor: {
            ${id ? `id: "${id}"` : ""}
            name: ${name ? `"${name}"` : null}
            nameJa: ${nameJa ? `"${nameJa}"` : null}
            sortJa: ${sortJa ? `"${sortJa}"` : null}
          }) {
            id
            name
            nameJa
            sortJa
            updatedAt
          }
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.sponsor &&
      response.data.admin.sponsor.save &&
      response.data.admin.sponsor.save) ||
    null
  )
}

export const deleteSponsor = async (id) => {
  let response = await GraphQL(
    `mutation {
      admin {
        sponsor {
          delete (
              sponsorId: "${id}"
          )
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.sponsor &&
      response.data.admin.sponsor.delete) ||
    null
  )
}

import { useContext, useState } from "react"

import { faCheck, faDownload, faExternalLink, faSpinner, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, IconButton, Link, Paper, Stack, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { ArticlesTodoContext } from "contexts/articles"
import { Article } from "models/Taiyoro/article"
import moment from "moment-timezone"
import "moment/locale/ja"
import { useTranslation } from "react-i18next"
import { approveArticle, fetchArticleCSVData, rejectArticle } from "services/Taiyoro/articles"
import { TAIYORO_DOMAIN } from "utils/taiyoro"
import { convertAndDownloadBlobAsCSV } from "utils/tools"
import { useLocalisedLabel } from "utils/useLocalisedLabel"

import { OGImage } from "./styles"

const ArticlesTodo = () => {
  const { articles, loading, reload } = useContext(ArticlesTodoContext)

  const [pendingAction, setPendingAction] = useState(false)

  const [fetchingCSVData, setFetchingCSVData] = useState(false)

  const { t } = useTranslation("taiyoro")

  const localisedLabel = useLocalisedLabel()

  const handleAction = (article: Article, action: "reject" | "approve") => {
    const actionFunction = action === "reject" ? rejectArticle : approveArticle
    setPendingAction(true)
    actionFunction(article.id).finally(() => {
      setPendingAction(false)
      reload()
    })
  }

  const handleCSVDownload = async () => {
    setFetchingCSVData(true)
    fetchArticleCSVData()
      .then((response) => {
        const blob = new Blob([response], { type: "text/csv;encoding:utf-8" })
        convertAndDownloadBlobAsCSV(blob, "articles")
      })
      .finally(() => {
        setFetchingCSVData(false)
      })
  }

  const columns = [
    {
      flex: 1,
      field: "entity",
      sortable: false,
      headerName: t("articles.tableHeaders.entity"),
      renderCell: (params) => {
        const getUrl = () => {
          if (params.value === "event") {
            return `${TAIYORO_DOMAIN}/event/${params.row.entityId}/${params.row.entityData.urlSlug}`
          }
          return `${TAIYORO_DOMAIN}/${params.value}/${params.row.entityData.urlSlug}`
        }

        return (
          <Link
            href={getUrl()}
            target="_blank"
          >
            {localisedLabel(params.row.entityData)}
          </Link>
        )
      },
    },
    {
      flex: 4,
      field: "title",
      sortable: false,
      headerName: t("articles.tableHeaders.title"),
    },
    {
      flex: 3,
      field: "url",
      sortable: false,
      headerName: t("articles.tableHeaders.article"),
      renderCell: (params) => {
        const url = new URL(params.value)
        return (
          <Link
            href={params.value}
            target="_blank"
          >
            {url.hostname}
            <Box
              ml={1}
              component="span"
            >
              <FontAwesomeIcon icon={faExternalLink} />
            </Box>
          </Link>
        )
      },
    },
    {
      flex: 2,
      field: "imageUrl",
      sortable: false,
      headerName: t("articles.tableHeaders.image"),
      renderCell: (params) => <OGImage src={params.value} />,
    },
    {
      flex: 2,
      field: "username",
      sortable: false,
      headerName: t("articles.tableHeaders.user"),
    },
    {
      flex: 2,
      field: "submissionDatetime",
      sortable: false,
      headerName: t("articles.tableHeaders.submissionDate"),
      valueGetter: (params) =>
        moment
          .utc(params.value)
          .tz("Asia/Tokyo")
          .locale(localStorage.getItem("language") || "en")
          .fromNow(),
    },
    {
      flex: 2,
      field: "Actions",
      sortable: false,
      headerName: t("articles.tableHeaders.actions"),
      type: "actions",
      renderCell: (params) => (
        <Stack
          direction="row"
          gap={1}
        >
          <IconButton onClick={() => handleAction(params.row, "approve")}>
            <FontAwesomeIcon icon={faCheck} />
          </IconButton>
          <IconButton onClick={() => handleAction(params.row, "reject")}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </Stack>
      ),
    },
  ]

  return (
    <Paper sx={{ m: 1, p: 1, boxSizing: "border-box", height: "100%" }}>
      <Stack
        height="100%"
        gap={1}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="h5">{t("articles.review")}</Typography>
          <Button
            variant="text"
            disabled={fetchingCSVData}
            startIcon={
              <FontAwesomeIcon
                icon={fetchingCSVData ? faSpinner : faDownload}
                spin={fetchingCSVData}
              />
            }
            onClick={handleCSVDownload}
          >
            {t("analytics.form.downloadCsv")}
          </Button>
        </Stack>
        <Box sx={{ width: "100%", flex: "1" }}>
          <DataGrid
            rows={articles}
            columns={columns}
            disableSelectionOnClick
            loading={loading || pendingAction}
            hideFooter
            density="comfortable"
            disableColumnFilter
            disableDensitySelector
            disableColumnMenu
          />
        </Box>
      </Stack>
    </Paper>
  )
}

export default ArticlesTodo

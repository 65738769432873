import React from "react"

import { styled, Box } from "@mui/material"
import { Chip, lighten, Tooltip } from "@mui/material"
import { CSSProperties } from "@mui/styles"
import { Link } from "react-router-dom"

const Root = styled(Box)`
  margin: 2px;
`

const HoverableChip = styled(Chip)`
  cursor: pointer !important;
  transition: all 0.25s ease-in;

  &:hover {
    transition: all 0.25s ease-out;
    background-color: ${lighten("#3f51b5", 0.2)} !important;
  }
`

interface Props {
  to: string
  label: string | any
  color?: string
  size?: "small" | "medium"
  tooltip?: boolean
  style?: CSSProperties
}

const ChipLink = ({ to, label, tooltip, style, color, ...props }: Props) => (
  <Root component="span">
    {tooltip ? (
      <Tooltip title={label}>
        <Link
          to={to}
          style={{ textDecoration: "none" }}
        >
          <HoverableChip
            style={{ ...style, ...{ marginBottom: "4px" } }}
            {...props}
            label={label}
            sx={{ backgroundColor: color }}
          />
        </Link>
      </Tooltip>
    ) : (
      <Link
        to={to}
        style={{ textDecoration: "none" }}
      >
        <HoverableChip
          style={{ ...style, ...{ marginBottom: "4px" } }}
          {...props}
          label={label}
          sx={{ backgroundColor: color }}
        />
      </Link>
    )}
  </Root>
)

export default ChipLink

import { MetaType } from "models/Taiyoro/Meta/MetaType"
import { Tag, TagHierarchy } from "models/Taiyoro/Meta/Tag"
import GraphQL from "utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchTags = async (): Promise<Tag[] | null> => {
  let response = await GraphQL(
    `query {
        admin {
          tag {
            get {
              id
              name
              nameJa
              sortJa
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.tag &&
      response.data.admin.tag.get &&
      response.data.admin.tag.get) ||
    null
  )
}

export const fetchTag = async (id: string): Promise<Tag | null> => {
  let response = await GraphQL(
    `query {
        admin {
          tag {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.tag &&
      response.data.admin.tag.get &&
      response.data.admin.tag.get[0]) ||
    null
  )
}

export const createTag = async (
  name: string,
  nameJa?: string | null,
  sortJa?: string | null,
  id?: string | null
) => {
  let response = await GraphQL(
    `mutation {
      admin {
        tag {
          save (tag: {
            ${id ? `id: "${id}"` : ""}
            name: ${name ? `"${name}"` : null}
            nameJa: ${nameJa ? `"${nameJa}"` : null}
            sortJa: ${sortJa ? `"${sortJa}"` : null}
          }) {
            id
            name
            nameJa
            sortJa
            updatedAt
          }
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.tag &&
      response.data.admin.tag.save &&
      response.data.admin.tag.save) ||
    null
  )
}

export const deleteTag = async (id) => {
  let response = await GraphQL(
    `mutation {
      admin {
        tag {
          delete (
              tagId: "${id}"
          )
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data && response.data.admin && response.data.admin.tag && response.data.admin.tag.delete) ||
    null
  )
}

export const linkToMeta = async (tagId: string, metaId: string, metaType: MetaType) => {
  return GraphQL(
    `mutation {
        admin {
          tag {
            linkToMeta(tagId: "${tagId}", metaId: "${metaId}", metaType: ${metaType})
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    if (
      response.data &&
      response.data.admin &&
      response.data.admin.tag &&
      response.data.admin.tag.linkToMeta
    ) {
      return response.data.admin.tag.linkToMeta
    }
    throw new Error()
  })
}

export const deleteLinkToMeta = async (tagId: string, metaId: string, metaType: MetaType) => {
  return GraphQL(
    `mutation {
        admin {
          tag {
            deleteLinkToMeta(tagId: "${tagId}", metaId: "${metaId}", metaType: ${metaType})
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    if (
      response.data &&
      response.data.admin &&
      response.data.admin.tag &&
      response.data.admin.tag.deleteLinkToMeta
    ) {
      return response.data.admin.tag.deleteLinkToMeta
    }
    throw new Error()
  })
}

export const fetchTagHierarchy = (): Promise<TagHierarchy> => {
  return GraphQL(
    `query {
        admin {
          tag {
            getHierarchy
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    if (
      response.data &&
      response.data.admin &&
      response.data.admin.tag &&
      response.data.admin.tag.getHierarchy
    ) {
      return response.data.admin.tag.getHierarchy
    }
    throw new Error()
  })
}

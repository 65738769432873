import { createRef } from "react"
import type { MutableRefObject } from "react"
import React from "react"

import type { Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import { Editor } from "@toast-ui/react-editor"
import { useTranslation } from "react-i18next"

interface Props {
  initialValue: string
  onChange: (data: string) => void
  validationRule?: (value: string) => string
}

export const PostMarkdownInput = ({ initialValue, onChange, validationRule }: Props) => {
  const editorRef: React.LegacyRef<Editor> = createRef()

  const {
    palette: { mode },
  } = useTheme<Theme>()

  const {
    i18n: { language: locale },
  } = useTranslation()

  const editHandler = () => {
    const editor = (editorRef as MutableRefObject<Editor>).current.getInstance()
    const data = editor.getMarkdown()
    onChange(data)
  }

  const blurHandler = () => {
    if (validationRule) {
      const editor = (editorRef as MutableRefObject<Editor>).current.getInstance()
      const data = editor.getMarkdown()
      const validatedData = validationRule(data)
      if (data !== validatedData) {
        onChange(validatedData)
        editor.setMarkdown(validatedData, false)
      }
    }
  }

  return (
    <Editor
      initialValue={initialValue}
      height="500px"
      initialEditType="markdown"
      useCommandShortcut={true}
      theme={mode}
      ref={editorRef}
      toolbarItems={[["heading", "bold", "italic", "link", "quote", "ul", "ol", "table"]]}
      language={locale}
      previewStyle="tab"
      onChange={editHandler}
      autofocus={false}
      onBlur={blurHandler}
    />
  )
}

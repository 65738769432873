import { MetaType } from "models/Taiyoro/Meta/MetaType"
import { MetaWithImages } from "models/Taiyoro/Meta/Placement"
import moment from "moment"
import GraphQL, { stringifyObjectForPayload } from "utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

type BracketBase = {
  id: string
  name: string
  dateId: string
  startTime: string
  endTime: string
  viewsLiveConcurrentPeak: number
  viewsLiveMinutesWatched: number
  viewsLiveConcurrentAverage: number
  linkedMeta: BracketLinkedMeta
}

export type EventDateBracket = BracketBase & {
  streamAnalytics: StreamAnalytics
}

export type Bracket = Omit<BracketBase, "startTime" | "endTime"> & {
  eventName: string
  eventId: string
  dateName: string
  createdAt: string
}

export type BracketLinkedMeta = {
  teams: Array<MetaWithImages>
  teamOrganizations: Array<MetaWithImages>
  players: Array<MetaWithImages>
  tags: Array<MetaWithImages>
}

export type StreamAnalytics = Array<{
  datePlatformId: string
  viewsLiveConcurrentPeak: number
  viewsLiveMinutesWatched: number
  viewsLiveConcurrentAverage: number
}>

export const fetchDateBrackets = async (dateId: string): Promise<Array<EventDateBracket>> =>
  GraphQL(
    `{
    admin {
      date {
        getTimeMarks(dateId: ${JSON.stringify(dateId)}) {
          id
          dateId
          name
          startTime
          endTime
          viewsLiveConcurrentPeak
          viewsLiveMinutesWatched
          viewsLiveConcurrentAverage
          streamAnalytics {
            datePlatformId
            viewsLiveConcurrentPeak
            viewsLiveMinutesWatched
            viewsLiveConcurrentAverage
          }
          linkedMeta {
            teams {
              id
              name
              primaryImage
              secondaryImage
            }
            teamOrganizations {
              id
              name
              primaryImage
              secondaryImage
            }
            players{
              id
              name
              nameJa
              primaryImage
            }
            tags {
              id
              name
              nameJa
            }
          }
        }
      }
    }
  }
  `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
    .then((response) => response.data.admin.date.getTimeMarks)
    .then((timeMarks) =>
      timeMarks.map((timeMark) => ({
        ...timeMark,
        startTime: moment.utc(timeMark.startTime).toISOString(),
        endTime: moment.utc(timeMark.endTime).toISOString(),
      }))
    )

export type PaginatedBrackets = {
  totalCount: number
  results: Array<Bracket>
}

export const fetchBracketsList = async (limit: number, offset: number): Promise<PaginatedBrackets> =>
  GraphQL(
    `query {
            admin {
              timeMark {
                get(limit: ${limit}, offset: ${offset}) {
                  results {
                    id
                    dateId
                    name
                    createdAt
                    dateName
                    eventId
                    eventName
                    viewsLiveConcurrentPeak
                    viewsLiveMinutesWatched
                    viewsLiveConcurrentAverage
                    linkedMeta {
                      teams {
                        id
                        name
                        primaryImage
                        secondaryImage
                      }
                      teamOrganizations {
                        id
                        name
                        primaryImage
                        secondaryImage
                      }
                      players{
                        id
                        name
                        nameJa
                        primaryImage
                      }
                      tags {
                        id
                        name
                        nameJa
                      }
                    }
                  }
                  totalCount          
                }
              }
            }
          }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => response.data.admin.timeMark.get)

export const createBracket = async (
  dateId: string,
  name: string,
  startTime: string,
  endTime: string
): Promise<EventDateBracket> =>
  GraphQL(
    `mutation {
    admin {
      date {
        addTimeMark(timeMark: {${stringifyObjectForPayload({ dateId, name, startTime, endTime })}}) {
          id
          dateId
          name
          startTime
          endTime
          viewsLiveConcurrentPeak
          viewsLiveMinutesWatched
          viewsLiveConcurrentAverage
          streamAnalytics {
            datePlatformId
            viewsLiveConcurrentPeak
            viewsLiveMinutesWatched
            viewsLiveConcurrentAverage
          }
          linkedMeta {
            teams {
              id
              name
              primaryImage
              secondaryImage
            }
            teamOrganizations {
              id
              name
              primaryImage
              secondaryImage
            }
            players{
              id
              name
              nameJa
              primaryImage
            }
            tags {
              id
              name
              nameJa
            }
          }
        }
      }
    }
  }`,
    undefined,
    undefined,
    undefined,
    undefined,
    endpoint
  )
    .then((response) => response.data.admin.date.addTimeMark)
    .then((timeMark) => ({ ...timeMark, startTime, endTime }))

export const deleteBracket = async (id: string): Promise<boolean> =>
  GraphQL(
    `mutation {
  admin {
    date {
      deleteTimeMark(timeMarkId: ${JSON.stringify(id)})
    }
  }
}`,
    undefined,
    undefined,
    undefined,
    undefined,
    endpoint
  ).then((response) => response.data.admin.date.deleteTimeMark)

export const addMetaToBracket = async (
  bracketId: string,
  metaType: MetaType,
  metaId: string
): Promise<boolean> =>
  GraphQL(
    `mutation {
  admin {
    date {
      addMetaToTimeMark(timeMarkId: ${JSON.stringify(
        bracketId
      )}, metaType: ${metaType}, metaId: ${JSON.stringify(metaId)})
    }
  }
}`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => response.data.admin.date.addMetaToTimeMark)

export const deleteMetaFromBracket = async (
  bracketId: string,
  metaType: MetaType,
  metaId: string
): Promise<boolean> =>
  GraphQL(
    `mutation {
  admin {
    date {
      deleteMetaFromTimeMark(timeMarkId: ${JSON.stringify(
        bracketId
      )}, metaType: ${metaType}, metaId: ${JSON.stringify(metaId)})
    }
  }
}`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => response.data.admin.date.deleteMetaFromTimeMark)

import Organizer from "../../models/Taiyoro/Meta/Organizer"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchOrganizers = async (): Promise<Organizer[] | null> => {
  let response = await GraphQL(
    `query {
        admin {
          organizer {
            get {
              id
              name
              nameJa
              sortJa
              isGameDeveloper
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.organizer &&
      response.data.admin.organizer.get &&
      response.data.admin.organizer.get) ||
    null
  )
}

export const fetchOrganizer = async (id: string): Promise<Organizer | null> => {
  let response = await GraphQL(
    `query {
        admin {
          organizer {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              isGameDeveloper
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.organizer &&
      response.data.admin.organizer.get &&
      response.data.admin.organizer.get[0]) ||
    null
  )
}

export const createOrganizer = async (
  name: string,
  nameJa?: string | null,
  sortJa?: string | null,
  isGameDeveloper?: boolean | null,
  id?: string | null
) => {
  let response = await GraphQL(
    `mutation {
      admin {
        organizer {
          save (organizer: {
            ${id ? `id: "${id}"` : ""}
            name: ${name ? `"${name}"` : null}
            nameJa: ${nameJa ? `"${nameJa}"` : null}
            sortJa: ${sortJa ? `"${sortJa}"` : null}
            isGameDeveloper: ${isGameDeveloper ? 1 : 0}
          }) {
            id
            name
            nameJa
            sortJa
            isGameDeveloper
            updatedAt
          }
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  response.data.admin.organizer.save.isGameDeveloper =
    response.data.admin.organizer.save.isGameDeveloper === 1

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.organizer &&
      response.data.admin.organizer.save &&
      response.data.admin.organizer.save) ||
    null
  )
}

export const deleteOrganizer = async (id) => {
  let response = await GraphQL(
    `mutation {
      admin {
        organizer {
          delete (
              organizerId: "${id}"
          )
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.organizer &&
      response.data.admin.organizer.delete) ||
    null
  )
}

import { ReactNode, SetStateAction } from "react"
import { useCallback, useContext, useEffect, useState } from "react"
import React from "react"

import { NotificationContext } from "contexts/notification"
import moment from "moment"
import { useUpdateEffect } from "react-use"
import { FeaturedEvent, fetchFeaturedEvents } from "services/Taiyoro/featuredEvents"
import { UTCDateInJST } from "utils/tools"

const PAGE_SIZE = 20

export const TEMPORARY_ID = "temp-id-"

export type PendingChange = {
  type: "create" | "delete" | "update"
  id: string
  action: () => Promise<any>
}

export type TimeRange = {
  start: moment.Moment
  end: moment.Moment
  featuredEvents: Array<FeaturedEvent>
}

type FeaturedEventsContextType = {
  loading: boolean
  featuredEvents: Array<FeaturedEvent>
  setFeaturedEvents: React.Dispatch<SetStateAction<Array<FeaturedEvent>>>
  pendingApiChangesQueue: Array<PendingChange>
  setPendingApiChangesQueue: React.Dispatch<SetStateAction<Array<PendingChange>>>
  reload: () => void
  timeRanges: Array<TimeRange>
}

export const FeaturedEventsContext = React.createContext<FeaturedEventsContextType>({
  loading: true,
  featuredEvents: [],
  setFeaturedEvents: () => {},
  pendingApiChangesQueue: [],
  setPendingApiChangesQueue: () => {},
  reload: () => {},
  timeRanges: [],
})

const sortFeaturedEventsByOrderProperty = (a: FeaturedEvent, b: FeaturedEvent) => a.orderValue - b.orderValue

export const FeaturedEventsProvider = ({ children }: { children: ReactNode }) => {
  const [featuredEvents, setFeaturedEvents] = useState<Array<FeaturedEvent>>([])
  const [timeRanges, setTimeRanges] = useState<Array<TimeRange>>([])
  const [pendingApiChangesQueue, setPendingApiChangesQueue] = useState<Array<PendingChange>>([])

  const [loading, setLoading] = useState(true)

  const { setNotification } = useContext(NotificationContext)

  const loadAllFeaturedEvents = useCallback(async () => {
    try {
      setLoading(true)
      let response
      const cumulativeFeaturedEvents: Array<FeaturedEvent> = []
      while (!response || response.length > 0) {
        response = await fetchFeaturedEvents(PAGE_SIZE, cumulativeFeaturedEvents.length)
        cumulativeFeaturedEvents.push(...response)
      }
      setLoading(false)
      setFeaturedEvents(cumulativeFeaturedEvents)
    } catch (e) {
      setLoading(false)
      setNotification({
        message: JSON.stringify(e),
        severity: "error",
      })
    }
  }, [setLoading, setNotification])

  useEffect(() => {
    loadAllFeaturedEvents()
  }, [loadAllFeaturedEvents])

  const reload = () => {
    setPendingApiChangesQueue([])
    loadAllFeaturedEvents()
  }

  useUpdateEffect(() => {
    const tr = featuredEvents.reduce((acc: Array<TimeRange>, curr: FeaturedEvent) => {
      const currentStart = UTCDateInJST(curr.featuredStartDatetime)
      const currentEnd = UTCDateInJST(curr.featuredEndDatetime)
      const overlappingTimeRange = acc.find(
        (otr) =>
          currentStart.isBetween(otr.start, otr.end, null, "[]") ||
          currentEnd.isBetween(otr.start, otr.end, null, "[]") ||
          otr.start.isBetween(currentStart, currentEnd, null, "[]") ||
          otr.end.isBetween(currentStart, currentEnd, null, "[]")
      )
      if (overlappingTimeRange) {
        overlappingTimeRange.featuredEvents.push(curr)
        overlappingTimeRange.featuredEvents.sort(sortFeaturedEventsByOrderProperty)
        overlappingTimeRange.end = moment.max(
          overlappingTimeRange.featuredEvents.map((featuredEvent) =>
            UTCDateInJST(featuredEvent.featuredEndDatetime)
          )
        )
        overlappingTimeRange.start = moment.min(
          overlappingTimeRange.featuredEvents.map((featuredEvent) =>
            UTCDateInJST(featuredEvent.featuredStartDatetime)
          )
        )
        return acc
      }
      return [
        ...acc,
        {
          start: currentStart,
          end: currentEnd,
          featuredEvents: [curr],
        },
      ]
    }, [])
    setTimeRanges(tr)
  }, [featuredEvents])

  return (
    <FeaturedEventsContext.Provider
      value={{
        loading,
        featuredEvents,
        setFeaturedEvents,
        pendingApiChangesQueue,
        setPendingApiChangesQueue,
        reload: reload,
        timeRanges: timeRanges,
      }}
    >
      {children}
    </FeaturedEventsContext.Provider>
  )
}

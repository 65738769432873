import React, { useState, useContext, useEffect } from "react"

import { faSpinner, faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DialogContent, DialogContentText, DialogActions, Button, Stack, Box } from "@mui/material/"
import getPlatformFromUrl from "@taiyoro/parse-stream-url"
import LoadingBox from "components/LoadingBox"
import { DataRecoveryContext, RecoveryStep } from "contexts/data-recovery"
import { useTranslation } from "react-i18next"
import { fetchRecoverySuggestions, DataCollectionResult } from "services/Taiyoro/dataCollection"

import RecoverElement from "../../RecoverElement"
import StreamResults from "../../StreamResults"
import { StyledLink, ExistingStreamWrapper, StyledStreamLink } from "../../styles"

const ExistingRecover = () => {
  const { t } = useTranslation(["taiyoro", "common"])

  const [loading, setLoading] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [streamSuggestions, setStreamSuggestions] = useState<Array<DataCollectionResult>>([])

  const {
    setStep,
    selectedEventDate,
    selectedStreams,
    selectedExistingStream,
    setSelectedExistingStream,
    setSelectedStreams,
    setError,
    recoveryLoading,
    resetDialog,
    handleExistingRecover,
  } = useContext(DataRecoveryContext)

  useEffect(() => {
    setSelectedExistingStream(null)
  }, [])

  const isSamePlatform = (stream) => {
    try {
      const suggestionPlatform = getPlatformFromUrl(stream.channelUrl)
      const existingStreamPlatform = getPlatformFromUrl(selectedExistingStream.url)
      if (suggestionPlatform === existingStreamPlatform) {
        return true
      }
    } catch (e) {
      return false
    }
  }

  const filteredSuggestions = streamSuggestions.filter(isSamePlatform)

  const noSuggestions = filteredSuggestions.length === 0

  const getStreamSuggestions = () => {
    setLoading(true)

    fetchRecoverySuggestions(selectedEventDate.id)
      .then((fetchedResponse) => {
        setShowResults(true)
        if (fetchedResponse && fetchedResponse.length) {
          setStreamSuggestions(fetchedResponse)
        }
      })
      .catch(() => {
        setError(true)
        resetDialog()
      })
      .finally(() => setLoading(false))
  }

  const resetFlow = () => {
    setStreamSuggestions([])
    setSelectedExistingStream(null)
    setShowResults(false)
  }

  const captionStyles = { lineHeight: 1, pt: 1 }
  const infoStyles = { color: "text.primary" }

  return (
    <>
      {loading && (
        <DialogContent sx={{ mb: 3 }}>
          <LoadingBox />
        </DialogContent>
      )}

      {!loading && (
        <>
          {!showResults && (
            <>
              <DialogContent>
                <DialogContentText sx={{ pb: 2, color: "text.primary" }}>
                  {t("dialogs.recover.selectExistingStream")}
                </DialogContentText>
                <StreamResults>
                  {selectedEventDate &&
                    selectedEventDate.platforms &&
                    selectedEventDate.platforms.map((stream) => {
                      if (stream.isVod === 0) {
                        return (
                          <RecoverElement
                            streamSelection
                            data={stream}
                            key={stream.id}
                          />
                        )
                      }
                    })}
                </StreamResults>
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <StyledLink onClick={() => setStep(RecoveryStep.INITIAL)}>
                    {t("common:actions.back")}
                  </StyledLink>
                  <Button
                    sx={{ ml: 3 }}
                    onClick={() => getStreamSuggestions()}
                    color="primary"
                    autoFocus
                    variant="contained"
                    disabled={!selectedExistingStream ? true : false}
                  >
                    {t("dialogs.recover.loadData")}
                  </Button>
                </Stack>
              </DialogActions>
            </>
          )}
          {showResults && (
            <>
              <DialogContent>
                <DialogContentText sx={{ color: "text.primary" }}>
                  {t("dialogs.recover.existingSuggestions")}
                </DialogContentText>
                {selectedExistingStream && (
                  <ExistingStreamWrapper>
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("edit.dates.streams.url")}
                      </DialogContentText>
                      <StyledStreamLink
                        href={selectedExistingStream.url}
                        target="_blank"
                        rel="noopener"
                      >
                        <span>{selectedExistingStream.url}</span>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                      </StyledStreamLink>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ mr: 1 }}
                    >
                      <Stack>
                        <DialogContentText
                          variant="caption"
                          sx={captionStyles}
                        >
                          {t("list.header.pcu")}
                        </DialogContentText>
                        <DialogContentText sx={infoStyles}>
                          {Number(selectedExistingStream.viewsLiveConcurrentPeak).toLocaleString() ||
                            Number(selectedExistingStream.viewsLiveConcurrentPeakManual).toLocaleString()}
                        </DialogContentText>
                      </Stack>
                      <Stack>
                        <DialogContentText
                          variant="caption"
                          sx={captionStyles}
                        >
                          {t("list.header.acu")}
                        </DialogContentText>
                        <DialogContentText sx={infoStyles}>
                          {Number(selectedExistingStream.viewsLiveConcurrentAverage).toLocaleString() ||
                            Number(selectedExistingStream.viewsLiveConcurrentAverageManual).toLocaleString()}
                        </DialogContentText>
                      </Stack>
                      <Stack>
                        <DialogContentText
                          variant="caption"
                          sx={captionStyles}
                        >
                          {t("list.header.minutesWatched")}
                        </DialogContentText>
                        <DialogContentText sx={infoStyles}>
                          {Number(selectedExistingStream.viewsLiveMinutesWatched).toLocaleString() ||
                            Number(selectedExistingStream.viewsLiveMinutesWatchedManual).toLocaleString()}
                        </DialogContentText>
                      </Stack>
                    </Stack>
                  </ExistingStreamWrapper>
                )}
                <DialogContentText sx={{ pt: 3, pb: 2, color: "text.primary" }}>
                  {t("dialogs.recover.selectStream")}
                </DialogContentText>
                <StreamResults>
                  {noSuggestions && (
                    <DialogContentText sx={{ p: 3, color: "text.primary" }}>
                      {t("dialogs.recover.noExistingResults")}
                    </DialogContentText>
                  )}
                  {!noSuggestions &&
                    filteredSuggestions.map((stream) => (
                      <RecoverElement
                        data={stream}
                        key={stream.id}
                      />
                    ))}
                </StreamResults>
              </DialogContent>

              <DialogActions sx={{ p: 3 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: 1 }}
                >
                  {noSuggestions && (
                    <>
                      <StyledLink onClick={() => setStep(RecoveryStep.CHANNEL_FLOW)}>
                        {t("dialogs.recover.searchByChannel")}
                      </StyledLink>
                      <Stack
                        direction="row"
                        alignItems="center"
                      >
                        <StyledLink
                          disabled={recoveryLoading}
                          onClick={() => resetFlow()}
                        >
                          {t("common:actions.back")}
                        </StyledLink>
                        <StyledLink onClick={() => setStep(RecoveryStep.VOD_FLOW)}>
                          {t("dialogs.recover.searchByVod")}
                        </StyledLink>
                      </Stack>
                    </>
                  )}
                  {!noSuggestions && (
                    <>
                      <StyledLink
                        disabled={recoveryLoading}
                        onClick={() => setStep(RecoveryStep.CHANNEL_FLOW)}
                      >
                        {t("dialogs.recover.notSuggested")}
                      </StyledLink>
                      <Stack
                        direction="row"
                        alignItems="center"
                      >
                        <StyledLink
                          disabled={recoveryLoading}
                          onClick={() => resetFlow()}
                        >
                          {t("common:actions.back")}
                        </StyledLink>
                        <Button
                          sx={{ ml: 3 }}
                          onClick={() => setSelectedStreams(filteredSuggestions)}
                          color="primary"
                          variant="contained"
                          disabled={recoveryLoading}
                        >
                          {t("dialogs.recover.selectAll")}
                        </Button>
                        <Button
                          sx={{ ml: 3 }}
                          onClick={recoveryLoading ? () => {} : () => handleExistingRecover()}
                          color="primary"
                          autoFocus
                          variant="contained"
                          disabled={
                            !noSuggestions && (selectedStreams === undefined || selectedStreams.length === 0)
                              ? true
                              : false
                          }
                        >
                          {recoveryLoading && (
                            <Box
                              component="span"
                              sx={{ pr: 1 }}
                            >
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spinPulse
                              />
                            </Box>
                          )}
                          {t("dialogs.recover.recover")}
                        </Button>
                      </Stack>
                    </>
                  )}
                </Stack>
              </DialogActions>
            </>
          )}
        </>
      )}
    </>
  )
}

export default ExistingRecover

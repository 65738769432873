import { useContext, useState } from "react"

import { faSpinner, faTrashAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import RealTimeUpdateField from "components/Taiyoro/RealTimeUpdateField"
import { EventContext } from "contexts/event"
import Placement, { EventGamePlacement } from "models/Taiyoro/Meta/Placement"
import SignificantPlayer from "models/Taiyoro/Meta/SignificantPlayer"
import Team from "models/Taiyoro/Meta/Team"
import { EventGame } from "models/Taiyoro/eventGame"
import { useTranslation } from "react-i18next"
import {
  addPlayerPlacement,
  addTeamPlacement,
  deletePlayerPlacement,
  deleteTeamPlacement,
} from "services/Taiyoro/eventGame"
import { localisedLabel } from "utils/i18n"

interface Props {
  eventGame: EventGame
  type: "team" | "player"
  options: Array<SignificantPlayer | Team>
  availablePlacements: Array<Placement>
  placement: EventGamePlacement
  canDelete: boolean
}

const PlacementEntry = (props: Props) => {
  const { t } = useTranslation("taiyoro")

  const [event, setEvent] = useContext(EventContext)

  const [isDeleting, setIsDeleting] = useState(false)

  const handleDelete = async () => {
    const deleteFunc = props.type === "team" ? deleteTeamPlacement : deletePlayerPlacement
    setIsDeleting(true)
    const success = await deleteFunc(event.id, props.eventGame.gameId, props.placement[props.type].id)
    if (success === true) {
      const newEvent = Object.assign(Object.create(Object.getPrototypeOf(event)), event)
      const index = event.games
        .find((game) => game.gameId === props.eventGame.gameId)
        .participants[`${props.type}s`].indexOf(props.placement)
      newEvent.games
        .find((game) => game.gameId === props.eventGame.gameId)
        .participants[`${props.type}s`].splice(index, 1)
      setEvent(newEvent)
    }
  }

  const updatePlacementType = (placement: Placement | null) => {
    const newEvent = Object.assign(Object.create(Object.getPrototypeOf(event)), event)
    const index = event.games
      .find((game) => game.gameId === props.eventGame.gameId)
      .participants[`${props.type}s`].findIndex((p) => p[props.type].id === props.placement[props.type].id)
    newEvent.games.find((game) => game.gameId === props.eventGame.gameId).participants[`${props.type}s`][
      index
    ] = {
      eventId: event.id,
      gameId: props.eventGame.gameId,
      [`${props.type}`]: {
        id: props.placement[props.type].id,
      },
      placement: {
        id: placement?.id,
      },
      quantifier: placement?.quantifiable === 1 ? 1 : null,
    }
    setEvent(newEvent)
  }

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        marginLeft={2}
      >
        <Typography>
          {localisedLabel(props.options.find((o) => o.id === props.placement[props.type].id))}
        </Typography>
        <Box width="300px">
          <RealTimeUpdateField
            type="select"
            options={props.availablePlacements}
            label={t("meta.pageTitles.placements")}
            updateFunc={(value) => {
              const updateFunc = props.type === "team" ? addTeamPlacement : addPlayerPlacement
              updatePlacementType(value)
              updateFunc(
                event.id,
                props.eventGame.gameId,
                props.placement[props.type].id,
                value?.id,
                value?.quantifiable === 1 ? 1 : null
              )
            }}
            initialValue={props.availablePlacements.find((p) => p.id === props.placement?.placement?.id)}
          />
        </Box>
        {props.availablePlacements.find((p) => p.id === props.placement?.placement?.id)?.quantifiable ===
          1 && (
          <Box width="200px">
            <RealTimeUpdateField
              type="number-format"
              label={t("edit.games.quantifier")}
              updateFunc={(value) => {
                const updateFunc = props.type === "team" ? addTeamPlacement : addPlayerPlacement
                return updateFunc(
                  event.id,
                  props.eventGame.gameId,
                  props.placement[props.type].id,
                  props.placement.placement.id,
                  value
                )
              }}
              initialValue={props.placement.quantifier}
              onFocus={(event) => event.target.select()}
            />
          </Box>
        )}
        {props.canDelete && (
          <Box sx={{ marginLeft: "auto !important" }}>
            <Tooltip title={t("common:actions.delete")}>
              <IconButton
                color="error"
                onClick={handleDelete}
                sx={{ width: "32px", height: "32px" }}
                disabled={isDeleting}
              >
                <FontAwesomeIcon
                  icon={isDeleting ? faSpinner : faTrashAlt}
                  {...(isDeleting ? { spin: true } : {})}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Stack>
    </Box>
  )
}

export default PlacementEntry

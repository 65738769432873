import SignificantPlayer from "../../models/Taiyoro/Meta/SignificantPlayer"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchSignificantPlayers = async (): Promise<SignificantPlayer[] | null> => {
  let response = await GraphQL(
    `query {
        admin {
          significantPlayer {
            get {
              id
              name
              nameJa
              sortJa
              teamId
              teamOrganizationId
              primaryImage
              urlSlug
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.significantPlayer &&
      response.data.admin.significantPlayer.get &&
      response.data.admin.significantPlayer.get) ||
    null
  )
}

export const fetchSignificantPlayer = async (id: string): Promise<SignificantPlayer | null> => {
  let response = await GraphQL(
    `query {
        admin {
          significantPlayer {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              teamId
              teamOrganizationId
              primaryImage
              urlSlug
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.significantPlayer &&
      response.data.admin.significantPlayer.get &&
      response.data.admin.significantPlayer.get[0]) ||
    null
  )
}

export const createSignificantPlayer = async (
  name: string,
  urlSlug: string,
  nameJa?: string,
  sortJa?: string,
  teamId?: string,
  teamOrganizationId?: string,
  primaryImage?: string,
  id?: string
) => {
  let response = await GraphQL(
    `mutation {
      admin {
        significantPlayer {
          save (significantPlayer: {
            ${id ? `id: "${id}"` : ""}
            urlSlug: ${urlSlug ? `"${urlSlug}"` : null}
            name: ${name ? `"${name}"` : null}
            nameJa: ${nameJa ? `"${nameJa}"` : null}
            sortJa: ${sortJa ? `"${sortJa}"` : null}
            teamId: ${teamId ? `"${teamId}"` : null}
            teamOrganizationId: ${teamOrganizationId ? `"${teamOrganizationId}"` : null}
            primaryImage: ${primaryImage ? `"${primaryImage}"` : null}
          }) {
            id
            name
            nameJa
            sortJa
            teamId
            urlSlug
            teamOrganizationId
            primaryImage
            updatedAt
          }
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.significantPlayer &&
      response.data.admin.significantPlayer.save) ||
    null
  )
}

export const deleteSignificantPlayer = async (id) => {
  let response = await GraphQL(
    `mutation {
      admin {
        significantPlayer {
          delete (
              significantPlayerId: "${id}"
          )
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.significantPlayer &&
      response.data.admin.significantPlayer.delete) ||
    null
  )
}

import React, { ChangeEvent, FocusEventHandler, useState } from "react"

import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Link,
  Paper,
  Popper,
  Slide,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocation, matchPath } from "react-router"
import { Link as ReactRouterDomLink } from "react-router-dom"
import { useDebounce } from "react-use"

import { searchFeedAndMeta } from "../../../services/Taiyoro/event"
import { localisedLabel } from "../../../utils/i18n"
import { Search, SearchIconWrapper, StyledInputBase, popperOffset } from "./styles"

const metaMap = {
  games: {
    titleSlug: "meta.pageTitles.games",
    link: "games",
  },
  teams: {
    titleSlug: "meta.pageTitles.teams",
    link: "teams",
  },
  platforms: {
    titleSlug: "meta.pageTitles.platforms",
    link: "platforms",
  },
  gameGroups: {
    titleSlug: "meta.pageTitles.gameGroups",
    link: "game-groups",
  },
  producers: {
    titleSlug: "meta.pageTitles.producers",
    link: "producers",
  },
  organizers: {
    titleSlug: "meta.pageTitles.organizers",
    link: "organizers",
  },
  casters: {
    titleSlug: "meta.pageTitles.casters",
    link: "casters",
  },
  significantPlayers: {
    titleSlug: "meta.pageTitles.players",
    link: "players",
  },
  sponsors: {
    titleSlug: "meta.pageTitles.sponsors",
    link: "sponsors",
  },
  tags: {
    titleSlug: "meta.pageTitles.tags",
    link: "tags",
  },
  teamOrganizations: {
    titleSlug: "meta.pageTitles.teamOrganizations",
    link: "team-organizations",
  },
  venues: {
    titleSlug: "meta.pageTitles.venues",
    link: "venues",
  },
}

const QuickSearch = () => {
  const { t } = useTranslation("taiyoro")

  const [searchTermState, setSearchTermState] = useState("")

  const [resultState, setResultState] = useState<null | any>(null)

  const [showSearchBox, setShowSearchBox] = useState(false)

  const [focusState, setFocusState] = useState(false)

  const [anchorElState, setAnchorElState] = useState<HTMLInputElement | null>(null)

  const [loading, setLoading] = useState(false)

  const handleClickAway = () => {
    if (!focusState) {
      setResultState(null)
      setShowSearchBox(false)
      setSearchTermState("")
    }
  }

  const handleOnBlur = () => {
    setFocusState(false)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value || event.target.value === "") {
      setResultState(null)
      setShowSearchBox(false)
    }
    setSearchTermState(event.target.value || "")
    setLoading(event.target.value !== "")
    event.target.value && setShowSearchBox(true)
    setAnchorElState(event.currentTarget)
  }

  const handleSearch = (searchTerm) => {
    setLoading(true)
    searchFeedAndMeta(searchTerm).then((results) => {
      setResultState(results)
      setLoading(false)
    })
  }

  useDebounce(
    () => {
      searchTermState !== "" && handleSearch(searchTermState)
    },
    700,
    [searchTermState]
  )

  const handleOnFocus: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setFocusState(true)
  }

  const getTop10Events = () => {
    if (!resultState) {
      return []
    }
    return resultState.feed.reduce((acc, curr) => {
      if (acc.length === 10) {
        return acc
      }
      if (!acc.some((event) => event.id === curr.event.id)) {
        return [...acc, curr.event]
      }
      return acc
    }, [])
  }

  const location = useLocation()

  const isTaiyoroRoute = matchPath(location.pathname, {
    path: "/taiyoro",
  })

  return (
    isTaiyoroRoute && (
      <>
        <Search>
          <SearchIconWrapper>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={t("page.search.placeholder")}
            value={searchTermState}
            inputProps={{ className: showSearchBox ? "force-focus" : "" }}
            onChange={handleInputChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
        </Search>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            open={showSearchBox}
            anchorEl={anchorElState}
            modifiers={popperOffset}
            placement="bottom-start"
            transition
            style={{ zIndex: 20 }}
          >
            {({ TransitionProps }) => (
              <Slide
                direction="down"
                in={showSearchBox}
                mountOnEnter
                unmountOnExit
                {...TransitionProps}
                timeout={100}
              >
                <Paper>
                  <Box
                    width="350px"
                    sx={{ padding: "12px" }}
                  >
                    {loading && (
                      <Box padding="30px 0px">
                        <CircularProgress
                          style={{ margin: "0 auto", display: "block" }}
                          size="24px"
                        />
                      </Box>
                    )}
                    {!loading && resultState && (
                      <Box>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                        >
                          {t("page.search.events")}
                        </Typography>
                        {getTop10Events().length === 0 && (
                          <Typography>{t("page.search.noEvents")}</Typography>
                        )}
                        {getTop10Events().map((event) => (
                          <Link
                            key={event.id}
                            color="inherit"
                            to={`/taiyoro/event/${event.id}`}
                            underline="none"
                            title={event.name}
                            component={ReactRouterDomLink}
                            sx={{
                              display: "block",
                              cursor: "pointer",
                              borderRadius: "5px",
                              padding: "6px",
                              "&:hover": (theme) => ({
                                backgroundColor: theme.palette.action.hover,
                              }),
                            }}
                          >
                            <Typography noWrap>{event.name}</Typography>
                          </Link>
                        ))}
                        {Object.keys(metaMap).map((meta) => (
                          <React.Fragment key={meta}>
                            {resultState[meta].length > 0 && (
                              <Box mt="12px">
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  component="div"
                                  sx={{ marginBottom: "6px" }}
                                >
                                  {t(metaMap[meta].titleSlug)}
                                </Typography>
                                {resultState[meta].slice(0, 2).map((metaItem) => (
                                  <Link
                                    key={metaItem.id}
                                    color="inherit"
                                    to={`/taiyoro/meta/${metaMap[meta].link}?id=${metaItem.id}`}
                                    component={ReactRouterDomLink}
                                    underline="none"
                                    title={localisedLabel(metaItem)}
                                    sx={{
                                      display: "block",
                                      cursor: "pointer",
                                      borderRadius: "5px",
                                      padding: "6px",
                                      "&:hover": (theme) => ({
                                        backgroundColor: theme.palette.action.hover,
                                      }),
                                    }}
                                  >
                                    <Typography noWrap>{localisedLabel(metaItem)}</Typography>
                                  </Link>
                                ))}
                              </Box>
                            )}
                          </React.Fragment>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Slide>
            )}
          </Popper>
        </ClickAwayListener>
      </>
    )
  )
}

export default QuickSearch

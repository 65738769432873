import Placement from "models/Taiyoro/Meta/Placement"
import GraphQL from "utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchPlacements = async (): Promise<Placement[] | null> => {
  let response = await GraphQL(
    `query {
        admin {
          placement {
            get {
              id
              name
              nameJa
              sortJa
              quantifiable
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.placement &&
      response.data.admin.placement.get &&
      response.data.admin.placement.get) ||
    null
  )
}

export const fetchPlacement = async (id: string): Promise<Placement | null> => {
  let response = await GraphQL(
    `query {
        admin {
          placement {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              quantifiable
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.placement &&
      response.data.admin.placement.get &&
      response.data.admin.placement.get[0]) ||
    null
  )
}

export const createPlacement = async (
  name: string,
  nameJa?: string | null,
  sortJa?: string | null,
  quantifiable?: number | null,
  id?: string | null
) => {
  let response = await GraphQL(
    `mutation {
      admin {
        placement {
          save (placement: {
            ${id ? `id: "${id}"` : ""}
            name: ${name ? `"${name}"` : null}
            nameJa: ${nameJa ? `"${nameJa}"` : null}
            sortJa: ${sortJa ? `"${sortJa}"` : null}
            quantifiable: ${quantifiable === 1 ? 1 : 0}
          }) {
            id
            name
            nameJa
            sortJa
            quantifiable
            updatedAt
          }
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.placement &&
      response.data.admin.placement.save &&
      response.data.admin.placement.save) ||
    null
  )
}

export const deletePlacement = async (id) => {
  let response = await GraphQL(
    `mutation {
      admin {
        placement {
          delete (
              placementId: "${id}"
          )
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.placement &&
      response.data.admin.placement.delete) ||
    null
  )
}

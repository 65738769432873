import { useTranslation } from "react-i18next"

interface LocalisedEntity {
  name: string
  nameJa: string
}

export const useLocalisedLabel = () => {
  const { i18n } = useTranslation()

  return (entity: LocalisedEntity) => {
    let label = ""

    if (!entity) {
      return ""
    }

    if (i18n.language === "en") {
      label = entity["name"] || entity["nameJa"]
    }

    if (i18n.language === "ja") {
      label = entity["nameJa"] || entity["name"]
    }

    return label || entity["nameJa"] || entity["name"] || ""
  }
}

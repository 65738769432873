import { useContext, useEffect, useState } from "react"
import React from "react"

import { faCheck, faSpinner, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip, Stack, autocompleteClasses } from "@mui/material"
import clsx from "clsx"
import DropdownSearchable from "components/Form/DropdownSearchable"
import { FlattenedHierarchy, flattenTagHierarchy } from "components/Taiyoro/EventList/TagFilter"
import { GroupableTags } from "components/Taiyoro/EventList/TagFilter/styles"
import { EventContext } from "contexts/event"
import { TaiyoroDataContext } from "contexts/tag-hierarchy"
import { errorContext } from "index"
import { MetaType } from "models/Taiyoro/Meta/MetaType"
import { Tag } from "models/Taiyoro/Meta/Tag"
import { useTranslation } from "react-i18next"
import { deleteLinkToMeta, linkToMeta } from "services/Taiyoro/tags"
import { useLocalisedLabel } from "utils/useLocalisedLabel"

/* This component is an entire duplicate of `components/Taiyoro/EventList/TagFilter` mixed with
copied logic from `components/Taiyoro/RealTimeUpdateField`. The original TagFilter is a well
developed, isolated component, and should not be mixed with the reportedly buggy logic from 
RealTimeUpdateField. When the CreateEvent flow is redeveloped, this component can be deleted */
const RealTimeUpdateTagEventTags = () => {
  const { tagHierarchy, loadingTags } = useContext(TaiyoroDataContext)

  const [event, _setEvent] = useContext(EventContext)

  const [flattenedTagHierarchy, setFlattenedTagHierarchy] = useState<FlattenedHierarchy>({
    tags: [],
    groupIds: [],
  })

  const { t } = useTranslation("taiyoro")

  useEffect(() => {
    if (tagHierarchy && flattenedTagHierarchy.tags.length === 0) {
      setFlattenedTagHierarchy(flattenTagHierarchy(tagHierarchy))
    }
  }, tagHierarchy)

  const localisedLabel = useLocalisedLabel()

  const isGroup = (tag: Tag) => flattenedTagHierarchy.groupIds.some((tagId) => tagId === tag.id)

  const isNoGroup = (tag: Tag) => tagHierarchy.some((tagNode) => tagNode.tag.id === tag.id)

  const [updatedValueState, setUpdatedValueState] = useState([])
  const [currentValueState, setCurrentValueState] = useState([])

  const [pendingChangesState, setPendingChangesState] = useState(null)
  const [updatingState, setUpdatingState] = useState(false)
  const [successState, setSuccessState] = useState(false)
  const [errorDataState, setErrorDataState] = useState(null)
  const [errorState, setErrorState] = errorContext()

  useEffect(() => {
    const tags = event.tags
      .map((tagId) => flattenedTagHierarchy.tags.find((t) => t.id === tagId))
      .filter((x) => !!x)
    setUpdatedValueState(tags)
    setCurrentValueState(tags)
  }, [event, flattenedTagHierarchy])

  const handleError = (error) => {
    let newErrorState = [...errorState]
    if (errorDataState) {
      newErrorState = newErrorState.filter((error) => error !== errorDataState)
    }
    const errorMessage = `${t("edit.tags")}: ${error}`
    newErrorState.push(errorMessage)
    setErrorDataState(errorMessage)
    setErrorState(newErrorState)
    setUpdatingState(false)
  }

  useEffect(() => {
    if (pendingChangesState) {
      setUpdatingState(true)
      setSuccessState(false)
      const doChanges = async (changes: any) => {
        for (let pendingChange of changes) {
          await pendingChange()
        }
      }
      doChanges(pendingChangesState)
        .then(() => {
          setSuccessState(true)
          setUpdatingState(false)
          setPendingChangesState(null)
          setErrorState(errorState.filter((error) => error !== errorDataState))
          setErrorDataState(null)
        })
        .catch(handleError)
    }
  }, [pendingChangesState])

  const label = (
    <React.Fragment>
      <span>{t("edit.tags")} &nbsp;</span>
      {!successState && updatingState && (
        <FontAwesomeIcon
          title="Updating"
          icon={faSpinner}
          spin
        />
      )}
      {successState && !updatingState && (
        <FontAwesomeIcon
          title="Updated"
          icon={faCheck}
        />
      )}
      {errorDataState && !updatingState && (
        <FontAwesomeIcon
          title="Error updating"
          color="red"
          icon={faTimes}
        />
      )}
    </React.Fragment>
  )

  return (
    <>
      {!loadingTags && (
        <DropdownSearchable
          multiple
          options={flattenedTagHierarchy.tags}
          label={label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          optionLabel={(option) => localisedLabel(option)}
          filterSelectedOptions={false}
          renderOption={(props, option) => {
            return (
              <GroupableTags
                {...props}
                key={option.id}
                className={clsx(
                  [autocompleteClasses.option, "option"],
                  isGroup(option) && "group",
                  isNoGroup(option) && "noGroup"
                )}
              >
                {localisedLabel(option)}
              </GroupableTags>
            )
          }}
          value={updatedValueState}
          variant={"outlined"}
          clearOnBlur
          indicateChanges={successState}
          onChange={(event) => {
            setUpdatedValueState(event)
          }}
          renderTags={(value, getTagProps, ownerState) => {
            return (
              <Stack
                direction="row"
                gap={0.5}
                flexWrap="wrap"
              >
                {value.map((option, index) => {
                  const { chipProps, onDelete } = getTagProps(index)
                  return (
                    <Chip
                      variant="outlined"
                      label={localisedLabel(option)}
                      {...getTagProps({ index })}
                    />
                  )
                })}
              </Stack>
            )
          }}
          onBlur={() => {
            const removed = currentValueState.filter((entity) => !updatedValueState.some((e) => e === entity))
            const added = updatedValueState.filter((e) => !currentValueState.some((entity) => entity === e))
            const changes = [
              ...removed.map((r) => () => deleteLinkToMeta(r.id, event.id, MetaType.EVENT)),
              ...added.map((a) => () => linkToMeta(a.id, event.id, MetaType.EVENT)),
            ]
            if (changes.length > 0) {
              setPendingChangesState(changes)
              setCurrentValueState(updatedValueState)
            }
          }}
        />
      )}
    </>
  )
}

export default RealTimeUpdateTagEventTags

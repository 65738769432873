import EventStream from "models/Taiyoro/Stream"
import GraphQL from "utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchDatePlatformTrim = (
  datePlatformId: string
): Promise<{ startDatetime: string | null; endDatetime: string | null }> => {
  return GraphQL(
    `query {
        admin{
          datePlatform{
            trimmedTime(id: ${JSON.stringify(datePlatformId)}) {
              startDatetime
              endDatetime
            }
          }
        }
      }
      `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return response.data.admin.datePlatform.trimmedTime
  })
}

export const updateDatePlatformTrim = (
  datePlatformId: string,
  startDatetime: string,
  endDatetime: string
): Promise<Array<EventStream>> => {
  return GraphQL(
    `mutation {
        admin{
          datePlatform {
            trimTimeline(id: ${JSON.stringify(datePlatformId)}, startDatetime: ${JSON.stringify(
              startDatetime
            )}, endDatetime: ${JSON.stringify(endDatetime)}) {
              id
              dateId
              platformId
              url
              viewsLiveUnique
              viewsLiveTotal
              viewsLiveConcurrentPeak
              viewsLiveMinutesWatched
              liveBroadcastDuration
              viewsLiveConcurrentAverage
              viewsLiveConcurrentPeakManual
              viewsLiveMinutesWatchedManual
              viewsLiveConcurrentAverageManual
              isVod
              isLive
              lastLiveCheckDatetime
              views1Week
              viewsWeek2
              viewsWeek3
              viewsWeek4
              views1WeekManual
              viewsWeek2Manual
              viewsWeek3Manual
              viewsWeek4Manual
              views1Month
              language
              dataCollectionError
              isDataCollectionScheduled
              automaticallyGenerateVods
              automaticallyGenerated
              isSubStream
              channelId
              channelDisplayName
              recoveryStatus
              channelIconUrl
            }
          }
        }
      }
     `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return response.data.admin.datePlatform.trimTimeline
  })
}

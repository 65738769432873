import React from "react"

import { useTranslation } from "react-i18next"

import MetasPage from "../../../components/Taiyoro/MetaPage"
import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import { fetchSponsors, createSponsor, deleteSponsor } from "../../../services/Taiyoro/sponsors"

const MetaSponsorPage = () => {
  const { t } = useTranslation("taiyoro")

  return (
    <MetasPage
      title={t("meta.pageTitles.sponsors")}
      fetchFunc={fetchSponsors}
      deleteFunc={deleteSponsor}
      addNewFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createSponsor(name, nameJa, sortJa, id)}
      updateFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createSponsor(name, nameJa, sortJa, id)}
      metaType={MetaType.SPONSOR}
    />
  )
}

export default MetaSponsorPage

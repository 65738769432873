import React, { useContext } from "react"

// import { Visibility as VisibilityIcon } from "@mui/icons-material/"
import { Stack, Typography, Box } from "@mui/material"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"

import Participants from "../Participants"
import SaveData from "../SaveData"

export const SettingsOptions = () => {
  const { t } = useTranslation("taiyoro")
  const { eventState } = useContext(PickemSettingsContext)

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{ mb: 3, fontWeight: 700 }}
        >
          {t("eventPickem.title")}
        </Typography>

        <Box>
          {/* <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            endIcon={<VisibilityIcon />}
            sx={{ mr: 2 }}
          >
            {t("eventPickem.preview")}
          </Button> */}

          <SaveData />
        </Box>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          mb: 2,
        }}
      >
        <Typography
          variant="h5"
          mb={1}
        >
          {eventState?.name}
        </Typography>
        <Participants />
      </Stack>
    </Box>
  )
}

import Game from "../../models/Taiyoro/Meta/Game"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchGames = async (): Promise<Array<Game>> => {
  let response = await GraphQL(
    `query {
        admin {
          game {
            get {
              id
              name
              nameJa
              sortJa
              hashtag
              urlSlug
              primaryImage
              secondaryImage
              alternativeNames
              parentGroups {
                id
              }
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.game &&
      response.data.admin.game.get &&
      response.data.admin.game.get) ||
    null
  )
}

export const fetchGame = async (id: string): Promise<Game | null> => {
  let response = await GraphQL(
    `query {
        admin {
          game {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              hashtag
              urlSlug
              primaryImage
              secondaryImage
              alternativeNames
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.game &&
      response.data.admin.game.get &&
      response.data.admin.game.get[0]) ||
    null
  )
}

export const createGame = async (
  name: string,
  nameJa?: string,
  sortJa?: string,
  hashtag?: string,
  urlSlug?: string,
  primaryImage?: string,
  secondaryImage?: string,
  id?: string
) => {
  let response = await GraphQL(
    `mutation {
      admin {
        game {
          save (game: {
            ${id ? `id: "${id}"` : ""}
            name: ${name ? `"${name}"` : null}
            nameJa: ${nameJa ? `"${nameJa}"` : null}
            sortJa: ${sortJa ? `"${sortJa}"` : null}
            hashtag: ${hashtag ? `"${hashtag}"` : null}
            urlSlug: ${urlSlug ? `"${urlSlug}"` : null}
            primaryImage: ${primaryImage ? `"${primaryImage}"` : null}
            secondaryImage: ${secondaryImage ? `"${secondaryImage}"` : null}
          }) {
            id
            name
            nameJa
            sortJa
            hashtag
            urlSlug
            primaryImage
            secondaryImage
            updatedAt
          }
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data && response.data.admin && response.data.admin.game && response.data.admin.game.save) ||
    null
  )
}

export const deleteGame = async (id) => {
  let response = await GraphQL(
    `mutation {
      admin {
        game {
          delete (
              gameId: "${id}"
          )
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data && response.data.admin && response.data.admin.game && response.data.admin.game.delete) ||
    null
  )
}

export const addGameAlternativeName = async (gameId, name) => {
  let response = await GraphQL(
    `mutation {
      admin {
        game {
          addGameAlternativeName (
              gameId: "${gameId}"
              name: "${name}"
          ) {
            gameId
            name
          }
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.game &&
      response.data.admin.game.addGameAlternativeName) ||
    null
  )
}

export const deleteGameAlternativeName = async (gameId, name) => {
  let response = await GraphQL(
    `mutation {
      admin {
        game {
          deleteGameAlternativeName (
              gameId: "${gameId}"
              name: "${name}"
          )
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.game &&
      response.data.admin.game.deleteGameAlternativeName) ||
    null
  )
}

import React from "react"

import { Link, Typography } from "@mui/material"

import {
  DataCollectionResult,
  DataCollectionSort,
  fetchDataCollection,
} from "../../../services/Taiyoro/dataCollection"

// YouTube, OpenRec, Mildom, Twitch

export default (props) => {
  const [list, setList] = React.useState([] as DataCollectionResult[])
  React.useEffect(() => {
    if (props.match.params.dateId) {
      fetchDataCollection(
        null,
        DataCollectionSort.START_DATETIME_ASC,
        0,
        null,
        null,
        null,
        null,
        null,
        props.match.params.dateId
      ).then((dataArr) => {
        if (dataArr) {
          setList(dataArr.results)
        }
      })
    }
  }, [props.match.params.dateId])

  return (
    <div
      style={{
        width: "1000px",
        margin: "auto",
      }}
    >
      <h1 style={{ textAlign: "center" }}>SCANNED DATA</h1>
      {list.length > 0 &&
        list.map((item) => (
          <div
            style={{
              borderStyle: "solid",
              margin: "auto",
              textAlign: "center",
            }}
          >
            <Typography>
              <Link href={item.channelUrl}>{item.userName}</Link>
            </Typography>
            <br />
            <br />
            <Typography>
              <Link href={item.videoUrl}>{item.streamTitle}</Link>
            </Typography>
            {/* <p><a style={{color:'green'}} href={item.url}>{item.title}</a> - Clipped by <a style={{color:'yellow'}} href={`https://twitch.tv/${item.creatorName}`}>{item.creatorName}</a></p>
          <p>{moment.utc(item.clipCreatedAt)
            .tz('Asia/Tokyo')
            .local()
            .format('YYYY-MM-DD HH:mm:ss Z')} ({item.clipCreatedAt} UTC) , {item.durationSeconds} seconds long</p> */}
            <p>Average Concurrents: {calculatePerMinuteAverageConcurrents(item)}</p>
            <p>Minutes Watched: {calculateMinutesWatched(item)}</p>
            <p>Peak Concurrents: {calculatePeakConcurrents(item)}</p>
          </div>
        ))}
    </div>
  )
}

const calculatePerMinuteAverageConcurrents = (item: DataCollectionResult) => {
  if (item.viewerTimeline) {
    const metrics = calculateMetrics(item.viewerTimeline, item.id)
    return metrics.views_live_concurrent_average
  } else {
    return " "
  }
}

const calculatePeakConcurrents = (item: DataCollectionResult) => {
  if (item.viewerTimeline) {
    const metrics = calculateMetrics(item.viewerTimeline, item.id)
    return metrics.views_live_concurrent_peak
  } else {
    return " "
  }
}

const calculateMinutesWatched = (item: DataCollectionResult) => {
  if (item.viewerTimeline) {
    const metrics = calculateMetrics(item.viewerTimeline, item.id)
    return metrics.views_live_minutes_watched
  } else {
    return " "
  }
}

const metricsResult: {
  [key: string]: {
    views_live_concurrent_average: number
    views_live_minutes_watched: number
    views_live_concurrent_peak: number
  }
} = {}
const calculateMetrics = (viewerTimelineString: string, id: string) => {
  if (metricsResult[id]) return metricsResult[id]
  const scannedTimeline = JSON.parse(viewerTimelineString)
  let timeline = scannedTimeline.timeline
  const roundDownToNearestSecond = (unixTimestampMs: number) => unixTimestampMs - (unixTimestampMs % 60000)
  let views_live_minutes_watched = 0
  let live_broadcast_duration = 0
  let peak = (timeline[0] && timeline[0].concurrentViewers) || 0

  let lastSignificantPointIndex = timeline.length
  for (let i = timeline.length - 1; i >= 0; i--) {
    if (timeline[i] && timeline[i].concurrentViewers > 0) {
      lastSignificantPointIndex = i
      break
    }
  }
  timeline = timeline.slice(0, lastSignificantPointIndex)

  for (let i = 1; i < timeline.length; i++) {
    const entry = timeline[i]
    const previousTimePoint = timeline[i - 1]
    const minutesSince =
      (roundDownToNearestSecond(Date.parse(entry.datetime)) -
        roundDownToNearestSecond(Date.parse(previousTimePoint.datetime))) /
      60000
    if (minutesSince > 0) {
      views_live_minutes_watched +=
        ((previousTimePoint.concurrentViewers + entry.concurrentViewers) / 2) * minutesSince
      live_broadcast_duration += minutesSince
    }
    if (entry.concurrentViewers > peak) {
      peak = entry.concurrentViewers
    }
  }
  const views_live_concurrent_average = Math.floor(
    (views_live_minutes_watched || 0) / (live_broadcast_duration || 1)
  )
  metricsResult[id] = {
    views_live_concurrent_average,
    views_live_minutes_watched: Math.floor(views_live_minutes_watched),
    views_live_concurrent_peak: peak,
  }
  return metricsResult[id]
}

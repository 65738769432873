import { Article, ArticleLog, ReviewedArticlesResults } from "models/Taiyoro/article"
import GraphQL from "utils/graphql"

import { fetchLogsForEntities } from "./logs"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchArticlesTodo = async (): Promise<Article[] | null> =>
  GraphQL(
    `query {
        article {
          get(status: [null], sort: LAST_STATUS_UPDATE, limit: -1, offset: 0) {
            offset
            limit
            totalCount
            results {
              id
              username
              userId
              submissionDatetime
              title
              url
              imageUrl
              entity
              entityId
              entityData {
                name
                nameJa
                urlSlug
              }
              visits
              popularityWeightPast
              status
            }
          }
        }
      }
      `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((res) => res.data.article.get.results as Array<Article>)

export const approveArticle = async (id: string): Promise<Boolean> =>
  GraphQL(
    `mutation {
    article {
      updateStatus(articleId: "${id}", status: 1)
    }
  }
  `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((res) => res.data.article.updateStatus)

export const fetchArticleCSVData = async (): Promise<string> => {
  return GraphQL(
    `query {
          admin {
            analytics {
              csv(type: "ARTICLE")
            }
          }
        }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return (
      response.data &&
      response.data.admin &&
      response.data.admin.analytics &&
      response.data.admin.analytics.csv
    )
  })
}

export const rejectArticle = async (id: string): Promise<Boolean> =>
  GraphQL(
    `mutation {
    article {
      updateStatus(articleId: "${id}", status: 0)
    }
  }
  `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((res) => res.data.article.updateStatus)

export const fetchArticleLogs = async (id: string): Promise<Array<ArticleLog>> => {
  return fetchLogsForEntities([{ entityType: "article_status", entityID: id }], 100, 0).then((res) => {
    return res.data.admin.logging.get.results.map((result) => {
      return {
        username: result.username,
        status: result.changes[0].new,
        datetime: result.createdAt,
      } as ArticleLog
    })
  })
}

export const fetchReviewedArticles = async (page: number, size: number): Promise<ReviewedArticlesResults> =>
  GraphQL(
    `query {
      article {
        get(status: [0,1], sort: LAST_STATUS_UPDATE, limit: ${size}, offset: ${page * size}) {
          offset
          limit
          totalCount
          results {
            id
            username
            userId
            submissionDatetime
            title
            url
            imageUrl
            entity
            entityId
            entityData {
              name
              nameJa
              urlSlug
            }
            visits
            popularityWeightPast
            status
          }
        }
      }
    }
    `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((res) => res.data.article.get as ReviewedArticlesResults)

export const searchReviewedArticles = async (
  page: number,
  size: number,
  searchString: string
): Promise<ReviewedArticlesResults> =>
  GraphQL(
    `query {
        article {
          searchReviewed(limit: ${size}, offset: ${page * size}, searchString: ${JSON.stringify(
            searchString
          )}) {
            offset
            limit
            totalCount
            results {
              id
              username
              userId
              submissionDatetime
              title
              url
              imageUrl
              entity
              entityId
              entityData {
                name
                nameJa
                urlSlug
              }
              visits
              popularityWeightPast
              status
            }
          }
        }
      }
      `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((res) => res.data.article.searchReviewed as ReviewedArticlesResults)

import React, { useState } from "react"

import {
  faExclamationTriangle,
  faLock,
  faSlidersSimple,
  faTrashAlt,
  faUnlock,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, IconButton, Stack } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import getPlatformFromUrl, { Platform as PlatformType } from "@taiyoro/parse-stream-url"
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog"
import { TrimDialog } from "components/Taiyoro/CreateEvent/Dates/Date/Streams/TrimDialog"
import { useTranslation } from "react-i18next"
import { useUpdateEffect } from "react-use"

import Platform from "../../../../../../../models/Taiyoro/Meta/Platform"
import EventStreamModel, { RecoveryStatus } from "../../../../../../../models/Taiyoro/Stream"
import { PublishedState } from "../../../../../../../models/Taiyoro/event"
import { UserRole } from "../../../../../../../models/UserManagement"
import { editDatePlatform } from "../../../../../../../services/Taiyoro/event"
import { fetchLanguages } from "../../../../../../../services/Taiyoro/languages"
import { HIGH_ACCESS_ROLES } from "../../../../../../../utils/roles"
import isValidUrl from "../../../../../../../utils/taiyoro/validators/streamUrl"
import useRolesCanAccess from "../../../../../../../utils/useRolesCanAccess"
import RealTimeUpdateField from "../../../../../RealTimeUpdateField"
import UnlockDialog from "../UnlockDialog"

interface Props {
  model: EventStreamModel
  eventPublishedState: PublishedState
  availablePlatforms: Platform[]
  onUpdate: (changes: any) => void
  onDelete: (id: string) => void
}

// (Roy) Should maybe be a database controlled backend field?
const platformsCapableOfAutoGeneration = ["openrec", "twitch", "youtube"]

const EventStream = ({ model, eventPublishedState, availablePlatforms, onUpdate, onDelete }: Props) => {
  const [availableLanguagesState] = useState(fetchLanguages)

  const [idState] = useState<string | null>(model.id || null)
  const [platformState, setPlatformState] = useState<string | null>(model.platform || null)
  const [urlState, setUrlState] = useState(model.url || null)
  const [languageState, setLanguageState] = useState(model.language)
  const [viewsLiveConcurrentPeakState, setViewsLiveConcurrentPeakState] = useState<number | null>(
    model.viewsLiveConcurrentPeakManual || model.viewsLiveConcurrentPeak
  )
  const [viewsLiveMinutesWatchedState, setViewsLiveMinutesWatchedState] = useState<number | null>(
    model.viewsLiveMinutesWatchedManual || model.viewsLiveMinutesWatched
  )
  const [viewsLiveConcurrentAverageState, setViewsLiveConcurrentAverageState] = useState<number | null>(
    model.viewsLiveConcurrentAverageManual || model.viewsLiveConcurrentAverage
  )
  const [isSubStreamState, setIsSubStreamState] = useState(model.isSubStream)
  const [automaticallyGenerateVodsState, setAutomaticallyGenerateVodsState] = useState<number | null>(
    model.automaticallyGenerateVods
  )
  const [isValidUrlState, setIsValidUrlState] = useState(model.url && isValidUrl(model.url))
  const [isLockedState, setIsLockedState] = useState(true)
  const [isUnlockDialogOpenState, setIsUnlockDialogOpenState] = useState(false)

  const { t } = useTranslation(["taiyoro", "common"])
  const availableRecoveryStatus = Object.values(RecoveryStatus)
    .filter((value) => typeof value === "string")
    .map((rs) => {
      return {
        id: rs,
        name: t("edit.dates.dataRecovery." + rs),
      }
    })

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const [showTrimConfirmation, setShowTrimConfirmation] = useState(false)

  const isHighAccessUser = useRolesCanAccess(HIGH_ACCESS_ROLES)

  const isEditableToEditor =
    useRolesCanAccess([UserRole.EDITOR]) && eventPublishedState === PublishedState.Draft

  const canDelete = isHighAccessUser || isEditableToEditor

  const isPlatformCapableOfAutoGeneration = (platformId: string) => {
    const platform = availablePlatforms.find((p) => p.id === platformId)
    return platform && platformsCapableOfAutoGeneration.some((p) => platform.name.toLowerCase().includes(p))
  }

  useUpdateEffect(() => {
    //Update the values on the parent component so it can calculate the totals of these values
    onUpdate({
      viewsLiveMinutesWatchedManual: viewsLiveMinutesWatchedState,
      viewsLiveConcurrentAverageManual: viewsLiveConcurrentAverageState,
      viewsLiveConcurrentPeakManual: viewsLiveConcurrentPeakState,
      platform: platformState,
      url: urlState,
      language: languageState,
      automaticallyGenerateVods: automaticallyGenerateVodsState,
      isSubStream: isSubStreamState,
    })
    // eslint-disable-next-line
  }, [
    viewsLiveConcurrentPeakState,
    viewsLiveConcurrentAverageState,
    viewsLiveMinutesWatchedState,
    platformState,
    urlState,
    languageState,
    automaticallyGenerateVodsState,
    isSubStreamState,
  ])

  const toggleLock = () => {
    if (isLockedState) {
      setIsUnlockDialogOpenState(true)
      return
    }
    if (!isLockedState) {
      setIsLockedState(true)
    }
  }

  const onUpdateUrl = (url: string) => {
    setUrlState(url)

    setIsValidUrlState(url && isValidUrl(url))

    try {
      const p = getPlatformFromUrl(url)
      let pId
      let platform
      switch (p) {
        case PlatformType.YouTube:
          platform = availablePlatforms.find((o) => o.name.toLowerCase().includes("youtube"))
          if (platform) {
            pId = platform.id
          }
          break
        case PlatformType.Twitch:
          platform = availablePlatforms.find((o) => o.name.toLowerCase().includes("twitch"))
          if (platform) {
            pId = platform.id
          }
          break
        case PlatformType.OpenRec:
          platform = availablePlatforms.find((o) => o.name.toLowerCase().includes("openrec"))
          if (platform) {
            pId = platform.id
          }
          break
        case PlatformType.Mildom:
          platform = availablePlatforms.find((o) => o.name.toLowerCase().includes("mildom"))
          if (platform) {
            pId = platform.id
          }
          break
        case PlatformType.Afreeca:
          platform = availablePlatforms.find((o) => o.name.toLowerCase().includes("afreeca"))
          if (platform) {
            pId = platform.id
          }
          break
      }
      pId && setPlatformState(pId)
      return pId
    } catch (e) {
      console.error(e)
    }
  }

  useUpdateEffect(() => {
    model.platform !== platformState && setPlatformState(model.platform)
    model.url !== urlState && setUrlState(model.url)
    model.language !== languageState && setLanguageState(model.language)
    model.viewsLiveConcurrentPeak !== viewsLiveConcurrentPeakState &&
      setViewsLiveConcurrentPeakState(model.viewsLiveConcurrentPeak)
    model.viewsLiveMinutesWatched !== viewsLiveMinutesWatchedState &&
      setViewsLiveMinutesWatchedState(model.viewsLiveMinutesWatched)
    model.viewsLiveConcurrentAverage !== viewsLiveConcurrentAverageState &&
      setViewsLiveConcurrentAverageState(model.viewsLiveConcurrentAverage)
    model.automaticallyGenerateVods !== automaticallyGenerateVodsState &&
      setAutomaticallyGenerateVodsState(model.automaticallyGenerateVods)
    isValidUrl(model.url) !== isValidUrlState && setIsValidUrlState(isValidUrl(model.url))
  }, [model])

  return (
    <React.Fragment>
      <Stack
        sx={{ minWidth: "200px" }}
        direction="row"
        alignItems="center"
        gap={1}
      >
        <Tooltip
          placement="bottom"
          title={urlState || ""}
        >
          <Box>
            <RealTimeUpdateField
              key={urlState}
              type="text"
              variant="standard"
              onFocus={(event) => {
                event.target.select()
              }}
              label={t("edit.dates.streams.url")}
              validationRule={async (encodedUrl) => {
                return new Promise((resolve, reject) => {
                  const encodedUrlSafe = encodedUrl === null ? "https://" : encodedUrl
                  resolve(encodedUrlSafe)
                })
              }}
              updateFunc={(encodedUrl) => {
                const decodedUrl = decodeURI(encodedUrl)
                setUrlState(decodedUrl)
                const newPlatformId = onUpdateUrl(decodedUrl)
                let payload: any = {}
                if (platformState !== newPlatformId) {
                  const automaticallyGenerateVodsValue = isPlatformCapableOfAutoGeneration(newPlatformId)
                    ? 1
                    : 0
                  setAutomaticallyGenerateVodsState(automaticallyGenerateVodsValue)
                  payload.automaticallyGenerateVods = automaticallyGenerateVodsValue
                }
                if (newPlatformId) {
                  payload.platformId = newPlatformId
                }
                payload.url = decodedUrl

                return editDatePlatform(idState, payload)
              }}
              initialValue={urlState || ""}
            />
          </Box>
        </Tooltip>
        {!isValidUrlState && (
          <Tooltip
            placement="bottom"
            title={t("list.summary.dateList.invalidOrUnsupportedUrl")}
          >
            <Box>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                color="#ffcc01"
              />
            </Box>
          </Tooltip>
        )}
      </Stack>
      <Box width="125px">
        <Tooltip
          placement="bottom"
          title={availableLanguagesState.find((l) => l.id === languageState)?.name || ""}
        >
          <Box width="125px">
            <RealTimeUpdateField
              key={languageState}
              type="select"
              variant="standard"
              disableClearable
              initialValue={availableLanguagesState.find((l) => l.id === languageState)}
              options={availableLanguagesState}
              label={t("edit.dates.streams.language")}
              updateFunc={(value) => {
                setLanguageState(value.id)
                return editDatePlatform(idState, { language: value.id })
              }}
            />
          </Box>
        </Tooltip>
      </Box>
      <Box width="125px">
        <RealTimeUpdateField
          key={viewsLiveConcurrentPeakState}
          type="number-format"
          variant="standard"
          label={t("list.summary.dateList.streamList.pcu")}
          disabled={isLockedState}
          updateFunc={(value) => {
            setViewsLiveConcurrentPeakState(value ? parseInt(value) : model.viewsLiveConcurrentPeak)
            return editDatePlatform(idState, {
              viewsLiveConcurrentPeakManual: value ? parseInt(value) : null,
            })
          }}
          initialValue={viewsLiveConcurrentPeakState}
        />
      </Box>
      <Box width="125px">
        <RealTimeUpdateField
          key={viewsLiveConcurrentAverageState}
          type="number-format"
          variant="standard"
          label={t("list.summary.dateList.streamList.acu")}
          disabled={isLockedState}
          updateFunc={(value) => {
            setViewsLiveConcurrentAverageState(value ? parseInt(value) : model.viewsLiveConcurrentAverage)
            return editDatePlatform(idState, {
              viewsLiveConcurrentAverageManual: value ? parseInt(value) : null,
            })
          }}
          initialValue={viewsLiveConcurrentAverageState}
        />
      </Box>
      <Box width="125px">
        <RealTimeUpdateField
          key={viewsLiveMinutesWatchedState}
          type="number-format"
          variant="standard"
          label={t("list.summary.dateList.streamList.minsWatched")}
          disabled={isLockedState}
          updateFunc={(value) => {
            setViewsLiveMinutesWatchedState(value ? parseInt(value) : model.viewsLiveMinutesWatched)
            return editDatePlatform(idState, {
              viewsLiveMinutesWatchedManual: value ? parseInt(value) : null,
            })
          }}
          initialValue={viewsLiveMinutesWatchedState}
        />
      </Box>
      <Box>
        <Tooltip
          placement="bottom"
          title={t("edit.dates.streams.lock.tooltip")}
        >
          <Button onClick={toggleLock}>
            {!isLockedState && <FontAwesomeIcon icon={faUnlock}></FontAwesomeIcon>}
            {isLockedState && <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>}
          </Button>
        </Tooltip>
      </Box>
      <Box width="125px">
        <RealTimeUpdateField
          type="select"
          variant="standard"
          disableClearable
          label={t("edit.dates.dataRecovery.label")}
          options={availableRecoveryStatus}
          initialValue={availableRecoveryStatus.find((rs) => rs.id === model.recoveryStatus)}
          updateFunc={(value) => {
            editDatePlatform(model.id, { recoveryStatus: value.id }, true)
          }}
        />
      </Box>
      <Box>
        <RealTimeUpdateField
          key={isSubStreamState}
          type="checkbox"
          label={t("edit.dates.streams.isSubStream")}
          updateFunc={(value) => {
            const intValue = parseInt(value)
            setIsSubStreamState(intValue)
            return editDatePlatform(idState, { isSubStream: intValue })
          }}
          initialValue={isSubStreamState}
        />
      </Box>
      <Box>
        <Tooltip
          placement="bottom"
          title={t("edit.dates.streams.automaticallyGenerateVodsTooltip")}
        >
          <Box>
            <RealTimeUpdateField
              key={automaticallyGenerateVodsState}
              type="checkbox"
              label={t("edit.dates.streams.automaticallyGenerateVods")}
              disabled={!isPlatformCapableOfAutoGeneration(platformState)}
              updateFunc={(value) => {
                setAutomaticallyGenerateVodsState(value)
                return editDatePlatform(idState, { automaticallyGenerateVods: value })
              }}
              initialValue={automaticallyGenerateVodsState}
            />
          </Box>
        </Tooltip>
      </Box>
      <Stack
        direction="row"
        gap={1}
        justifyContent="flex-end"
      >
        {isHighAccessUser && model.viewsLiveConcurrentPeak && !model.isSubStream && (
          <Tooltip title={t("edit.dataTrim.tooltip")}>
            <IconButton
              color="default"
              onClick={() => setShowTrimConfirmation(true)}
              sx={{ width: "32px", height: "32px" }}
            >
              <FontAwesomeIcon icon={faSlidersSimple} />
            </IconButton>
          </Tooltip>
        )}
        {canDelete && (
          <Tooltip title={t("common:actions.delete")}>
            <IconButton
              color="error"
              onClick={() => setShowDeleteConfirmation(true)}
              sx={{ width: "32px", height: "32px" }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <UnlockDialog
        isUnlockDialogOpenState={isUnlockDialogOpenState}
        onUnlock={() => {
          setIsLockedState(false)
          setIsUnlockDialogOpenState(false)
        }}
        onCancelUnlock={() => setIsUnlockDialogOpenState(false)}
      />
      <ConfirmationDialog
        body={t("common:actions.checkAgain")}
        open={showDeleteConfirmation}
        onConfirm={() => onDelete(model.id)}
        onCancel={() => setShowDeleteConfirmation(false)}
        confirmText={t("common:actions.delete")}
        cancelText={t("common:actions.cancel")}
      />
      <TrimDialog
        id={model.id}
        openControls={[showTrimConfirmation, setShowTrimConfirmation]}
      />
    </React.Fragment>
  )
}

export default EventStream

import { useCallback, useContext, useEffect, useState } from "react"

import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Autocomplete, TextField } from "@mui/material"
import { NotificationContext } from "contexts/notification"
import Event from "models/Taiyoro/event"
import { useTranslation } from "react-i18next"
import { useDebounce, useUpdateEffect } from "react-use"
import { fetchEvent, searchFeedAndMeta } from "services/Taiyoro/event"

interface Props {
  preloadEventId?: string
  onEventSelected: (event: Event) => void
}

export const EventSearchAutocomplete = (props: Props) => {
  const [inputValue, setInputValue] = useState("")
  const [value, setValue] = useState(null)
  const [options, setOptions] = useState([])
  const [searchingEvents, setSearchingEvents] = useState(false)
  const [preloadingEvent, setPreloadingEvent] = useState(false)
  const { setNotification } = useContext(NotificationContext)
  const { t } = useTranslation("common")

  const handleError = useCallback(
    (error: any) => {
      setNotification({
        message: error,
        severity: "error",
      })
    },
    [setNotification]
  )

  const handleSearch = (searchTerm) => {
    setSearchingEvents(true)
    searchFeedAndMeta(searchTerm)
      .then((results) => {
        const uniqueEvents = results.feed.reduce((acc, curr) => {
          if (!acc.some((e) => e.id === curr.event.id)) {
            acc.push(curr.event)
          }
          return acc
        }, [])
        setOptions(uniqueEvents)
        setSearchingEvents(false)
      })
      .catch(handleError)
  }

  useDebounce(
    () => {
      inputValue !== "" && handleSearch(inputValue)
    },
    700,
    [inputValue]
  )

  useUpdateEffect(() => {
    props.onEventSelected(value)
  }, [value])

  useEffect(() => {
    const preloadEvent = async () => {
      setPreloadingEvent(true)
      const preloadedEvent = await fetchEvent(props.preloadEventId).catch(handleError)
      if (!preloadedEvent || !preloadedEvent.name) {
        handleError("Couldn't find designated preload event")
        setPreloadingEvent(false)
        return
      }
      searchFeedAndMeta(preloadedEvent.name)
      setInputValue(preloadedEvent.name)
      setValue(preloadedEvent)
      setPreloadingEvent(false)
    }
    props.preloadEventId && preloadEvent()
  }, [handleError, props.preloadEventId])

  useUpdateEffect(() => {
    if (inputValue !== "") {
      setSearchingEvents(true)
      setOptions([])
    }
  }, [inputValue])

  return (
    <Autocomplete
      loading={searchingEvents}
      loadingText={
        <FontAwesomeIcon
          icon={faSpinner}
          spin
        />
      }
      fullWidth
      filterOptions={(x) => x}
      options={options}
      autoComplete
      getOptionLabel={(option) => option.name}
      includeInputInList
      filterSelectedOptions
      value={value}
      disabled={preloadingEvent}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
      }}
      noOptionsText={t("eventSearch.noOptions")}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          placeholder={preloadingEvent ? t("loading") : t("eventSearch.placeholder")}
        />
      )}
    />
  )
}

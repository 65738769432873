import React from "react"

import { useTranslation } from "react-i18next"

import MetasPage from "../../../components/Taiyoro/MetaPage"
import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import { createProducer, deleteProducer, fetchProducers } from "../../../services/Taiyoro/producers"

const MetaProducerPage = () => {
  const { t } = useTranslation("taiyoro")

  return (
    <MetasPage
      title={t("meta.pageTitles.producers")}
      fetchFunc={fetchProducers}
      deleteFunc={deleteProducer}
      addNewFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createProducer(name, nameJa, sortJa, isGameDeveloper, id)}
      updateFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createProducer(name, nameJa, sortJa, isGameDeveloper, id)}
      metaType={MetaType.PRODUCER}
      gameDeveloperFields
    />
  )
}

export default MetaProducerPage

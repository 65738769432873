import React, { useState, useContext, useEffect } from "react"

import getPlatformFromUrl, { Platform as PlatformType } from "@taiyoro/parse-stream-url"
import { DataRecoveryContext, RecoveryStep } from "contexts/data-recovery"
import { EventContext } from "contexts/event"
import Platform from "models/Taiyoro/Meta/Platform"
import { DataCollectionResult, saveDatePlatform } from "services/Taiyoro/dataCollection"

interface Props {
  children: React.ReactNode
  availablePlatforms: Array<Platform>
}

const DataRecoveryProvider = (props: Props) => {
  const [selectedEventDate, setSelectedEventDate] = useState(null)
  const [selectedStreams, setSelectedStreams] = useState<Array<DataCollectionResult>>([])
  const [step, setStep] = useState<RecoveryStep>(RecoveryStep.INITIAL)
  const [error, setError] = useState(false)
  const [recoveryLoading, setRecoveryLoading] = useState(false)
  const [selectedExistingStream, setSelectedExistingStream] = useState(null)

  const [, setEvent] = useContext(EventContext)

  useEffect(() => {
    if (!selectedEventDate) {
      setStep(RecoveryStep.INITIAL)
    }
  }, [selectedEventDate])

  const getPlatformIdFromUrl = (url: string) => {
    try {
      const p = getPlatformFromUrl(url)

      switch (p) {
        case PlatformType.YouTube:
          return props.availablePlatforms.find((o) => o.name.toLowerCase().includes("youtube"))?.id
        case PlatformType.Twitch:
          return props.availablePlatforms.find((o) => o.name.toLowerCase().includes("twitch"))?.id
        case PlatformType.OpenRec:
          return props.availablePlatforms.find((o) => o.name.toLowerCase().includes("openrec"))?.id
        case PlatformType.Mildom:
          return props.availablePlatforms.find((o) => o.name.toLowerCase().includes("mildom"))?.id
        case PlatformType.Afreeca:
          return props.availablePlatforms.find((o) => o.name.toLowerCase().includes("afreeca"))?.id
        default:
          return null
      }
    } catch (e) {
      console.error(e)
      return null
    }
  }

  const handleNewRecover = async () => {
    setRecoveryLoading(true)
    try {
      const recoveredStreams = await saveDatePlatform(
        selectedEventDate.id,
        getPlatformIdFromUrl(selectedStreams[0].videoUrl),
        selectedStreams
      )
      if (recoveredStreams) {
        setEvent((oldEvent) => {
          const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
          newEvent.dates.find((date) => date.id === selectedEventDate.id).platforms.push(recoveredStreams)
          return newEvent
        })
        setStep(RecoveryStep.SUCCESS)
      }
    } catch (e) {
      setError(true)
      resetDialog()
    } finally {
      setRecoveryLoading(false)
    }
  }

  const handleExistingRecover = async () => {
    setRecoveryLoading(true)
    try {
      const recoveredStream = await saveDatePlatform(
        selectedEventDate.id,
        getPlatformIdFromUrl(selectedStreams[0].videoUrl),
        selectedStreams,
        selectedExistingStream.id
      )
      if (recoveredStream) {
        setEvent((oldEvent) => {
          const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
          const date = newEvent.dates.find((date) => date.id === selectedEventDate.id)
          const index = date.platforms.findIndex((platform) => platform.id === recoveredStream.id)
          date.platforms[index] = recoveredStream
          return newEvent
        })
        setStep(RecoveryStep.SUCCESS)
      }
    } catch (e) {
      setError(true)
      resetDialog()
    } finally {
      setRecoveryLoading(false)
    }
  }

  const resetDialog = () => {
    setSelectedEventDate(null)
    setSelectedStreams([])
    setSelectedExistingStream(null)
  }

  return (
    <DataRecoveryContext.Provider
      value={{
        selectedEventDate,
        setSelectedEventDate,
        selectedStreams,
        setSelectedStreams,
        step,
        setStep,
        error,
        setError,
        handleNewRecover,
        handleExistingRecover,
        resetDialog,
        recoveryLoading,
        setRecoveryLoading,
        selectedExistingStream,
        setSelectedExistingStream,
      }}
    >
      {props.children}
    </DataRecoveryContext.Provider>
  )
}

export default DataRecoveryProvider

import { useContext, useEffect, useState } from "react"

import { Button, Dialog, DialogActions, DialogContent, Stack } from "@mui/material"
import { FeaturedEventForm } from "components/Taiyoro/FeaturedEvents/FeaturedEventForm"
import { FeaturedEventsContext, TEMPORARY_ID } from "components/Taiyoro/FeaturedEvents/FeaturedEventsProvider"
import { useTranslation } from "react-i18next"
import { FeaturedEvent, addFeaturedEvent, updateFeaturedEvent } from "services/Taiyoro/featuredEvents"

interface Props {
  featuredEvent: FeaturedEvent
  onClose: () => void
}

export const EditDialog = (props: Props) => {
  const { t } = useTranslation("common")
  const { featuredEvents, pendingApiChangesQueue, setPendingApiChangesQueue, setFeaturedEvents } =
    useContext(FeaturedEventsContext)

  const [updatedFeaturedEvent, setUpdatedFeaturedEvent] = useState(props.featuredEvent)

  const handleUpdate = () => {
    // Filter out any existing updates, no point in doing multiple updates on
    // the same FeaturedEvent via the API
    const filteredPendingApiChangesQueue = pendingApiChangesQueue.filter(
      (pendingChange) => pendingChange.id !== props.featuredEvent.id
    )

    // The Featured Event is still pending creation - let's update it's creation signature
    if (updatedFeaturedEvent.id.startsWith(TEMPORARY_ID)) {
      setPendingApiChangesQueue([
        ...filteredPendingApiChangesQueue,
        {
          type: "create",
          id: updatedFeaturedEvent.id,
          action: () => addFeaturedEvent(updatedFeaturedEvent),
        },
      ])
    } else {
      setPendingApiChangesQueue([
        ...filteredPendingApiChangesQueue,
        {
          type: "update",
          id: updatedFeaturedEvent.id,
          action: () => updateFeaturedEvent(updatedFeaturedEvent),
        },
      ])
    }

    //Finally update the FeaturedEvent and close the EditDialog
    setFeaturedEvents([
      ...featuredEvents.filter((featuredEvent) => featuredEvent.id !== updatedFeaturedEvent.id),
      updatedFeaturedEvent,
    ])
    props.onClose()
  }

  useEffect(() => {
    setUpdatedFeaturedEvent(props.featuredEvent)
  }, [props.featuredEvent])

  return (
    <Dialog
      open={props.featuredEvent !== null}
      onClose={props.onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogContent>
        {props.featuredEvent !== null && (
          <FeaturedEventForm
            featuredEvent={updatedFeaturedEvent}
            onUpdate={(featuredEvent) => setUpdatedFeaturedEvent(featuredEvent)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          gap={1}
        >
          <Button
            onClick={props.onClose}
            color="primary"
            variant="text"
          >
            {t("actions.cancel")}
          </Button>
          <Button
            onClick={handleUpdate}
            color="primary"
            variant="contained"
            disabled={!updatedFeaturedEvent || !updatedFeaturedEvent.eventId || !updatedFeaturedEvent.event}
          >
            {t("actions.save")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

import React from "react"

import EventDate from "../../../models/Taiyoro/eventDate"
import { fetchEvent } from "../../../services/Taiyoro/event"
import { fetchPlatforms } from "../../../services/Taiyoro/platforms"
import { RenderGraph, RenderEventGraphTable, RenderLegend } from "./metricsUtil"

export default (props) => {
  const [eventData, setEventData] = React.useState(null)
  const [dateLabels, setDateLabels] = React.useState({})
  const page = props.match.params.page
  console.log("page", page)
  React.useEffect(() => {
    if (props.match.params.eventId) {
      fetchEvent(props.match.params.eventId).then((data) => {
        setEventData(data)
        const dateLabelMap = {}
        if (data) {
          const dates = data ? data.dates : null
          if (dates) {
            for (let i = 0; i < dates.length; i++) {
              const date = dates[i]
              dateLabelMap[date.startTime.tz("Asia/Tokyo").format("MM/DD")] = date
            }
          }
        }
        setDateLabels(dateLabelMap)
      })
    }
  }, [props.match.params.eventId])
  const [platforms, setPlatforms] = React.useState({})
  React.useEffect(() => {
    if (props.match.params.eventId) {
      fetchPlatforms().then((data) => {
        setPlatforms(
          data.reduce((acc, p) => {
            if (eventData) {
              const dates = eventData ? eventData.dates : null
              if (dates) {
                for (let i = 0; i < dates.length; i++) {
                  const date = dates[i]
                  for (let j = 0; j < date.platforms.length; j++) {
                    const platform = date.platforms[j]
                    if (
                      !platform.dataCollectionErrored &&
                      !platform.isVod &&
                      (platform.viewsLiveConcurrentPeak || platform.viewsLiveConcurrentPeakManual)
                    ) {
                      if (platform.platform === p.id) {
                        acc[p.id] = p
                        break
                      }
                    }
                  }
                }
              }
            }
            return acc
          }, {})
        )
      })
    }
  }, [eventData])

  const platformsArr: any[] = Object.values(platforms)
  const platformIds = platformsArr.map((p) => p.id)
  let eventDates: EventDate[] = eventData ? eventData.dates : null
  // PDF is generated on AWS lambda which is always UTC, so we need to add offset values since Chart doesn't support specifying a time zone.
  eventDates =
    eventDates &&
    eventDates.map((d) => {
      return {
        ...d,
        startTime: d.startTime.clone().add(9, "hours"),
        endTime: d.endTime.clone().add(9, "hours"),
      }
    })

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        WebkitPrintColorAdjust: "exact",
        justifyContent: "center",
        height: "200vh",
      }}
    >
      <div style={{ margin: "5% 5%", justifyContent: "center" }}>
        <h1 style={{ width: "100%", textAlign: "center", margin: "auto" }}>{eventData && eventData.name}</h1>
        {page === "1" && (
          <div>
            <h1 style={{ width: "100%", textAlign: "center", margin: "auto", justifyContent: "center" }}>
              {"Minutes Watched"}
            </h1>
            <div style={{ display: "flex", margin: "auto", justifyContent: "center" }}>
              {RenderGraph(
                eventDates,
                platformIds,
                platforms,
                dateLabels,
                "minutes",
                "viewsLiveMinutesWatched",
                "viewsLiveMinutesWatchedManual"
              )}
              {RenderLegend(platformsArr)}
            </div>
            <div style={{ marginTop: "150px" }}>
              {RenderEventGraphTable(
                eventData,
                platformIds,
                platforms,
                dateLabels,
                "minutes",
                "viewsLiveMinutesWatched",
                "viewsLiveMinutesWatchedManual"
              )}
            </div>
          </div>
        )}
        {page === "2" && (
          <div>
            <h1 style={{ width: "100%", textAlign: "center", margin: "auto", justifyContent: "center" }}>
              {"Average Concurrents"}
            </h1>
            <div style={{ display: "flex", margin: "auto", justifyContent: "center" }}>
              {RenderGraph(
                eventDates,
                platformIds,
                platforms,
                dateLabels,
                "ACV",
                "viewsLiveConcurrentAverage",
                "viewsLiveConcurrentAverageManual"
              )}
              {RenderLegend(platformsArr)}
            </div>
            <div style={{ marginTop: "150px" }}>
              {RenderEventGraphTable(
                eventData,
                platformIds,
                platforms,
                dateLabels,
                "ACV",
                "viewsLiveConcurrentAverage",
                "viewsLiveConcurrentAverageManual"
              )}
            </div>
          </div>
        )}
        {page === "3" && (
          <div>
            <h1 style={{ width: "100%", textAlign: "center", margin: "auto", justifyContent: "center" }}>
              {"Peak Concurrents"}
            </h1>
            <div style={{ display: "flex", margin: "auto", justifyContent: "center" }}>
              {RenderGraph(
                eventDates,
                platformIds,
                platforms,
                dateLabels,
                "PCV",
                "viewsLiveConcurrentPeak",
                "viewsLiveConcurrentPeakManual"
              )}
              {RenderLegend(platformsArr)}
            </div>
            <div style={{ marginTop: "150px" }}>
              {RenderEventGraphTable(
                eventData,
                platformIds,
                platforms,
                dateLabels,
                "PCV",
                "viewsLiveConcurrentPeak",
                "viewsLiveConcurrentPeakManual"
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

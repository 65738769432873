import React, { useState, useEffect } from "react"

import { Grid, Typography } from "@mui/material"

import EventDate from "../../../../../../models/Taiyoro/eventDate"
import { DateText, TimeFirst, TimeLast, TimeContainer, DateFirst, DateLast } from "./styles"

interface Props {
  date: EventDate
}

const Duration = ({ date }: Props) => {
  const [startTimeTextState, setStartTimeTextState] = useState("")
  const [startDateState, setStartDateTextState] = useState("")
  const [endTimeTextState, setEndTimeTextState] = useState("")
  const [endDateTextState, setEndDateTextState] = useState("")

  useEffect(() => {
    if (date === null) {
      // This shouldnt happen, but it'll prevent errors
      return
    }
    let first = date.startTime
    let last = date.endTime

    setStartDateTextState(first.locale("en").format("DD MMM YYYY"))
    setStartTimeTextState(first.format("HH:mm"))
    setEndDateTextState(last.locale("en").format("DD MMM YYYY"))
    setEndTimeTextState(last.format("HH:mm"))

    // eslint-disable-next-line
  }, [date])

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        style={{ minWidth: "192px" }}
      >
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            spacing={0}
          >
            <DateFirst
              item
              xs={5}
              sx={{ color: "text.secondary" }}
            >
              {startDateState}
            </DateFirst>
            <TimeContainer
              item
              xs={2}
            ></TimeContainer>
            <DateLast
              item
              xs={5}
              sx={{ color: "text.secondary" }}
            >
              {endDateTextState}
            </DateLast>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
        >
          {date.estimated === 1 && (
            <Typography
              color="error"
              textAlign="center"
              fontWeight="700"
              fontSize="1.4em"
            >
              TBD
            </Typography>
          )}
          {date.estimated === 0 && (
            <Grid
              container
              spacing={0}
            >
              <TimeFirst
                item
                xs={5}
              >
                <DateText component="span">{startTimeTextState}</DateText>
              </TimeFirst>
              <TimeContainer
                item
                xs={2}
              >
                ~
              </TimeContainer>
              <TimeLast
                item
                xs={5}
              >
                <DateText component="span">{endTimeTextState}</DateText>
              </TimeLast>
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Duration

import React, { useEffect, useState } from "react"

import { Grid } from "@mui/material"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import Currency from "../../../../models/Taiyoro/Meta/Currency"
import Sponsor from "../../../../models/Taiyoro/Meta/Sponsor"
import EventSponsor, { PriorityType } from "../../../../models/Taiyoro/Sponsor"
import Event from "../../../../models/Taiyoro/event"
import { deleteEventSponsor, saveEventSponsor } from "../../../../services/Taiyoro/event"
import DropdownSearchable from "../../../Form/DropdownSearchable"
import { StyleTypography } from "../../../StyleMaterialUI"
import TableList from "../../../Table/TableList"
import SponsorEntry from "./SponsorEntry"
import { HelperText } from "./styles"

interface Props {
  loadedModel: Event
  availableSponsors: Sponsor[]
  availableCurrencies: Currency[]
  availableDates
  onDelete: (cb: () => void) => void
  onCreateSponsor: (value: any) => Promise<{ id: string; name: string }>
}

const EventSponsors = ({
  loadedModel,
  availableSponsors,
  availableCurrencies,
  availableDates,
  onDelete,
  onCreateSponsor,
}: Props) => {
  const [availableSponsorsState, setAvailableSponsorsState] = useState([])
  const [availableCurrenciesState, setAvailableCurrenciesState] = useState([])

  const [loadedModelState, setLoadedModelState] = useState(null)
  const [idState, setIdState] = useState(null)

  const [sponsorsState, setSponsorsState] = useState([])
  const { t } = useTranslation("taiyoro")

  useEffect(() => {
    if (loadedModel) {
      setIdState(loadedModel.id)

      setLoadedModelState(loadedModel)
    }
  }, [loadedModel])

  useEffect(() => {
    setAvailableSponsorsState(availableSponsors)
  }, [availableSponsors])

  useEffect(() => {
    setAvailableCurrenciesState(availableCurrencies)
  }, [availableCurrencies])

  useEffect(() => {
    if (!loadedModelState || availableSponsorsState.length === 0) {
      return
    }

    const language = localStorage.getItem("language") || "en"
    const { field, fallback } =
      language === "ja" ? { field: "nameJa", fallback: "name" } : { field: "name", fallback: "nameJa" }

    setSponsorsState(
      loadedModelState.sponsors.map((sponsor) => {
        const sponsorObj = availableSponsorsState.find((as) => as.id === sponsor.id)
        return new EventSponsor(
          sponsor.id,
          sponsorObj[field] || sponsorObj[fallback] || "",
          sponsor.revenue,
          sponsor.currency,
          sponsor.contributionTypes,
          sponsor.priority,
          sponsor.allDates,
          sponsor.dateIds
        )
      })
    )

    // eslint-disable-next-line
  }, [loadedModelState, availableSponsorsState, availableCurrenciesState])

  const updateSponsor = async (model) => {
    await saveEventSponsor(idState, model)
  }

  return (
    <Grid
      item
      xs={12}
    >
      <StyleTypography
        variant="h5"
        mt="24px"
        mb="12px"
      >
        {t("edit.sponsors.title")}
      </StyleTypography>
      <Grid container>
        {sponsorsState && sponsorsState.length > 0 && (
          <Grid
            item
            xs={12}
          >
            <TableList>
              <TableList.Header>
                <TableList.HeaderRow>
                  <TableList.HeaderCell>{t("edit.sponsors.name")}</TableList.HeaderCell>
                  <TableList.HeaderCell>{t("edit.sponsors.contributionTypes")}</TableList.HeaderCell>
                  <TableList.HeaderCell>{t("edit.sponsors.revenue")}</TableList.HeaderCell>
                  <TableList.HeaderCell>{t("edit.sponsors.currency")}</TableList.HeaderCell>
                  <TableList.HeaderCell>{t("edit.sponsors.priority")}</TableList.HeaderCell>
                  <TableList.HeaderCell>{t("edit.sponsors.sponsoringAllDates")}</TableList.HeaderCell>
                  <TableList.HeaderCell></TableList.HeaderCell>
                </TableList.HeaderRow>
              </TableList.Header>
              <TableList.Body>
                {sponsorsState.map((sponsor, index) => (
                  <React.Fragment key={sponsor.id}>
                    <SponsorEntry
                      availableCurrencies={availableCurrenciesState}
                      availableDates={availableDates}
                      sponsor={sponsor}
                      eventId={loadedModelState.id}
                      isEventPublished={loadedModelState.published}
                      onDelete={() => {
                        sponsorsState.splice(index, 1)
                        setSponsorsState([...sponsorsState])
                        if (idState !== null) {
                          onDelete(() => deleteEventSponsor(idState, sponsor.id))
                        }
                      }}
                    />
                  </React.Fragment>
                ))}
              </TableList.Body>
            </TableList>
          </Grid>
        )}
        <Grid
          container
          sx={{ marginTop: "24px" }}
        >
          <Grid
            item
            xs={7}
          >
            <DropdownSearchable
              id="eventSponsorsAddSponsorSelect"
              label={t("edit.sponsors.addSponsor")}
              clearOnBlur
              options={
                availableSponsorsState &&
                availableSponsorsState.filter((as) => !sponsorsState.find((s) => s.id === as.id))
              }
              onChange={(value) => {
                if (!value) {
                  return
                }
                const model = new EventSponsor(
                  value.id,
                  value.name,
                  null,
                  "JPY",
                  [],
                  PriorityType.HIGH,
                  1,
                  []
                )
                setSponsorsState([...sponsorsState, model])
                document.getElementById("eventSponsorsAddSponsorSelect").blur()
                updateSponsor(model)
              }}
              createFunc={async (value) => {
                const result = await onCreateSponsor(value)

                setAvailableSponsorsState([
                  ...availableSponsorsState,
                  new Sponsor(result.id, result.name, null, null, null),
                ])
                const state = [
                  ...sponsorsState,
                  new EventSponsor(
                    result.id,
                    result.name,
                    null,
                    "JPY",
                    [],
                    PriorityType.HIGH,
                    1,
                    availableDates
                  ),
                ]
                setSponsorsState(state)

                document.getElementById("eventSponsorsAddSponsorSelect").blur()
              }}
            />
          </Grid>
          <Grid
            item
            xs={5}
          >
            <HelperText>{t("edit.help.sponsors")}</HelperText>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

EventSponsors.propTypes = {
  loadedModel: PropTypes.object,
  availableSponsors: PropTypes.array,
  availableCurrencies: PropTypes.array,
  onDelete: PropTypes.func,
  onCreateSponsor: PropTypes.func,
}

export default EventSponsors

import Caster from "../../models/Taiyoro/Meta/Caster"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchCasters = async (): Promise<Caster[] | null> => {
  let response = await GraphQL(
    `query {
        admin {
          caster {
            get {
              id
              name
              nameJa
              sortJa
              primaryImage
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.caster &&
      response.data.admin.caster.get &&
      response.data.admin.caster.get) ||
    null
  )
}

export const fetchCaster = async (id: string): Promise<Caster | null> => {
  let response = await GraphQL(
    `query {
        admin {
          caster {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              primaryImage
              updatedAt
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.caster &&
      response.data.admin.caster.get &&
      response.data.admin.caster.get[0]) ||
    null
  )
}

export const createCaster = async (
  name: string,
  nameJa?: string,
  sortJa?: string,
  primaryImage?: string,
  id?: string
) => {
  let response = await GraphQL(
    `mutation {
      admin {
        caster {
          save (caster: {
            ${id ? `id: "${id}"` : ""}
            name: ${name ? `"${name}"` : null}
            nameJa: ${nameJa ? `"${nameJa}"` : null}
            sortJa: ${sortJa ? `"${sortJa}"` : null}
            primaryImage: ${primaryImage ? `"${primaryImage}"` : null}
          }) {
            id
            name
            nameJa
            sortJa
            primaryImage
            updatedAt
          }
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data && response.data.admin && response.data.admin.caster && response.data.admin.caster.save) ||
    null
  )
}

export const deleteCaster = async (id) => {
  let response = await GraphQL(
    `mutation {
      admin {
        caster {
          delete (
              casterId: "${id}"
          )
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.caster &&
      response.data.admin.caster.delete) ||
    null
  )
}

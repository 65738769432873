import { useState, useEffect } from "react"

import { faSave, faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Alert, Box, Button, Grid, MenuItem, Paper, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { withRouter, useParams } from "react-router-dom"

import LoadingBox from "../../components/LoadingBox"
import { User, UserRole } from "../../models/UserManagement"
import { getUserById, updateUser } from "../../services/UserManagement/user"

const UserEdit = (props) => {
  const params = useParams<{ product: string }>()
  const [usernameState, setUsernameState] = useState("")
  const [roleState, setRoleState] = useState("")
  const [user, setUser] = useState<User | null>(null)
  const [errorState, setErrorState] = useState(false)
  const [savingState, setSavingState] = useState(false)

  const { t } = useTranslation(["user-management", "common"])

  const loadUser = async (userId) => {
    const user = await getUserById(params.product, userId)
    setUser(user)
    setUsernameState(user.username)
    setRoleState(user.role)
  }

  const handleSave = () => {
    setSavingState(true)
    updateUser(user.id, params.product, usernameState, roleState)
      .then((success) => {
        if (!success) {
          throw new Error()
        }
        const updatedUser = {
          ...user,
          username: usernameState,
          role: roleState,
        }
        setUser(updatedUser)
        setSavingState(false)
        setErrorState(false)
      })
      .catch(() => {
        setErrorState(true)
        setSavingState(false)
      })
  }

  const handleCancel = () => {
    setUsernameState(user.username)
    setRoleState(user.role)
  }

  const changesExist = () => usernameState !== user.username || roleState !== user.role

  useEffect(() => {
    const urlParamUserId = props.match.params.userId
    urlParamUserId && loadUser(urlParamUserId)
    // eslint-disable-next-line
  }, [])

  return (
    <Paper sx={{ padding: "24px" }}>
      {!user && <LoadingBox />}
      {user && (
        <>
          <Box mb="12px">
            <TextField
              label={t("fields.username")}
              value={usernameState}
              fullWidth
              onChange={(e) => setUsernameState(e.target.value || "")}
              autoComplete="off"
            />
          </Box>
          <Box mb="12px">
            <TextField
              label={t("fields.email")}
              value={user.email}
              fullWidth
              type="email"
              autoComplete="off"
              disabled
            />
          </Box>
          <Box mb="12px">
            <TextField
              select
              label={t("fields.role")}
              value={roleState}
              fullWidth
              onChange={(e) => setRoleState(e.target.value as UserRole)}
            >
              {(Object.values(UserRole).filter((value) => typeof value === "string") as Array<string>).map(
                (userRole) => (
                  <MenuItem
                    key={userRole}
                    value={userRole}
                  >
                    {userRole}
                  </MenuItem>
                )
              )}
            </TextField>
          </Box>
          <Box mb="12px">
            <Alert severity="info">{t(`user-management:roleDescriptions.${roleState}`)}</Alert>
          </Box>
          {errorState && (
            <Alert
              color="error"
              sx={{ marginBottom: "12px" }}
            >
              {t("apiError")}
            </Alert>
          )}
          <Grid container>
            <Grid
              item
              sx={{ marginLeft: "auto" }}
            >
              <Button
                disabled={!changesExist()}
                onClick={handleCancel}
              >
                {t("common:actions.cancel")}
              </Button>
            </Grid>
            <Grid
              item
              sx={{ marginLeft: "12px" }}
            >
              <Button
                color="success"
                disabled={!changesExist() && usernameState.length > 0}
                startIcon={
                  <FontAwesomeIcon
                    icon={savingState ? faSpinner : faSave}
                    spin={savingState}
                  />
                }
                onClick={handleSave}
                variant="contained"
              >
                {t("common:actions.save")}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  )
}

export default withRouter(UserEdit)
